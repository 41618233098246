import React, { useContext, useEffect, useState } from 'react';
import { PAGE } from 'constants';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduct, fetchUserProduct, fetchHighlightProduct, fetchLoadMoreProduct } from 'actions/product';
import { fetchEnterprises } from 'actions/business';
import { setParamsTrackActivity } from 'lib';
import { createTrackActivity } from 'actions/track_activity';
import AppContext from 'components/app_context';
import SharedUIMarket from './SharedUIMarket';
import UserProduct from './components/UserProduct';
import ListEnterprise from './components/listEnterPrise';

function Products() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { keyword, current_user } = useContext(AppContext);
  const { data: productsOfUser } = useSelector(state => state.product.listUserProducts);
  const { data: highlightProducts, isLoadMore } = useSelector(state => state.product.highlightProducts);
  const { data: products } = useSelector(state => state.product.listProducts);
  const { data: enterprises } = useSelector(state => state.business.list_enterprises);
  const { data: catalogue } = useSelector(state => state.product.catalogueOfProducts);
  const [limit, setLimit] = useState(8);
  const [productLimit, setProductLimit] = useState(8);
  const [page, setPage] = useState(PAGE);

  const data = {
    products,
    catalogue,
    productLimit,
    onLoadMore: () => handleLoadmoreProduct(),
    HeadComponent: current_user && UserProduct(productsOfUser),
  };

  const handleLoadmoreProduct = () => {
    setProductLimit(prev => prev + 8);
    if (productLimit === data.products.length) {
      setPage(prev => prev + 1);
      dispatch(fetchLoadMoreProduct({ page: page + 1, limit: 8 }));
    }
    // chưa handle case danh sách sản phẩm ít hơn 40
  };

  const pathParams = {
    '/cho-2nong': {
      product_type: 'all',
    },
    '/cho-2nong/nong-san': {
      title: 'Chợ nông sản',
      product_type: 'farmers_markets',
    },
    '/cho-2nong/vat-tu-nong-nghiep': {
      title: 'Chợ vật tư nông nghiệp',
      product_type: 'agricultural_materials_market',
    },
    '/cho-2nong/si-nong-nghiep': {
      title: 'Chợ sỉ nông nghiệp',
      product_type: 'agricultural_wholesale_market',
    },
    '/cho-2nong/nha-san-xuat': {
      title: 'Chợ sản xuất',
      product_type: 'producer_market',
      HeadComponent: ListEnterprise(enterprises),
    },
  };
  
  const getParams = pathname => {
    const { title, product_type, HeadComponent } = pathParams[pathname] || {};
    data.title = title || 'Chợ 2Nông';
    data.HeadComponent = HeadComponent || data.HeadComponent;
    data.products = product_type ? data.products : highlightProducts;

    const params = {
      product_type: product_type || '',
      productAction: product_type
        ? fetchProduct({ product_type, limit: 40 })
        : fetchHighlightProduct({ keyword, limit: 40 }),
      HeadComponentAction:
        product_type === 'producer_market'
          ? fetchEnterprises({})
          : current_user
            ? fetchUserProduct({ keyword, limit, product_type })
            : false,
    };

    return params;
  };

  const params = getParams(location.pathname);

  const fetchProductsData = async () => {
    if (params.HeadComponentAction) dispatch(params.HeadComponentAction);
    dispatch(params.productAction);
    dispatch(createTrackActivity(setParamsTrackActivity('List products', 'GET', {}, 'products')));
  };

  //catalog được dispatch trong sidebarCatalogOfProduct

  useEffect(() => {
    fetchProductsData();
  }, [keyword, current_user, location.pathname]);

  return <SharedUIMarket data={data} />;
}
export default Products;
