import { useDispatch, useSelector } from 'react-redux';
import { showDeepLink } from 'actions/auth';
import { useReactPWAInstall } from 'react-pwa-install';
import { isIOS } from 'react-device-detect';

function DeepLink() {
  const isShowMenuDownLoad = useSelector(state => state.login.show_menu);
  const dispatch = useDispatch();
  let { show } = isShowMenuDownLoad;

  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const handleDisplayMenuShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: 'Hai nông diễn đàn nông nghiệp hàng đầu',
          url: window.location.href,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing:', error));
    }
  };

  const handleShowDeepLinkAndPWA = async () => {
    if (isIOS) {
      handleDisplayMenuShare();
    } else {
      if (supported() & !isInstalled()) {
        await pwaInstall({
          title: 'Tải ứng dụng Diễn đàn nông nghiệp',
          logo: 'https://dev.hainong.vn/images/app-icon.png',
          description: 'Đây là một ứng dụng rất tốt và có rất nhiều thứ hữu ích.',
        })
          .then(() => alert('Ứng dụng đã được cài đặt thành công hoặc hướng dẫn cài đặt được hiển thị'))
          .catch(() => alert('Người dùng đã chọn không tham gia cài đặt'));
      } else {
        alert('Bạn đã cài đặt ứng dụng này rồi!');
      }
    }
  };

  const handleShowDeepLink = () => {
    dispatch(showDeepLink());
  };

  return (
    <>
      {show && (
        <div className="d-mobile w-100 sticky-top">
          <div className="mrgb-10 go-to-app align-items-center d-flex position-relative">
            <img src="/images/app-icon.png" alt="app download" title="app download" className="app-icon" />
            <div>
              <p className="mrgb-0 fw-bold">Diễn đàn nông nghiệp</p>
              <p className="mrgb-0 grey-clr">Đăng ký trên app ngay</p>
              <p className="mrgb-0 fw-bold second-clr">Sử dụng nhiều tính năng</p>
            </div>
            <div className="d-flex gap-1">
              <a role="button" className="ms-auto btn-open position-absolute" style={{ top: '10px' }} onClick={() => handleShowDeepLinkAndPWA()}>
                Cài đặt web
              </a>
              <span className="icon-close d-block position-absolute" onClick={() => handleShowDeepLink()}></span>
            </div>
            <a href="https://cho2nong.page.link/social" className="ms-auto btn-open" style={{ marginTop: '45px' }}>
              Mở App
            </a>
          </div>
        </div>
      )}
    </>
  );
}
export default DeepLink;
