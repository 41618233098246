import { useDispatch, useSelector } from "react-redux";
import useCartTotal from "./useCartTotal";
import { addProductToCart, updateNumberProductInCart, calculateTotalPriceInCart, removeProductFromCart } from "actions/cart";
import { useContext } from "react";
import AppContext from "components/app_context";
const useCart = () => {
  const { current_user } = useContext(AppContext);
  const { updateTotalQuantity, updateTotalNumberProductInCart, updateTotalPrice } = useCartTotal();
  const dispatch = useDispatch();
  const listProductInCarts = useSelector((state) => state.carts.carts.data);
  const numberProductInCart = useSelector((state) => state.carts.carts.data).filter((item) => item.user_id === current_user?.id).length;
  const totalPriceProductInCart = useSelector((state) => state.carts.carts.data)
    .filter((item) => item.user_id === current_user?.id)
    .reduce((sum, product) => {
      sum += product.price * product.quantity;
      return sum;
    }, 0);

  const updateQuantitySafely = (currentProduct, quantity) => {
    let updatedProducts;
    updatedProducts = listProductInCarts.map((product) => {
      if (product.id === currentProduct.id) {
        return Object.assign({ ...product, quantity: quantity });
      } else {
        return product;
      }
    });
    dispatch(addProductToCart(updatedProducts));
    let TotalPrice = updateTotalPrice(updatedProducts);
    dispatch(calculateTotalPriceInCart(TotalPrice));
  };

  const handleAddToCart = async (newProduct) => {
    let updatedProducts;
    const isProductAlreadyInCart = listProductInCarts.some((product) => newProduct.id === product?.id && newProduct.user_id === product.user_id);
    if (isProductAlreadyInCart) {
      updatedProducts = listProductInCarts.map((product) => {
        return updateTotalQuantity(product, newProduct, parseInt(newProduct?.quantity));
      });
    } else {
      updatedProducts = [...listProductInCarts, newProduct];
    }
    let NumberProduct = updateTotalNumberProductInCart(updatedProducts);
    let TotalPrice = updateTotalPrice(updatedProducts);
    dispatch(updateNumberProductInCart(NumberProduct));
    dispatch(calculateTotalPriceInCart(TotalPrice));
    dispatch(addProductToCart(updatedProducts));
  };

  const handleRemoveProduct = async (currentProduct) => {
    let updatedProducts;
    updatedProducts = listProductInCarts.filter((product) => product.id !== currentProduct.id);
    let TotalPrice = updateTotalPrice(updatedProducts);
    let NumberProduct = updateTotalNumberProductInCart(updatedProducts);
    dispatch(updateNumberProductInCart(NumberProduct));
    await dispatch(calculateTotalPriceInCart(TotalPrice));
    dispatch(removeProductFromCart(updatedProducts));
  };

  const handleRemoveAllCart = async () => {
    let updatedProducts
    updatedProducts = listProductInCarts.filter((product) => product.user_id !== current_user?.id);
    let TotalPrice = updateTotalPrice(updatedProducts);
    let NumberProduct = updateTotalNumberProductInCart(updatedProducts);
    dispatch(updateNumberProductInCart(NumberProduct));
    await dispatch(calculateTotalPriceInCart(TotalPrice));
    dispatch(removeProductFromCart(updatedProducts));
  };

  return {
    listProductInCarts,
    updateQuantitySafely,
    handleAddToCart,
    numberProductInCart,
    totalPriceProductInCart,
    handleRemoveProduct,
    handleRemoveAllCart,
  };
};
export default useCart;
