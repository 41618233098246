import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ListFertilizer from "./listFertilizer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as fertilizerActions from "actions/fertilizer";
import { mixNPK } from "apis/fertilizer";
import { Collapse } from "react-bootstrap";
import {createTrackActivity} from 'actions/track_activity';
import {setParamsTrackActivity} from 'lib';

function TabCalculatorNPK(props) {
  //ref
  // state
  let [weight, setWeight] = useState("");
  let [n_rate, setN_rate] = useState("");
  let [p_rate, setP_rate] = useState("");
  let [k_rate, setK_rate] = useState("");
  let [n, setN] = useState("");
  let [p, setP] = useState("");
  let [k, setK] = useState("");
  let [results, setResult] = useState(null);
  let [show, setShow] = useState(false);
  // action
  const { fertilizerActions } = props;
  const { fetchFertilizer } = fertilizerActions; // end action
  const dispatch = useDispatch();
  //reducer

  let { fertilizer_list } = props;
  let { data } = fertilizer_list; //end reducer

  useEffect(() => {
    fetchFertilizer();
  }, [fetchFertilizer]);

  let getFertilizerIds = () => {
    if ($(".nitrogen:checked").length > 0) {
      var list = $(".nitrogen");
      var checked = $(".nitrogen:checked");
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
      setN($(".nitrogen:checked").val());
    } else {
      $(".nitrogen:disabled").removeAttr("disabled");
      setN(null);
    }

    if (
      $(".phosphor:checked").length > 0 ||
      $(".phosphor_mix:checked").length > 0
    ) {
      var list = [];
      var checked = [];

      if ($(".phosphor_mix:checked").length > 0) {
        list = $(".phosphor_mix, .phosphor");
        checked = $(".phosphor_mix:checked");
        setP($(".phosphor_mix:checked").val());
      }
      if ($(".phosphor:checked").length > 0) {
        list = $(".phosphor_mix, .phosphor");
        checked = $(".phosphor:checked");
        setP($(".phosphor:checked").val());
      }

      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
    } else {
      $(".phosphor:disabled").removeAttr("disabled");
      $(".phosphor_mix:disabled").removeAttr("disabled");
      setP(null);
    }

    if ($(".kali:checked").length > 0) {
      var list = $(".kali");
      var checked = $(".kali:checked");
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
      setK($(".kali:checked").val());
    } else {
      $(".kali:disabled").removeAttr("disabled");
      setK(null);
    }
  };
  const submitMixMPK = (e) => {
    dispatch(createTrackActivity(setParamsTrackActivity("Calculator", "GET", {}, "mix_fertilizer_npk")));
    setShow(true);
    if (!show) {
      mixNPK({
        n_rate: n_rate,
        p_rate: p_rate,
        k_rate: k_rate,
        weight: weight,
        "npk_manures[]": [n, p, k],
      }).then((res) => {
        setResult(res.data.data);
      });
    } else {
      setShow(false);
    }
  };
  const detectFertilizer = (value) => {
    if (value == "nitrogen") {
      return "N";
    } else if (value == "phosphor") {
      return "P";
    } else if (value == "kali") {
      return "K";
    } else {
      return "P[P/N]";
    }
  };
  // end function

  return (
    <>
      <h2>Phối trộn phân bón NPK</h2>
      <p>
        NPK là phân có hàm lượng N, P2O5, K2O với các tỉ lệ khác nhau 16-16-8,
        15-15-15, 30-10-10, 6-30-30, 10- 0-30,… Công cụ{" "}
        <strong>NPK Calculator</strong> giúp bà con tự phối trộn phân bón từ
        phân đơn thành phân phức hợp chuyên dùng theo tỉ lệ NPK phù hợp với từng
        loại cây trồng, đặc tính của đất và từng giai đoạn sinh trưởng của cây.
      </p>
      <form className="npk-frm">
        <div className="row">
          <div className="col-md-6 col-sm-12 kg-unit">
            <label>Khối lượng phân bón cần trộn</label>
            <input
              className="form-control mb-2"
              type="text"
              onChange={(e) => setWeight(e.target.value)}
            />
            <span className="">KG</span>
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Tỷ lệ phân NPK mong muốn</label>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="N"
                  onChange={(e) => setN_rate(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="P"
                  onChange={(e) => setP_rate(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="K"
                  onChange={(e) => setK_rate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <ListFertilizer
          data={data}
          detectFertilizer={detectFertilizer}
          getFertilizerIds={getFertilizerIds}
        />
        <div className="accordion" id="accordionResult">
          <div className="accordion-item bg-transparent">
            <h2
              className="accordion-header d-flex justify-content-center"
              id="headingResult"
            >
              {weight != "" &&
              n_rate != "" &&
              p_rate != "" &&
              k_rate != "" &&
              n != null &&
              p != null &&
              k != null &&
              n != "" &&
              p != "" &&
              k != "" ? (
                <button
                  className="collapsed"
                  onClick={(e) => submitMixMPK(e)}
                  type="button"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Xác định Kết Quả
                </button>
              ) : (
                <button
                  className="collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseResult"
                  aria-expanded="false"
                  aria-controls="collapseResult"
                  disabled
                >
                  Xác định Kết Quả
                </button>
              )}
            </h2>
            <Collapse in={show}>
              <div className="accordion-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-uppercase">
                        Phân bón
                      </th>
                      <th scope="col" className="text-uppercase">
                        Trọng lượng
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {results?.manures?.map(function (result, index) {
                      return (
                        <tr key={index}>
                          <th scope="row">{result?.name}</th>
                          <td>{result?.value} KG</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <th scope="row">Phụ gia</th>
                      <td>{Math.round(results?.mix)} KG</td>
                    </tr>
                    <tr className="total-npk">
                      <th scope="row" className="fw-bold">
                        Tổng cộng
                      </th>
                      <td>{results?.total.toFixed(2)} KG</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Collapse>
          </div>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    fertilizer_list: state.fertilizer.list_fertilizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fertilizerActions: bindActionCreators(fertilizerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabCalculatorNPK);
