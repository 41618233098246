import axios from 'axios';
import i18n from 'i18n';
import { browserName, browserVersion } from 'react-device-detect'
import {toastError} from 'helpers/toastHelper'
class Service {
  constructor() {
    const service = axios.create({
      headers: {}
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  setHeader(name, value) {
    this.service.defaults.headers.common[name] = value;
  }

  getAuth = (isAuth) => {
    let user_storage = JSON.parse(localStorage.getItem('user_storage')).user_data
    let device_token = JSON.parse(localStorage.getItem('device'))?.token
    const token = user_storage !== undefined
      ? user_storage.authToken
      : '';
    if (isAuth) {
      let revert_token = token?.length > 34 ? `Bearer ${token}` : token;
      this.setHeader('Authorization', revert_token);
      this.setHeader('Device-Type', "Web");
      this.setHeader('Device-Name', browserName);
      this.setHeader('Os-Version', browserVersion);
      this.setHeader('Device-Token', device_token);
      this.setHeader('App-Version', 1);
      this.setHeader('Device-Id', `${user_storage?.current_user?.id}-${user_storage?.current_user?.phone}-${browserVersion}`);
      this.setHeader('Accept', 'multipart/form-data');
      this.setHeader('Content-Type', 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL');
    } else {
      this.removeHeader('Authorization');
    }
  }

  removeHeader(name) {
    delete this.service.defaults.headers.common[name];
  }

  handleSuccess(response) {
    return response;
  }

  requestLogoutUser() {
    localStorage.removeItem('user_storage');
    toastError('Tài khoản của bạn đã bị khoá hoặc thay đổi phiên làm việc');
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  handleError = error => {
    if (error.response === undefined) {
      let newError = { data: i18n.t('disconnect'), status: 502 }
      return newError;
    } else {
      const status = error.response.status;
      switch (status) {
        case 401:
          let error401 = { data: i18n.t('users.requireLogin'), status: status }
          this.requestLogoutUser();
          return error401;
        case 404:
          return error;
        default:
          return error.response.data;
      }
    }
  };
  

  handleRequest = async config => {
    return config;
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  get(endpoint, isAuth = false) {
    this.getAuth(isAuth);
    return this.service.get(endpoint);
  }

  auth(endpoint, payload) {
    return this.service.request({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: payload
    });
  }

  post(endpoint, payload) {
    this.getAuth(true);
    return this.service.request({
      method: 'POST',
      url: endpoint,
      responseType: 'json',
      data: payload
    });
  }

  put(endpoint, payload) {
    this.getAuth(true);
    return this.service.request({
      method: 'PUT',
      url: endpoint,
      responseType: 'json',
      data: payload
    });
  }

  patch(endpoint, payload) {
    this.getAuth(true);
    return this.service.request({
      method: 'PATCH',
      url: endpoint,
      responseType: 'json',
      data: payload
    });
  }

  delete(endpoint, payload) {
    this.getAuth(true);
    return this.service.delete(endpoint);
  }
}

export default new Service();
