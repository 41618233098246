export const FETCH_PRODUCT_FROM_BUSINESS = "FETCH_PRODUCT_FROM_BUSINESS";
export const FETCH_PRODUCT_FROM_BUSINESS_SUCCESS = "FETCH_PRODUCT_FROM_BUSINESS_SUCCESS";
export const FETCH_PRODUCT_FROM_BUSINESS_FAILED = "FETCH_PRODUCT_FROM_BUSINESS_FAILED";

export const FETCH_DETAIL_PRODUCT_BUSINESS = "FETCH_DETAIL_PRODUCT_BUSINESS";
export const FETCH_DETAIL_PRODUCT_BUSINESS_SUCCESS = "FETCH_DETAIL_PRODUCT_BUSINESS_SUCCESS";
export const FETCH_DETAIL_PRODUCT_BUSINESS_FAILED = "FETCH_DETAIL_PRODUCT_BUSINESS_FAILED";

export const UPDATE_BUSINESS_PRODUCT = "UPDATE_BUSINESS_PRODUCT";
export const UPDATE_BUSINESS_PRODUCT_SUCCESS = "UPDATE_BUSINESS_PRODUCT_SUCCESS";
export const UPDATE_BUSINESS_PRODUCT_FAILED = "UPDATE_BUSINESS_PRODUCT_FAILED";

export const DELETE_BUSINESS_PRODUCT = "DELETE_BUSINESS_PRODUCT";
export const DELETE_BUSINESS_PRODUCT_SUCCESS = "DELETE_BUSINESS_PRODUCT_SUCCESS";
export const DELETE_BUSINESS_PRODUCT_FAILED = "DELETE_BUSINESS_PRODUCT_FAILED";

export const CREATE_BUSINESS_PRODUCT = "CREATE_BUSINESS_PRODUCT";
export const CREATE_BUSINESS_PRODUCT_SUCCESS = "CREATE_BUSINESS_PRODUCT_SUCCESS";
export const CREATE_BUSINESS_PRODUCT_FAILED = "CREATE_BUSINESS_PRODUCT_FAILED";

export const IMPORT_BUSINESS_PRODUCT = "IMPORT_BUSINESS_PRODUCT";
export const IMPORT_BUSINESS_PRODUCT_SUCCESS = "IMPORT_BUSINESS_PRODUCT_SUCCESS";
export const IMPORT_BUSINESS_PRODUCT_FAILED = "IMPORT_BUSINESS_PRODUCT_FAILED";
