import React, { useEffect, useState } from "react";
import LeftSidebar from "../../components/left_sidebar";
import AddMetaTags from "../../components/add_meta_tags";
import { Card, Table } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { fetchSellingInvoices } from "redux/actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NumberFormat from "../../components/number_format";

const ListInvoiceRevenue= () => {

  const statusCheck = (string) => {
    switch (string) {
      case "delivered":
        return { text: "Đã giao hàng", color: "success" };
      case "pending":
        return { text: "Chờ xác nhận", color: "warning" };
      case "cancelled":
        return { text: "Đã hủy", color: "danger" };
      case "failed_delivery":
        return { text: "Giao thất bại", color: "danger" };
      case "returned":
        return { text: "Trả hàng", color: "warning" };
      case "ready_to_ship":
        return { text: "Chờ lấy hàng", color: "warning" };
      case "shipped":
        return { text: "Đang giao", color: "warning" };
      case "rejected":
        return { text: "Người bán từ chối đơn", color: "danger" };
      case "admin_rejected":
        return { text: "Quản trị viên từ chối đơn", color: "danger" };
      default:
        return { text: "", color: "primary" };
    }
  };

  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const start_date = searchParams.get("startDate");
  const end_date = searchParams.get("endDate");
  const listSellingInvoices = useSelector((state) => state.invoices.listSellingInvoices.data);
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const info_invoice = "both"

  const handleInputChange = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
  };

  useEffect(() => {
    dispatch(fetchSellingInvoices({ page: 1, limit: 24, status, type: "sales", end_date, start_date, info_invoice }));
  }, []);

  useEffect(() => {
    const sku_keyword = keyword
    dispatch(fetchSellingInvoices({ page: 1, limit: 24, status, type: "sales", end_date, start_date, info_invoice, sku_keyword }));
  }, [keyword]);

  return (
    <div>
      <AddMetaTags title={"Danh sách đơn hàng có doanh thu"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center saved-items">
          <div className="row">
          <Card className="p-0">
            <Card.Body>
            <div className="mkt-price mt-2">
              <div className="search-box">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12"></div>
                    <div className="search-top">
                      <input
                        className="form-control me-2 search-input"
                        type="text"
                        placeholder="Nhập mã đơn hàng"
                        aria-label="Search"
                        value={keyword}
                        onChange={handleInputChange}
                      />
                      <span className="icon-search" />
                    </div>
                  </div>
                </div>
              </div>
              <Table className="mt-2">
                <thead>
                  <tr className="text-center">
                    <th>
                      <div>Mã đơn hàng</div>
                    </th>
                    <th>
                      <div>Người đặt</div>
                    </th>
                    <th>
                      <div>Thời gian đặt</div>
                    </th>
                    <th>
                      <div>Tổng tiền</div>
                    </th>
                    <th>
                      <div>Trạng thái</div>
                    </th>
                  </tr>
                </thead>
                {listSellingInvoices.length > 0 ? (
                  listSellingInvoices?.map((item, index) => {
                    return (
                      <tbody id="invoice-listing" key={index}>
                        <tr className="text-center">
                          <td className="sku-detail product-detail">
                            <Link
                              to={`/nguoi-dung/thong-tin-don-hang-mua/${item.id}`}
                            >
                              {item.sku}
                            </Link>
                          </td>
                          <td>{item.name}</td>
                          <td>
                            <small>
                            { item?.invoice_user_id !== undefined ? moment(item.invoice_user_created_at).format("DD-MM-YYYY") : moment(item.created_at).format("DD-MM-YYYY") }
                            </small>
                          </td>
                          <td>
                            <NumberFormat>{ item?.invoice_user_id !== undefined ? item.total_price : item.price_total }</NumberFormat>
                            <span className="ml-1 red-clr font-italic">VNĐ</span>
                          </td>
                          <td>
                            <div className={`mt-1 badge badge-${ statusCheck(item.status).color }`} >
                              {statusCheck(item.status).text}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr >
                      <td className="empty-data">Không có đơn hàng</td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Card.Body>
          </Card>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListInvoiceRevenue;
