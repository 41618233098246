import React, { useEffect, useState, useContext } from "react";
import { toastError, toastErrorDownloadApp } from "helpers/toastHelper";
import { DOMAIN } from "constants";
import { validURL } from "lib";
import { isIOS, isAndroid } from "react-device-detect";
import AppContext from "components/app_context";

function AddPost(props) {
  let [images, setImages] = useState([]);
  let [previewURLs, setPreviewURLs] = useState([]);
  let [title, setTitle] = useState("");
  const { addPost, current_user } = props;
  const { isMobile } = useContext(AppContext);

  const uploadMultipleFiles = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
      let url = URL.createObjectURL(file);
      setImages((images) => [...images, file]);
      setPreviewURLs((previewURLs) => [...previewURLs, url]);
    });
  };

  const selectImage = () => {
    isMobile
      ? toastErrorDownloadApp()
      : toastError("Vui lòng tải app để đăng bài");
  };

  const onChange = (e) => {
    setTitle("");
    isMobile
      ? toastErrorDownloadApp()
      : toastError("Vui lòng tải app để đăng bài");
  };

  const submitForm = (e) => {
    if (isAndroid) {
      window.location.assign(
        "https://play.google.com/store/apps/details?id=com.advn.hainong"
      );
    }
    if (isIOS) {
      window.location.assign(
        "https://apps.apple.com/vn/app/2nông-diễn-đàn-nông-nghiệp/id1540198381"
      );
    }
    return isMobile
      ? toastErrorDownloadApp(isMobile)
      : toastError("Vui lòng tải app để đăng bài");
  };

  const resetValues = () => {
    setImages([]);
    setPreviewURLs([]);
    setTitle("");
  };

  const onImageRemove = (idx) => {
    const temp = [...images];
    temp.splice(idx, 1);
    setImages(temp);

    const temp_pre = [...previewURLs];
    temp_pre.splice(idx, 1);
    setPreviewURLs(temp_pre);
  };

  const linkImage = () => {
    if (current_user?.image === null) {
      return "/images/avatar.png";
    }

    if (validURL(current_user?.image)) {
      return current_user?.image;
    } else if (!validURL(current_user?.image)) {
      return DOMAIN + current_user?.image;
    }
  };
  return (
    <form className="post-box add-post">
      <div className="inpt-cntn">
        <textarea
          className="form-control"
          id="message"
          name="message"
          onChange={onChange}
          value={title}
          placeholder="Thông tin cần chia sẻ..."
          required
        />
        {current_user ? (
          <div
            className="bg-img-orgnal avtar45 position-absolute"
            style={{ background: `url(${linkImage()})` }}
          />
        ) : (
          <div
            className="bg-img-orgnal avtar45 position-absolute"
            style={{ background: 'url("/images/avatar.png")' }}
          />
        )}
      </div>
      <div className="form-group multi-preview">
        {previewURLs.map(function (url, index) {
          return (
            <div key={index} className="image">
              <img src={url} alt="..." />
              <span className="close" onClick={() => onImageRemove(index)}>
                &times;
              </span>
            </div>
          );
        })}
      </div>
      <div className="btn-post d-flex align-items-center">
        <div className="icon-library" onClick={() => selectImage()} />
        <button
          type="button"
          className="ms-auto btn modal-dialog-centered"
          onClick={submitForm}
        >
          đăng
        </button>
      </div>
    </form>
  );
}

export default AddPost;
