import React, { useEffect, useState } from 'react';
import { Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { fetchListBusinessEmployees, addNewBusinessEmployee, updateBusinessEmployee, deleteBusinessEmployee } from "actions/business";
import ModalAddEmployee from './ModalAddEmployee';
import Swal from 'sweetalert2';
import { toastSuccess } from 'helpers/toastHelper';

function BusinessEmployee() {
  const { businessId } = useParams();
  const [editData, setEditData] = useState(null);
  const [addNewEmploy, setAddNewEmploy] = useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.business.business_employee);

  useEffect(() => {
    dispatch(fetchListBusinessEmployees({ businessId: businessId }))
  }, [businessId]);

  const handleEditEmployee = (values, callback, employeeId) => {
    dispatch(updateBusinessEmployee({ payload: values, callback: callback, businessId: businessId, employeeId: employeeId }));
  }

  const handleAddnewEmployee = (values, callback) => {
    dispatch(addNewBusinessEmployee({ payload: values, callback: callback, businessId: businessId }))
  }
  const onToggle = () => {
    setEditData(null);
    setAddNewEmploy(false);
  }

  const handleDeleteEmployee = (employeeId) => {
    Swal.fire({
      title: 'Bạn có chắc chắn?',
      text: "Xóa nhân viên này",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: "Đóng"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBusinessEmployee({
          businessId: businessId, employeeId: employeeId, callback: () => {
            onToggle();
            toastSuccess("Xóa nhân viên thành công");
          }
        }))
      }
    })
  }

  function TranslatePermissionEmployee(permission) {
    const originalObject = permission.reduce((obj, key) => {
      obj[key] = key;
      return obj;
    }, {});
    const values = Object.values(originalObject);
    return values;
  }

  return (
    <>
      <div className="products">
        <AddMetaTags title={"Danh sách nhân viên danh nghiệp"} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="cont-center">
              <div className="row mt-1">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-between">
                    <h5 className="title-line-lt text-uppercase fw-bold">
                      <Link to={`/lien-ket-danh-nghiep/chuc-nang/${businessId}`}>
                        <span className="d-block">DANH SÁCH NHÂN VIÊN</span>
                      </Link>
                    </h5>
                  </div>
                  <div className="btn btn-primary" onClick={() => setAddNewEmploy(true)}>Thêm nhân viên</div>
                </div>
                <Card className="p-0 mt-3">
                  <Card.Body>
                    <Table bordered className="m-0">
                      <thead>
                        <tr>
                          <th>Tên nhân viên</th>
                          <th>Số điện thoại</th>
                          <th>Phân quyền</th>
                          <th>Tùy chọn</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((employee) => {
                          return (
                            <tr key={employee.id}>
                              <td>
                                <div className='fw-bold'>{employee?.user_name}</div>
                              </td>
                              <td>
                                {employee?.user_phone}
                              </td>
                              <td>
                                {TranslatePermissionEmployee(employee.permission).map((permission) => {
                                  return (<>&nbsp;<div className="mt-1 badge badge-warning">{permission === 'products' ? 'Đăng sản phẩm' : 'Duyệt đơn hàng'}</div></>)
                                })}
                                {employee.notification === 1 && <>&nbsp; <div className="mt-1 badge badge-warning">Nhận thông báo</div></>}
                              </td>
                              <td>
                                <Link to="#" onClick={() => setEditData(employee)}>
                                  <span className="btn btn-link">Sửa</span>
                                </Link>
                                <span
                                  className="btn btn-link"
                                  onClick={() => handleDeleteEmployee(employee.id)}
                                >
                                  Xoá
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAddEmployee show={addNewEmploy} onToggle={onToggle} onAdd={handleAddnewEmployee} editData={editData} onEdit={handleEditEmployee} businessId={businessId} />
    </>
  );
}

export default BusinessEmployee;
