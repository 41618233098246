import React, { useEffect, useState, useContext, useRef } from "react";
import AddMetaTags from "../../components/add_meta_tags";
import AppContext from "components/app_context";
import LeftSidebar from "../../components/left_sidebar";
import { Card, Table } from "react-bootstrap";
import { getPointSetting } from "apis/gift";
import ScrollToTop from "components/ScrollToTop";

function GiftReceiveDetail() {
  const { current_user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const isMounted = useRef(true);

  const fetchPointSetting = async () => {
    const response = await getPointSetting();
    const { data } = response.data;
    setData(data);
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchPointSetting();
    }
    return () => {
      setData([]);
    };
  }, [current_user]);
  return (
    <>
      <ScrollToTop>
        <div className="products">
          <AddMetaTags title={"Danh sách sản phẩm của danh nghiệp"} />
          <div className="row">
            <LeftSidebar />
            <div className="col-md-9 col-sm-12">
              <div className="cont-center">
                <div className="row mt-1">
                  <div className="d-flex justify-content-between">
                    <h5 className="title-line-lt text-uppercase fw-bold">
                      <span className="d-block">Chi tiết nhận điểm thưởng</span>
                    </h5>
                  </div>
                  <Card className="p-0 mt-3">
                    <Card.Body>
                      <Table bordered className="m-0">
                        <thead>
                          <tr>
                            <th>Tên nhiệm vụ</th>
                            <th>Số điểm thưởng</th>
                            <th>Số lần áp dụng/ngày</th>
                            {current_user && <th>Tiến độ</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((result) => {
                            const isComplete = result.point_histories_count >= result.times_day;
                            return (
                              <tr key={result.id}>
                                <td style={{minWidth: "130px"}}>
                                  <div className="fw-bold">{result?.event_name}</div>
                                </td>
                                <td>{result?.point_give}</td>
                                <td>
                                  <div className="price01">
                                    <span>Số lần:</span>
                                    <span className="price-clr">
                                      &nbsp;
                                      {result?.times_day}
                                    </span>
                                  </div>
                                </td>
                                {current_user && (
                                  <td>
                                    <div className="price01 row d-flex align-items-center">
                                      <div className="col">
                                        <span
                                          style={{
                                            color: `${isComplete ? "#19c119" : "red"}`,
                                          }}>
                                          &nbsp;
                                          {result.point_histories_count}/{result.times_day}
                                        </span>
                                      </div>
                                      <div className="col-8 d-flex align-items-center">
                                        <img
                                          src={`./images/${isComplete ? "check" : "pending"}.png`}
                                          alt="done"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <div className="gift-sm-none">
                                          <span >{isComplete ? "Hoàn thành" : "Đang thực hiện"}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollToTop>
    </>
  );
}

export default GiftReceiveDetail;
