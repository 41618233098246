import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContext from 'components/app_context';
import AddMetaTags from 'components/add_meta_tags';
import LeftSidebar from 'components/left_sidebar';
import ListGift from './Components/listGift';
import TopUpData from './Components/topupData';
import { GiftForYou } from './Components/GiftForYou';
import Loader from 'components/loader';
import { toastError } from 'helpers/toastHelper';
import { Link } from 'react-router-dom';
import {
  exchangePointsForGift,
  topUpDataForUser,
  fetchListGift,
  fetchCatalogueGiftAndProduct,
  updateQuantityExchangedOfGift,
} from 'actions/gift';
import { fetchPointOfUser, updatePointOfUser } from 'actions/auth';
import './gift.scss';
import { toastErrorLogin } from '../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

function GiftStore() {
  const dispatch = useDispatch();
  const { current_user } = useContext(AppContext);
  const giftCatalogues = useSelector(state => state.gift.catalogue_gift_products);
  const point = useSelector(state => state.login.pointOfUser.point);
  const giftForYou = useSelector(state => state.gift.listGift.data);
  const { isLoading, catalogue } = giftCatalogues;
  const [pointOfGift, setPointOfGift] = useState(0);

  useEffect(() => {
    dispatch(fetchCatalogueGiftAndProduct({ page: 1 }));
    if (current_user) {
      dispatch(fetchPointOfUser(current_user?.id));
      dispatch(fetchListGift({ page: 1 }));
    }
  }, [dispatch, current_user]);

  const handleExchangePointsToGift = store_id => {
    if (current_user) {
      dispatch(
        exchangePointsForGift({
          store_type: 'StoreGift',
          store_id: store_id,
          new_point: point - parseInt(pointOfGift),
        }),
      );
    } else {
      toastErrorLogin(isMobile);
    }
  };

  const handleTopUpData = package_id => {
    if (current_user) {
      dispatch(topUpDataForUser({ package_id: package_id }));
    } else {
      toastErrorLogin(isMobile);
    }
  };

  const getNumberDay = day => {
    let timeNow = new Date();
    let daynumber = Math.round((new Date(day) - timeNow) / 1000 / 60 / 60 / 24);
    var delta = Math.abs(new Date(day) - timeNow) / 1000;

    var hours = Math.floor(delta / 3600) % 24;
    var minutes = Math.floor(delta / 60) % 60;

    if (daynumber >= 1) {
      return `Còn ${daynumber} ngày`;
    } else if (hours) {
      return `Còn ${hours} giờ`;
    } else if (minutes) {
      return `Còn ${minutes} phút`;
    } else {
      return `Hết hạn`;
    }
  }; // hàm trả về số ngày

  const checkDueDay = day => {
    let timeNow = new Date();
    let number = Math.round((new Date(day) - timeNow) / 1000 / 60 / 60 / 24);
    return number;
  };

  const checkGiftStatusComingSoon = (start_date, end_day) => {
    let timeNow = new Date();
    return timeNow < new Date(start_date) && timeNow < new Date(end_day);
  };

  const giftStatus = {
    ACTIVE: 'active',
    DISABLE: 'disable',
    COMING_SOON: 'coming_soon',
  };

  function hiddenButtonExchangGift(status) {
    switch (status) {
      case giftStatus.ACTIVE:
        return false;
      case giftStatus.DISABLE:
        return true;
      case giftStatus.COMING_SOON:
        return true;
      default:
        return true;
    }
  }

  return (
    <div className="gifts">
      <AddMetaTags title={'Danh sách quà tặng'} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12 order-md-1 order-xs-2">
          <span className="font-weight-bold fs-2 d-flex justify-content-center">QUÀ TẶNG</span>
          <div className="d-flex title-top">
            <div className="ms-auto">
              {current_user ? (
                isLoading ? (
                  <button className="btn-style btn-success" disabled>
                    <div className="btn-txt">
                      <span className="fw-bold">Nhật ký đổi quà</span>
                      <span className="shape"></span>
                    </div>
                    <span className="arrow"></span>
                  </button>
                ) : (
                  <Link to="/nhat-ky-doi-qua">
                    <button className="btn-style btn-success">
                      <div className="btn-txt">
                        <span className="fw-bold">Nhật ký đổi quà</span>
                        <span className="shape"></span>
                      </div>
                      <span className="arrow"></span>
                    </button>
                  </Link>
                )
              ) : (
                ''
              )}
              {current_user && (
                <div className="fs-6 d-block text-center">
                  Điểm bạn đang có: <span className="fw-bold red-clr">{point}</span>
                </div>
              )}
            </div>
          </div>

          <Loader isLoading={isLoading} />
          {giftForYou.length > 0 ? (
            <div className="cont-center">
              <GiftForYou
                giftForYou={giftForYou}
                userPoint={point}
                setPointOfGift={setPointOfGift}
                handleExchangePointsToGift={handleExchangePointsToGift}
                getNumberDay={getNumberDay}
                checkDueDay={checkDueDay}
                hiddenButtonExchangGift={hiddenButtonExchangGift}
                checkGiftStatusComingSoon={checkGiftStatusComingSoon}
              />

              <TopUpData userPoint={point} handleTopUpData={handleTopUpData} getNumberDay={getNumberDay} />

              {catalogue.map(item => {
                const { id, name } = item;
                const catalogue_info = { id, name };
                return (
                  <ListGift
                    setPointOfGift={setPointOfGift}
                    catalogue_info={catalogue_info}
                    allGift={giftForYou}
                    handleExchangePointsToGift={handleExchangePointsToGift}
                    userPoint={point}
                    getNumberDay={getNumberDay}
                    checkDueDay={checkDueDay}
                    checkGiftStatusComingSoon={checkGiftStatusComingSoon}
                  />
                );
              })}
            </div>
          ) : (
            <div className="my-5 text-center font-italic text-secondary">
              <div className="">Hiện tại chưa có quà tặng</div>
              <div className="">Vui lòng quay lại sau</div>
            </div>
          )}
          <Link to={`/chi-tiet-nhan-thuong`}>
            <div className="d-flex justify-content-center">
              <button className="button_glowing">Nhận Quà Ngay</button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GiftStore;
