import React, { memo } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";
import { checkPriceDiffer } from "../lib";
import DownArrowIcon from "../assets/images/down-arrow.png";
import UpArrowIcon from "../assets/images/incr-arr.png";
import EqualArrowIcon from "../assets/images/2arr-yel.png";

const priceStyle = (priceDifference) => ({
  color: priceDifference > 0 ? "#1CC619" : "#ef4923"
});

const SidebarMarket = memo((props) => {
  return (
    <div className="gctt-box mkt-prod" style={{ border: "1px solid #e1e1e1" }}>
      <div className="title text-center mt-3">
        <h4 className="mb-0 fw-bold text-uppercase">Giá cả thị trường</h4>
        <small className="primary-clr fw-semibold">
          Hôm nay, <span>{moment().format("DD.MM - hh:mm A")}</span>
        </small>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Sản phẩm</th>
            <th scope="col" className="text-center">Giá</th>
            <th scope="col" className="text-center">Khu vực</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((market, index) => {
            const priceDifference = market.get_market_price_last?.price_difference || 0;
            return (
              <tr key={index}>
                <td>
                  <Link to={`/modules/thong-tin-gia-ca-thi-truong/${market.id}`}>
                    <div className="fw-semibold line-clamp-02">{market.title}</div>
                  </Link>
                </td>
                <td className="text-right">
                  <div className="fw-semibold fs-12">
                    <div>
                      <NumberFormat>{market.get_market_price_last?.price}</NumberFormat>
                      <span style={{ marginLeft: "2px" }}>&nbsp;đ</span>
                    </div>
                  </div>
                  <span className="redu-pri fs-10" style={priceStyle(priceDifference)}>
                    <img
                      src={priceDifference !== 0 ? (priceDifference > 0 ? UpArrowIcon : DownArrowIcon) : EqualArrowIcon}
                      style={{ width: "10px", height: "10px", marginBottom: "2px" }}
                    />
                    {priceDifference !== 0 && (
                      <span className="font-italic">
                        {priceDifference > 0 && "+"}
                        <NumberFormat>{checkPriceDiffer(priceDifference)}</NumberFormat> đ
                      </span>
                    )}
                  </span>
                </td>
                <td className="text-center text-secondary">{market.province_name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Link
        to={`/modules/gia-ca-thi-truong`}
        className="more-btn-fll text-center d-block">
        <u>Xem thêm</u>
      </Link>
    </div>
  );
});

export default SidebarMarket;