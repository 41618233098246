import * as businessConst from 'constants/business_association';
import * as businessProductConst from 'constants/product_business';
import * as businessInvoiceConst from 'constants/invoice_business';
import * as businessEmployeeConst from 'constants/employee_business';

export const fetchListBusiness = params => {
  return {
    type: businessConst.FETCH_LIST_BUSINESS,
    payload: { params },
  };
};

export const fetchListBusinessSuccess = data => {
  return {
    type: businessConst.FETCH_LIST_BUSINESS_SUCCESS,
    payload: { data },
  };
};

export const fetchListBusinessFailed = error => {
  return {
    type: businessConst.FETCH_LIST_BUSINESS_FAILED,
    payload: { error },
  };
};

export const fetchBusinessDetail = businessId => {
  return {
    type: businessConst.FETCH_BUSINESS_DETAIL,
    payload: { businessId },
  };
};

export const fetchBusinessDetailSuccess = data => {
  return {
    type: businessConst.FETCH_BUSINESS_DETAIL_SUCCESS,
    payload: { data },
  };
};

export const fetchBusinessDetailFailed = error => {
  return {
    type: businessConst.FETCH_BUSINESS_DETAIL_FAILED,
    payload: { error },
  };
};

export const fetchBusinessProduct = params => {
  return {
    type: businessProductConst.FETCH_PRODUCT_FROM_BUSINESS,
    payload: { params },
  };
};

export const fetchBusinessProductSuccess = data => {
  return {
    type: businessProductConst.FETCH_PRODUCT_FROM_BUSINESS_SUCCESS,
    payload: { data },
  };
};

export const fetchBusinessProductFailed = error => {
  return {
    type: businessProductConst.FETCH_PRODUCT_FROM_BUSINESS_FAILED,
    payload: { error },
  };
};

export const deleteBusinessProduct = params => {
  return {
    type: businessProductConst.DELETE_BUSINESS_PRODUCT,
    payload: { params },
  };
};

export const deleteBusinessProductSuccess = data => {
  return {
    type: businessProductConst.DELETE_BUSINESS_PRODUCT_SUCCESS,
    payload: { data },
  };
};

export const deleteBusinessProductFailed = error => {
  return {
    type: businessProductConst.DELETE_BUSINESS_PRODUCT_FAILED,
    payload: { error },
  };
};

export const createBusinessProduct = params => {
  return {
    type: businessProductConst.CREATE_BUSINESS_PRODUCT,
    payload: { params },
  };
};

export const createBusinessProductSuccess = data => {
  return {
    type: businessProductConst.CREATE_BUSINESS_PRODUCT_SUCCESS,
    payload: { data },
  };
};

export const createBusinessProductFailed = error => {
  return {
    type: businessProductConst.CREATE_BUSINESS_PRODUCT_FAILED,
    payload: { error },
  };
};
export const importBusinessProduct = params => {
  return {
    type: businessProductConst.IMPORT_BUSINESS_PRODUCT,
    payload: { params },
  };
};

export const importBusinessProductSuccess = data => {
  return {
    type: businessProductConst.IMPORT_BUSINESS_PRODUCT_SUCCESS,
    payload: { data },
  };
};

export const importBusinessProductFailed = error => {
  return {
    type: businessProductConst.IMPORT_BUSINESS_PRODUCT_FAILED,
    payload: { error },
  };
};

export const updateBusinessProduct = params => {
  return {
    type: businessProductConst.UPDATE_BUSINESS_PRODUCT,
    payload: { params },
  };
};

export const updateBusinessProductSuccess = data => {
  return {
    type: businessProductConst.UPDATE_BUSINESS_PRODUCT_SUCCESS,
    payload: { data },
  };
};

export const updateBusinessProductFailed = error => {
  return {
    type: businessProductConst.UPDATE_BUSINESS_PRODUCT_FAILED,
    payload: { error },
  };
};

export const fetchListBusinessInvoice = params => {
  return {
    type: businessInvoiceConst.FETCH_INVOICE_BUSINESS,
    payload: { params },
  };
};

export const fetchListBusinessInvoiceSuccess = data => {
  return {
    type: businessInvoiceConst.FETCH_INVOICE_BUSINESS_SUCCESS,
    payload: { data },
  };
};

export const fetchListBusinessInvoiceFailed = error => {
  return {
    type: businessInvoiceConst.FETCH_INVOICE_BUSINESS_FAILED,
    payload: { error },
  };
};

export const fetchBusinessInvoiceDetail = params => {
  return {
    type: businessInvoiceConst.FETCH_INVOICE_BUSINESS_DETAIL,
    payload: { params },
  };
};

export const fetchBusinessInvoiceDetailSuccess = data => {
  return {
    type: businessInvoiceConst.FETCH_INVOICE_BUSINESS_DETAIL_SUCCESS,
    payload: { data },
  };
};

export const fetchBusinessInvoiceDetailFailed = error => {
  return {
    type: businessInvoiceConst.FETCH_INVOICE_BUSINESS_DETAIL_FAILED,
    payload: { error },
  };
};

export const updateBusinessInvoice = params => {
  return {
    type: businessInvoiceConst.UPDATE_INVOICE_BUSINESS,
    payload: { params },
  };
};

export const updateBusinessInvoiceSuccess = data => {
  return {
    type: businessInvoiceConst.UPDATE_INVOICE_BUSINESS_SUCCESS,
    payload: { data },
  };
};

export const updateBusinessInvoiceFailed = error => {
  return {
    type: businessInvoiceConst.UPDATE_INVOICE_BUSINESS_FAILED,
    payload: { error },
  };
};

export const fetchListBusinessEmployees = params => {
  return {
    type: businessEmployeeConst.FETCH_LIST_EMPLOYEE,
    payload: { params },
  };
};

export const fetchListBusinessEmployeesSuccess = data => {
  return {
    type: businessEmployeeConst.FETCH_LIST_EMPLOYEE_SUCCESS,
    payload: { data },
  };
};

export const fetchListBusinessEmployeesFailed = error => {
  return {
    type: businessEmployeeConst.FETCH_LIST_EMPLOYEE_FAILED,
    payload: { error },
  };
};

export const addNewBusinessEmployee = params => {
  return {
    type: businessEmployeeConst.ADD_NEW_EMPLOYEE,
    payload: { params },
  };
};

export const addNewBusinessEmployeeSuccess = data => {
  return {
    type: businessEmployeeConst.ADD_NEW_EMPLOYEE_SUCCESS,
    payload: { data },
  };
};

export const addNewBusinessEmployeeFailed = error => {
  return {
    type: businessEmployeeConst.ADD_NEW_EMPLOYEE_FAILED,
    payload: { error },
  };
};

export const updateBusinessEmployee = params => {
  return {
    type: businessEmployeeConst.UPDATE_EMPLOYEE,
    payload: { params },
  };
};

export const updateBusinessEmployeeSuccess = data => {
  return {
    type: businessEmployeeConst.UPDATE_EMPLOYEE_SUCCESS,
    payload: { data },
  };
};

export const updateBusinessEmployeeFailed = error => {
  return {
    type: businessEmployeeConst.UPDATE_EMPLOYEE_FAILED,
    payload: { error },
  };
};

export const deleteBusinessEmployee = params => {
  return {
    type: businessEmployeeConst.DELETE_EMPLOYEE,
    payload: { params },
  };
};

export const deleteBusinessEmployeeSuccess = data => {
  return {
    type: businessEmployeeConst.DELETE_EMPLOYEE_SUCCESS,
    payload: { data },
  };
};

export const deleteBusinessEmployeeFailed = error => {
  return {
    type: businessEmployeeConst.DELETE_EMPLOYEE_FAILED,
    payload: { error },
  };
};

export const fetchEnterprises = params => {
  return {
    type: businessConst.FETCH_ENTERPRISES,
    payload: { params },
  };
};

export const fetchEnterprisesSuccess = data => {
  return {
    type: businessConst.FETCH_ENTERPRISES_SUCCESS,
    payload: { data },
  };
};

export const fetchEnterprisesFailed = error => {
  return {
    type: businessConst.FETCH_ENTERPRISES_FAILED,
    payload: { error },
  };
};

export const fetchProductOfEnterprises = params => {
  return {
    type: businessConst.FETCH_PRODUCT_OF_ENTERPRISES,
    payload: { params },
  };
};

export const fetchProductOfEnterprisesSuccess = data => {
  return {
    type: businessConst.FETCH_PRODUCT_OF_ENTERPRISES_SUCCESS,
    payload: { data },
  };
};

export const fetchProductOfEnterprisesFailed = error => {
  return {
    type: businessConst.FETCH_PRODUCT_OF_ENTERPRISES_FAILED,
    payload: { error },
  };
};
