import React from 'react';
import AddMetaTags from '../../components/add_meta_tags';
import LeftSidebar from '../../components/left_sidebar';
import HighlightProducts from './components/highlightProducts';
import SidebarCatalogueOfProduct from 'components/sidebar_catalogueOfProduct';

const SharedUIMarket = ({ data }) => {
  const { products, onLoadMore, HeadComponent, productLimit } = data;
  return (
    <div className="products">
      <AddMetaTags title={'Danh sách sản phẩm'} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-6 col-sm-12 order-md-1 order-xs-2">
          <div className="cont-center">
            {/* <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">{title}</p> */}
            {HeadComponent}
            {products && (
              <HighlightProducts data={products} onLoadmoreProduct={onLoadMore} productLimit={productLimit} />
            )}
          </div>
        </div>
        <SidebarCatalogueOfProduct />
      </div>
    </div>
  );
};

export default SharedUIMarket;
