import React, { Component } from 'react'

class Contact extends Component {
  onSubmit = () => {
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="wrap-container">
        <div className="row">
          <div className="col-md-12">
            <div style={{textAlign: 'center'}}>
              <h2>Liên hệ</h2>
              <p>Swing by for a cup of coffee, or leave us a message:</p>
            </div>
            <div className="row">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Tên</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Tên" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Điện thoại</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Điện thoại" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Email</label>
                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Nội dung</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
