import React, { useState } from 'react';
import FadeIn from 'react-fade-in';
import SubComment from './subComment';
import { toastError } from 'helpers/toastHelper';
import Modal from 'react-bootstrap/Modal';
import ModalDeleteConfirmComment from 'components/shared/modalDeleteConfirmComment/modal';
import Dropdown from 'react-bootstrap/Dropdown';
import InputComment from '../../../pages/postDetail/components/InputComment';
import CommentBody from '../../CommentBody';
import { validateStringEditor } from 'lib';
function Comment(props) {
  let {
    isToggle,
    post,
    postId,
    comments,
    handleSortComment,
    activeSort,
    current_user,
    deleteComment,
    options,
    class_like_comment,
    LikeComment,
    showReportComment,
    setContent,
    handleCloseReportComment,
    reportComment,
    handleShowReportComment,
    content,
    submitReportComment,
    setReportComment,
    dataUpdateComment,
    actionUpdateComment,
    isLoadMore,
    handleLoadMoreComment,
    actionReplyComment,
    dataUpdateAnswerComment,
    actionReportComment,
  } = props;
  let [id, setId] = useState(null);
  const [idComment, setIdComment] = useState(null);
  const [idSubComment, setIdSubComment] = useState(null);
  const [typeInput, setTypeInput] = useState('');

  const showInputComment = id => setIdComment(id);
  const showSubComment = id => setIdSubComment(id);

  const submitReply = (id, content, user_id) => {
    const validation = validateStringEditor(content);
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        if (typeInput === 'reply') {
          actionReplyComment(id, content, user_id);
        } else {
          actionUpdateComment('Comment', idComment, content);
        }
    }
    setIdComment(null);
    setTypeInput('');
  };

  return (
    <>
      {isToggle && postId == post.id && comments.length > 0 && (
        <div className="cmt-listing cmt-listing mrgt-0 mrgb-10 border-0">
          <div className="actions-sort-cmt d-flex mt-3">
            <ul>
              <li
                className={`${activeSort === 'asc' && 'active'} action-sort-like`}
                onClick={() => handleSortComment('asc')}
              >
                Yêu thích nhất
              </li>
              <li
                className={`${activeSort === 'new' && 'active'} action-sort-new`}
                onClick={() => handleSortComment('new')}
              >
                Bình luận mới nhất
              </li>
            </ul>
          </div>
          {comments.map(function (comment, index) {
            return (
              <FadeIn key={index}>
                <div key={comment.id} className="mt-1">
                  <div className="user-inf border-0">
                    <CommentBody comment={comment} />
                    <div className="mt-2 actions d-flex align-items-center mh-35">
                      <div className="d-flex align-items-center">
                        <div
                          className={`${class_like_comment}  hover cmnt d-inline-block mr-3`}
                          onClick={() => LikeComment(comment.classable_id, comment.classable_type, comment.user_liked)}
                        >
                          <a className={`${comment?.user_liked ? 'active' : ''} like d-flex align-items-center`}>
                            {comment.user_liked ? (
                              <div className="d-inline-block">
                                <span className="icon-like01"></span>
                                <span>Đã thích</span>
                              </div>
                            ) : (
                              <div className="d-inline-block">
                                <span className="icon-like01"></span>
                                <span>Thích</span>
                              </div>
                            )}
                            <small>({comment.total_likes === null ? 0 : comment.total_likes})</small>
                          </a>
                        </div>

                        {comment?.total_answers !== 0 && (
                          <div className="d-inline-block cmnt mr-3" onClick={() => showSubComment(comment.id)}>
                            <a className="d-flex align-items-center">
                              <span className="icon-comment-line"></span>
                              <span>Bình luận</span>
                              <small>({comment?.total_answers})</small>
                            </a>
                          </div>
                        )}

                        <Modal
                          show={showReportComment}
                          onHide={handleCloseReportComment}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          {/* modal báo cáo bình luận */}
                          <Modal.Header closeButton>
                            <Modal.Title>Báo cáo bình luận</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <input
                              className="form-control"
                              name="message"
                              placeholder="Nhập lý do"
                              value={reportComment}
                              onChange={e => setReportComment(e.target.value)}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => submitReportComment(current_user.id, reportComment)}
                            >
                              {' '}
                              gửi
                            </button>
                          </Modal.Footer>
                        </Modal>

                        <div
                          className="d-inline-block"
                          onClick={() => {
                            showInputComment(comment.id);
                            setTypeInput('reply');
                          }}
                        >
                          <a>
                            <span>Trả lời</span>
                          </a>
                        </div>
                      </div>

                      <div className="dropdown post-report ms-auto">
                        <Dropdown>
                          <Dropdown.Toggle as="div" className="dropdown post-report ms-auto">
                            <button className="btn dropdown-toggle">
                              <span className="icon-menu-dot"></span>
                            </button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                            <Dropdown.Item as="li" className="dropdown-item">
                              <div
                                className=""
                                onClick={() => handleShowReportComment(comment.id, comment.classable_type)}
                              >
                                <a>Vi phạm</a>
                              </div>
                            </Dropdown.Item>
                            {current_user?.id == comment.user_id ||
                            current_user?.manager_type == 'admin' ||
                            current_user?.manager_type == 'smod' ? (
                              <Dropdown.Item as="li" className="dropdown-item">
                                <div
                                  className=""
                                  onClick={() => {
                                    setContent(comment.content);
                                    showInputComment(comment.id);
                                    setTypeInput('edit');
                                  }}
                                >
                                  <span>Cập nhật</span>
                                </div>
                              </Dropdown.Item>
                            ) : (
                              ''
                            )}
                            {current_user?.id == comment.user_id ||
                            current_user?.manager_type == 'admin' ||
                            current_user?.manager_type == 'smod' ? (
                              <Dropdown.Item as="li" onClick={() => setId(comment.id)} className="dropdown-item">
                                <div className="">
                                  <span>Xóa</span>
                                </div>
                              </Dropdown.Item>
                            ) : (
                              ''
                            )}
                            <ModalDeleteConfirmComment
                              onDelete={() => deleteComment(id, current_user?.manager_type, comment.classable_type)}
                              isOpen={id}
                              onToggle={() => setId(null)}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  {idComment === comment.id && (
                    <InputComment
                      current_user={current_user}
                      commentContent={content}
                      setCommentContent={setContent}
                      submitReplyComment={submitReply}
                      comment={comment}
                      typeInput={typeInput}
                    />
                  )}
                  {idSubComment === comment.id && (
                    <SubComment
                      dataUpdateComment={dataUpdateComment}
                      deleteComment={deleteComment}
                      current_user={current_user}
                      LikeComment={LikeComment}
                      comment={comment}
                      id={comment.id}
                      dataUpdateAnswerComment={dataUpdateAnswerComment}
                      actionUpdateComment={actionUpdateComment}
                      actionReportComment={actionReportComment}
                    />
                  )}
                </div>
              </FadeIn>
            );
          })}
          {isLoadMore && (
            <span className="load-more-comment-post" onClick={() => handleLoadMoreComment(post.id)}>
              Xem thêm
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default Comment;
