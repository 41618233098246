import * as refundInvoiceConst from "constants/refund_invoice";

// Fetch Buying Refund Invoices
export const fetchBuyingRefundInvoices = (params) => {
  return {
    type: refundInvoiceConst.FETCH_BUYING_REFUND_INVOICES,
    payload: { params },
  };
};

export const fetchBuyingRefundInvoicesSuccess = (data) => {
  return {
    type: refundInvoiceConst.FETCH_BUYING_REFUND_INVOICES_SUCCESS,
    payload: { data },
  };
};

export const fetchBuyingRefundInvoicesFailed = (error) => {
  return {
    type: refundInvoiceConst.FETCH_BUYING_REFUND_INVOICES_FAILED,
    payload: { error },
  };
};

// Fetch Selling Refund Invoices
export const fetchSellingRefundInvoices = (params) => {
  return {
    type: refundInvoiceConst.FETCH_SELLING_REFUND_INVOICES,
    payload: { params },
  };
};

export const fetchSellingRefundInvoicesSuccess = (data) => {
  return {
    type: refundInvoiceConst.FETCH_SELLING_REFUND_INVOICES_SUCCESS,
    payload: { data },
  };
};

export const fetchSellingRefundInvoicesFailed = (error) => {
  return {
    type: refundInvoiceConst.FETCH_SELLING_REFUND_INVOICES_FAILED,
    payload: { error },
  };
};

// Fetch Detail Refund Invoice
export const fetchDetailRefundInvoice = (params) => {
  return {
    type: refundInvoiceConst.FETCH_DETAIL_REFUND_INVOICE,
    payload: { params },
  };
};

export const fetchDetailRefundInvoiceSuccess = (data) => {
  return {
    type: refundInvoiceConst.FETCH_DETAIL_REFUND_INVOICE_SUCCESS,
    payload: { data },
  };
};

export const requestComplaintStatus = (params) => {
  return {
    type: refundInvoiceConst.REQUEST_COMPLAINT_STATUS,
    payload: { params },
  }
}

export const requestComplaintStatusSuccess = (data) => {
  return {
    type: refundInvoiceConst.REQUEST_COMPLAINT_STATUS_SUCCESS,
    payload: { data },
  }
}

export const requestComplaintStatusFailed = (error) => {
  return {
    type: refundInvoiceConst.REQUEST_COMPLAINT_STATUS_FAILED,
    payload: { error },
  }
}

export const createRefundInvoice = (params) => {
  return {
    type: refundInvoiceConst.CREATE_REFUND_INVOICE,
    payload: { params },
  };
};

export const createRefundInvoiceSuccess = (data) => {
  return {
    type: refundInvoiceConst.CREATE_REFUND_INVOICE_SUCCESS,
    payload: { data },
  };
};

export const createRefundInvoiceFailed = (error) => {
  return {
    type: refundInvoiceConst.CREATE_REFUND_INVOICE_FAILED,
    payload: { error },
  };
};

export const updateStatusRefundInvoice = (params) => {
  return {
    type: refundInvoiceConst.UPDATE_STATUS_REFUND_INVOICE,
    payload: { params },
  }
}

export const updateStatusRefundInvoiceSuccess = (data) => {
  return {
    type: refundInvoiceConst.UPDATE_STATUS_REFUND_INVOICE_SUCCESS,
    payload: { data },
  }
}

export const updateStatusRefundInvoiceFailed = (error) => {
  return {
    type: refundInvoiceConst.UPDATE_STATUS_REFUND_INVOICE_FAILED,
    payload: { error },
  }
}
