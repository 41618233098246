import React from "react";
import { imageUrl } from "lib";

import parse from "html-react-parser";
import { Link } from "react-router-dom";
import moment from "moment/moment";
function MainNews({ newsFutures }) {
  const removeHtmlTags = (input) => {
    return input.replace(/<[^>]*>/g, "").slice(0, 250);
  };
  let imageMainImage =
    (newsFutures && newsFutures.image === null) || newsFutures.image === ""
      ? "/images/product-image-default.png"
      : imageUrl(newsFutures.image);

  return (
    <>
      {newsFutures && (
        <div className="main-news hover" style={{ marginTop: "6px" }}>
          <Link
            to={`/tin-tuc/${
              newsFutures?.slug ? newsFutures?.slug : newsFutures?.id
            }`}
          >
            <img
              src={imageMainImage}
              title="news photo"
              className="img-cover"
            />
          </Link>
          <div className="inf-box py-3 px-1">
            <h5 className="fw-semibold line-clamp-01">
              <Link
                style={{ lineHeight: "1.5" }}
                to={`/tin-tuc/${
                  newsFutures?.slug ? newsFutures?.slug : newsFutures?.id
                }`}
              >
                {newsFutures && parse(newsFutures?.title)}
              </Link>
            </h5>
            <div className="mb-0">
              <div className="d-inline-block">
                <span className="icon-time align-middle"></span>
                <span>
                  {moment(newsFutures?.created_at).format("DD-MM-YYYY")}
                </span>
              </div>
              <div className="d-inline-block ml-3">
                <span className="icon-eye-line01 align-middle"></span>
                <span>{newsFutures?.viewed}</span>
              </div>
              <div className="d-inline-block ml-3">
                <span className="icon-comment-line align-middle"></span>
                <span>{newsFutures?.total_comment}</span>
              </div>
            </div>
            <div className="line-clamp-03">
              <p>{parse(removeHtmlTags(newsFutures.content))}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MainNews;
