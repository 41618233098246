import qs from "query-string";
import axiosService from "services/axiosService";
import { DOMAIN } from "constants";

const url_refund_invoice = "api/v2/refund_invoices";

export const listRefundInvoiceSeller = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_refund_invoice}${query}`, true); //Danh sách đơn hàng dành cho người bán
};

export const listRefundInvoiceBuyer = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_refund_invoice}${query}`, true); //Danh sách đơn hàng dành cho người bán
};

export const invoiceRefundDetail = (params) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  let invoiceId = params['invoiceId'];
  return axiosService.get(`${DOMAIN}/${url_refund_invoice}/${invoiceId}${query}`, true);
}

export const buyerRequestComplaintStatus = (params) => {
  const invoice_user_id = params.invoiceUserId;
  const complaint_status = {
    complaint_status: params.complaint_status,
  };
  return axiosService.put(`${DOMAIN}/${url_refund_invoice}/${invoice_user_id}/request_complaint`, complaint_status);
}; // Người mua gửi/huỷ khiếu nại

export const createRefundInvoice = (params) => {
  return axiosService.post(`${DOMAIN}/${url_refund_invoice}`, params);
}; //Người mua trả hàng

export const updateStatusRefundInvoice = (params) => {
  let invoiceId;
  if (params instanceof FormData) {
    invoiceId = Object.fromEntries(params.entries()).invoiceId;
  } else {
    invoiceId = params.invoiceId;
  }
  return axiosService.put(`${DOMAIN}/${url_refund_invoice}/${invoiceId}`, params);
}; // người bán chấp nhận và từ chối yêu cầu trả hàng, người mua huỷ trả hàng
