import React from "react";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";

export default function RelativeArticles(props) {
  const { isVideoPage, data } = props;
  return (
    <div className="news-sectn">
      <h5 className="title-line-lt text-uppercase fw-bold mb-3">
        <span className="d-block">{isVideoPage ? "Video liên quan" : "Tin tức liên quan"}</span>
      </h5>
      <div className="row sub-news">
        {data.map(function (re, index) {
          return (
            <div className="col-md-6 col-sm-6 col-xs-12" key={index}>
              <Link
                to={`${isVideoPage ? "/short_videos/" : "/tin-tuc/"}${re?.slug}`}
                className="link-articles row align-items-center mb-3">
                <div className="col-md-4 col-sm-4 col-xs-4">
                  <img src={re.image ?? "images/photos/no_image.png"} title="news photo" className="img-cover" />
                </div>
                <div className="col-md-8 col-sm-8 col-xs-8">
                  <h6 className="font-weight-semibold line-clamp-02">{re.title}</h6>
                  <div className="d-inline-block">
                    <span className="icon-time align-middle"></span>
                    <span className="font-weight-normal">
                      <ReactTimeAgo date={Date.parse(re.created_at)} />
                    </span>
                  </div>
                  <div className="d-inline-block ml-1">
                    <small
                      className="icon-eye-line01 align-middle mr-1"
                      style={{ color: "#919191", fontSize: "13px" }}></small>
                    <small style={{ fontSize: "13px" }}>{re?.viewed * 7}</small>
                  </div>
                  <div className="d-inline-block ml-1">
                    <span className="icon-comment-line align-middle"></span>
                    <small className="font-weight-normal" style={{ fontSize: "13px" }}>
                      {" "}
                      {re.total_comment ?? "0"}
                    </small>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
