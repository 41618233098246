import * as authConst from 'constants/auth';
import { toastError, toastSuccess } from 'helpers/toastHelper';

let initialState = {
  user_data: {
    current_user: null,
    isLogin: false,
    authToken: null,
    isRemember: false,
    error: null,
    isShowDownLoadApp: true,
  },
  list_following: {
    data: [],
    error: null,
  },
  follow_user: {
    data: {},
    error: null,
  },
  show_menu: {
    show: true,
  },
  sidebarFollowing: {
    data: [],
    isLoading: false,
    error: null,
  },
  listFollowPost: {
    data: [],
    isLoading: false,
    isLoadMore: false,
    error: null,
  },
  current_url: {
    path: '/',
  },
  info_user: {
    data: null,
    isLoading: false,
    error: null,
  },
  pointOfUser: {
    point: null,
    isLoading: false,
  },
};
if (!localStorage.getItem('show_menu')) {
  localStorage.setItem('show_menu', JSON.stringify(true));
} else {
  const isShow = JSON.parse(localStorage.getItem('show_menu'));
  initialState.show_menu = { show: isShow };
}

if (!localStorage.getItem('current_url')) {
  localStorage.setItem('current_url', JSON.stringify('/'));
} else {
  const current_url = JSON.parse(localStorage.getItem('current_url'));
  initialState.current_url = { path: current_url };
}

if (!localStorage.getItem('user_storage')) {
  localStorage.setItem(
    'user_storage',
    JSON.stringify({
      user_data: {
        current_user: null,
        isLogin: false,
        authToken: null,
        isRemember: false,
        error: null,
      },
    }),
  );
} else {
  const storage = JSON.parse(localStorage.getItem('user_storage')) || {};
  initialState = { ...initialState, ...storage };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authConst.LOGIN: {
      return { ...initialState };
    }
    case authConst.LOGIN: {
      return {
        ...state,
        user_data: {
          data: initialState.user_data.data,
          isLogin: false,
          authToken: null,
          isRemember: false,
          error: null,
        },
      };
    }
    case authConst.LOGIN_SUCCESS: {
      const { data } = action.payload;
      localStorage.setItem(
        'user_storage',
        JSON.stringify({
          user_data: {
            current_user: data.data,
            isLogin: true,
            authToken: data.data.token_user,
            isRemember: false,
            error: null,
          },
        }),
      );
      return {
        ...state,
        user_data: {
          current_user: data.data,
          isLogin: true,
          authToken: data.data.token_user,
          isRemember: false,
          error: null,
        },
      };
    }
    case authConst.LOGIN_FAILED: {
      const { error } = action.payload;
      localStorage.setItem(
        'user_storage',
        JSON.stringify({
          user_data: {
            current_user: null,
            isLogin: false,
            authToken: null,
            isRemember: false,
            error: null,
          },
        }),
      );
      return {
        ...state,
        user_data: {
          current_user: null,
          isLogin: false,
          authToken: null,
          isRemember: false,
          error: error,
        },
      };
    }

    case authConst.LOGOUT_SUCCESS: {
      localStorage.setItem(
        'user_storage',
        JSON.stringify({
          user_data: {
            current_user: null,
            isLogin: false,
            authToken: null,
            isRemember: false,
            error: null,
          },
        }),
      );
      return {
        ...state,
        user_data: {
          current_user: null,
          isLogin: false,
          authToken: null,
          isRemember: false,
          error: null,
        },
      };
    }

    case authConst.LOGOUT_FAILED: {
      toastError('Tài khoản đã login trên thiết bị khác');
      localStorage.setItem(
        'user_storage',
        JSON.stringify({
          user_data: {
            current_user: null,
            isLogin: false,
            authToken: null,
            isRemember: false,
            error: null,
          },
        }),
      );
      return {
        ...state,
        user_data: {
          current_user: null,
          isLogin: false,
          authToken: null,
          isRemember: false,
          error: null,
        },
      };
    }

    case authConst.UPDATE_USER_SUCCESS: {
      const { data } = action.payload;
      localStorage.setItem(
        'user_storage',
        JSON.stringify({
          user_data: {
            current_user: data.data,
            isLogin: true,
            authToken: data.data.token2_user,
            isRemember: false,
            error: null,
          },
        }),
      );
      return {
        ...state,
        user_data: {
          current_user: data.data,
          isLogin: true,
          authToken: data.data.token_user,
          isRemember: false,
          error: null,
        },
      };
    }
    case authConst.UPDATE_USER_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }

    case authConst.FOLLOW_USER_SUCCESS: {
      toastSuccess('Theo dõi người dùng thành công');
      const { data } = action.payload;
      return {
        ...state,
        follow_user: {
          data: data.data,
          error: null,
        },
      };
    }

    case authConst.FETCH_FOLLOWING: {
      return {
        ...state,
        list_following: {
          data: [],
          error: null,
        },
      };
    }
    case authConst.FETCH_FOLLOWING_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        list_following: {
          data: data.data,
          error: null,
        },
      };
    }

    case authConst.UNFOLOW: {
      return {
        ...state,
        unfollow: {
          data: {},
          error: null,
        },
      };
    }

    case authConst.UNFOLOW_SUCCESS: {
      const { data } = action.payload;
      toastSuccess('Bỏ theo dõi người dùng thành công');
      return {
        ...state,
        list_following: {
          data: state.list_following.data.filter(el => el.shopable_id != data.data.followable_id),
          error: null,
        },
      };
    }

    case authConst.UNFOLLOW_FAVORITES_POST_SUCCESS: {
      const { data } = action.payload;
      toastSuccess('Bỏ theo dõi thành công');
      return {
        ...state,
        listFollowPost: {
          data: state.listFollowPost.data.filter(post => post.id != data.data.followable_id),
          error: null,
        },
      };
    }
    case authConst.SHARE_POINT_SUCCESS: {
      toastSuccess('Chia sẻ điểm thành công ');
      return { ...state };
    }

    case authConst.SHARE_POINT_FAILED: {
      const { error } = action.payload;
      toastError(error.data);
      return { ...state };
    }

    case authConst.SHOW_DEEP_LINK: {
      localStorage.setItem('show_menu', JSON.stringify(false));
      return {
        ...state,
        show_menu: {
          show: false,
        },
      };
    }
    case authConst.FETCH_SIDEBAR_FOLLOWING: {
      return {
        ...state,
        sidebarFollowing: {
          data: initialState.sidebarFollowing.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case authConst.FETCH_SIDEBAR_FOLLOWING_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        sidebarFollowing: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case authConst.FETCH_FOLLOW_POST: {
      return {
        ...state,
        listFollowPost: {
          data: initialState.listFollowPost.data,
          isLoading: true,
          isLoadMore: true,
          error: null,
        },
      };
    }

    case authConst.FETCH_FOLLOW_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listFollowPost: {
          data: data.data,
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }
    case authConst.ADD_CURRENT_URL: {
      return {
        ...state,
        current_url: {
          path: initialState.current_url.path,
        },
      };
    }

    case authConst.ADD_CURRENT_URL_SUCCESS: {
      const { url } = action.payload;
      localStorage.setItem('current_url', JSON.stringify(url));
      return {
        ...state,
        current_url: {
          path: url,
        },
      };
    }
    case authConst.FETCH_INFO_USER: {
      return {
        ...state,
        info_user: {
          data: initialState.info_user.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case authConst.FETCH_INFO_USER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        info_user: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case authConst.UPDATE_POINT_USER: {
      const { data } = action.payload;
      localStorage.setItem(
        'user_storage',
        JSON.stringify({
          user_data: {
            current_user: data,
            isLogin: true,
            authToken: data.token2_user,
            isRemember: false,
            error: null,
          },
        }),
      );
      return {
        ...state,
      };
    }
    case authConst.LIKE_FOLLOWING_POST_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        listFollowPost: {
          data: state.listFollowPost.data.map(post => {
            if (post.id === data.likeable_id) {
              post.total_like = post.total_like + 1;
              post.user_liked = true;
            }
            return post;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case authConst.UNLIKE_FOLLOWING_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listFollowPost: {
          data: state.listFollowPost.data.map(post => {
            if (post.id === data.classable_id) {
              post.total_like = post.total_like - 1;
              post.user_liked = false;
            }
            return post;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case authConst.FETCH_POINT_OF_USER: {
      return {
        ...state,
        pointOfUser: {
          data: initialState.pointOfUser.data,
          isLoading: true,
        },
      };
    }
    case authConst.FETCH_POINT_OF_USER_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        pointOfUser: {
          point: data.points,
          isLoading: false,
        },
      };
    }

    case authConst.UPDATE_POINT_OF_USER_SUCCESS: {
      const { new_point } = action.payload;
      return {
        ...state,
        pointOfUser: {
          point: new_point,
          isLoading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
