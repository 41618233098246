import React, { useContext } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { likeMarketPrice, dislikeMarketPrice } from "actions/gia_ca_thi_truong";
import AppContext from "components/app_context";
import { toastErrorLogin } from "helpers/toastHelper";
import { checkPriceDiffer } from "../../../lib/index";
function MarketPriceItem({ data }) {
  const { current_user, isMobile } = useContext(AppContext);
  const dispatch = useDispatch();

  const handleLikeMarketPrice = (isLike, marketPriceId) => {
    if (!current_user) {
      toastErrorLogin(isMobile);
      return;
    }

    const action = isLike ? dislikeMarketPrice : likeMarketPrice;
    dispatch(action({
      id: current_user.id,
      classable_type: "MarketPlace",
      classable_id: marketPriceId,
    }));
  };

  return (
    <>
      <div className="row row-cols-2 d-flex">
        {data && data?.map(function (market, index) {
          const priceDifference = market.get_market_price_last?.price_difference | 0;
          return (
            <div key={index} className="col">
              <div className="lst-item box-link hover">
                <Link to={`/modules/thong-tin-gia-ca-thi-truong/${market.slug || market.id}`} className="">
                  <div className="d-flex align-items-center">
                    <div className="me-2 d-flex align-items-center">
                      <img
                        src={market.image ?? "/images/photos/no_image.png"}
                        alt="product image"
                        title="product image"
                      />
                    </div>

                    <div className="prod-inf">
                      <h6>
                        <span>{market.title}</span>
                      </h6>
                      <div className="d-flex align-items-center">
                        <div>
                          <small className="d-flex align-items-center">
                            <span className="icon-location01 me-1"></span>
                            <span className="">{market.province_name}</span>
                          </small>
                        </div>

                        <div className="ms-auto">
                          <div className="prod-price mb-0">
                            {market && market.get_market_price_last?.price > 0 ? (
                              <div className="red-clr fs-5 fw-bold">
                                <NumberFormat>{market && market.get_market_price_last.price}</NumberFormat>{" "}
                                <span className="fs-7">đ/{market.unit}</span>
                              </div>
                            ) : (
                              <span className="red-clr">&nbsp;Liên hệ</span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex mb-1">
                        <div>
                          <small className="date d-flex align-items-center">
                            <span className="icon-time align-middle me-1"></span>
                            <span>{market.last_price_updated_at ? moment(market.last_price_updated_at).format("DD/MM/YYYY h:mm A") : "Không xác định"}</span>
                          </small>
                        </div>

                        <div className="ms-auto">
                          <small className="d-block text-right redu-pri">
                            {market && priceDifference != 0 ? (
                              market && priceDifference < 0 ? (
                                <span className="font-italic">
                                  <img
                                    src="/images/down-arrow.png"
                                    style={{ width: "10px", height: "10px", marginBottom: "2px" }}
                                  />
                                  <NumberFormat>{checkPriceDiffer(priceDifference)}</NumberFormat>
                                  &nbsp;đ/
                                  {market.unit}
                                </span>
                              ) : (
                                <span className="d-block text-right incr-pri font-italic">
                                  <img
                                    src="/images/incr-arr.png"
                                    style={{ width: "10px", height: "10px", marginBottom: "2px" }}
                                  />
                                  +<NumberFormat>{checkPriceDiffer(priceDifference)}</NumberFormat>
                                  &nbsp;đ
                                </span>
                              )
                            ) : (
                              <span className="mr-2">
                                <img
                                  src="/images/2arr-yel.png"
                                  style={{ width: "20px", height: "20px", marginBottom: "2px" }}
                                />
                              </span>
                            )}
                          </small>
                        </div>
                      </div>

                      <div className="d-flex">
                        <small className="user d-flex align-items-center">
                          <span className="icon-user01 align-middle me-1"></span>
                          <span>{market.user_name ?? "Admin"}</span>
                        </small>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="text-right btn-save">
                  <a
                    role="button"
                    className={`icon-tag fs-3 ${market.user_liked ? "active" : ""}`}
                    title="Save"
                    onClick={() => handleLikeMarketPrice(market.user_liked, market.classable_id)}></a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MarketPriceItem;
