import React, { useEffect, useState } from 'react';
import { getMetaTags } from 'apis/post';

function MetaPost(props) {
  let { links, shared_link_status, postId } = props;
  let [data, setData] = useState([]);
  useEffect(() => {
    if (!!postId) {
      links.map(function (link) {
        getMetaTags(link.href, postId).then(res => {
          let result = res?.data?.data;
          if (result) {
            setData(data => [...data, result]);
          } else {
            setData(null);
          }
        });
      });
    }
  }, []);

  return (
    <div className="sharing-post p-2 ">
      <div className="cont-desc">
        {!!data && shared_link_status !== 404 ? (
          data.map(function (meta, index) {
            let meta_image = meta?.meta_tags?.property['og:image'];
            if (meta?.meta_tags?.property['og:image'] < 2) {
              meta_image = meta?.meta_tags?.property['og:image'][0];
            }
            return (
              <div key={index} className="align-items-center">
                <a href={`${meta.url}`}>
                  <div className="">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage: `url(${meta_image})`,
                      }}
                    />
                  </div>
                  <div className="mt-3">
                    <div className="fw-bold" style={{ fontSize: '18px' }}>
                      {meta.best_title}
                    </div>
                    <div className="fst-italic">
                      <div>{meta.url}</div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })
        ) : (
          <div className="sharing-post p-2">
            <div className="cont-desc">
              <div className="row align-items-center">
                <div>
                  <div className="">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage: 'url("/images/logo_2nong.png")',
                      }}
                    />
                  </div>
                  <div className="mt-3">
                    <div className="fw-bold" style={{ fontSize: '18px' }}>
                      Nội dung này hiện không hiển thị
                    </div>
                    <div className="fst-italic">
                      <div>Lỗi này thường do nội dung đã bị xóa bởi chủ sở hữu nội dung</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MetaPost;
