import React from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import Tabs from "./components/tabs";

function BusinessInvoice() {

  return (
    <>
      <div>
        <AddMetaTags title={"Danh sách đơn hàng "} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="cont-center saved-items">
              <Tabs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessInvoice;
