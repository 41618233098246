import * as invoiceConst from "constants/invoice";

export const createInvoice = (params) => {
  return {
    type: invoiceConst.CREATE_INVOICE,
    payload: { params },
  };
};

export const createInvoiceSuccess = (data) => {
  return {
    type: invoiceConst.CREATE_INVOICE_SUCCESS,
    payload: { data },
  };
};

export const createInvoiceFailed = (error) => {
  return {
    type: invoiceConst.CREATE_INVOICE_FAILED,
    payload: { error },
  };
};

export const fetchBuyingInvoices = (params) => {
  return {
    type: invoiceConst.FETCH_BUYING_INVOICES,
    payload: { params },
  };
};

export const fetchBuyingInvoicesSuccess = (data) => {
  return {
    type: invoiceConst.FETCH_BUYING_INVOICES_SUCCESS,
    payload: { data },
  };
};

export const fetchBuyingInvoicesFailed = (error) => {
  return {
    type: invoiceConst.FETCH_SELLING_INVOICES_FAILED,
    payload: { error },
  };
};

export const fetchDetailInvoice = (params) => {
  return {
    type:  invoiceConst.FETCH_DETAIL_INVOICE,
    payload: {
      params
    }
  }
} 

export const fetchDetailInvoiceSuccess = (data) => {
  return {
    type:  invoiceConst.FETCH_DETAIL_INVOICE_SUCCESS,
    payload: {
      data
    }
  }
} 

export const fetchSellingInvoices = (params) => {
  return {
    type: invoiceConst.FETCH_SELLING_INVOICES,
    payload: { params },
  };
};

export const fetchBSellingInvoicesSuccess = (data) => {
  return {
    type: invoiceConst.FETCH_SELLING_INVOICES_SUCCESS,
    payload: { data },
  };
};

export const fetchSellingInvoicesFailed = (error) => {
  return {
    type: invoiceConst.FETCH_SELLING_INVOICES_FAILED,
    payload: { error },
  };
};

export const updateStatusInvoice = (params) => {
  return {
    type: invoiceConst.UPDATE_STATUS_INVOICE,
    payload: { params },
  }
}

export const updateStatusInvoiceOld = (params) => {
  return {
    type: invoiceConst.UPDATE_STATUS_INVOICE_OLD,
    payload: { params },
  }
}

export const updateStatusInvoiceSuccess = (data) => {
  return {
    type: invoiceConst.UPDATE_STATUS_INVOICE_SUCCESS,
    payload: { data },
  }
}

export const updateStatusInvoiceFailed = (error) => {
  return {
    type: invoiceConst.UPDATE_STATUS_INVOICE_FAILED,
    payload: { error },
  }
}
