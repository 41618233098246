import qs from "query-string";
import axiosService from "services/axiosService";
import { DOMAIN, PAGE, PER_PAGE } from "constants";

const url = "api/v2/catalogues";
export const getCataloguesArticles = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/article_catalogues${query}`);
};

export const getCataloguesProducts = (params) => {
  let query = "";

  if (typeof params === "object" && Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
    return axiosService.get(`${DOMAIN}/${url}/product_catalogues${query}`);
  } else if (typeof params === "string") {
    return axiosService.get(`${DOMAIN}/${url}/product_catalogues/${params}`);
  } else return axiosService.get(`${DOMAIN}/${url}/product_catalogues`);
};

export const getCataloguesVideos = () => {
  return axiosService.get(`${DOMAIN}/${url}/video_catalogues`);
};

export const getCataloguesShortVideos = () => {
  return axiosService.get(`${DOMAIN}/${url}/short_video_catalogues`);
}

export const getCataloguesProductsDetail = (id) => {
  return axiosService.get(`${DOMAIN}/${url}/child_product_catalogues/${id}`);
};
