
const RefundStatus = () => {

  const refundStatus = {
    requested: {
      className: 'text-warning',
      bgColor: 'badge-warning',
      text: 'Yêu cầu trả hàng đã được gửi',
    },
    approved: {
      className: 'text-warning',
      bgColor: 'badge-warning',
      text: 'Yêu cầu trả hàng đã được chấp nhận'
    },
    rejected: {
      className: 'text-danger',
      bgColor: 'badge-danger',
      text: 'Yêu cầu trả hàng bị từ chối bởi người bán'
    },
    cancelled: {
      className: 'text-danger',
      bgColor: 'badge-danger',
      text: 'Yêu cầu trả hàng đã được hủy bởi người mua',
    },
    shipped: {
      className: 'text-warning',
      bgColor: 'badge-warning',
      text: 'Hàng đã được lấy/đang vận chuyển'
    },
    received: {
      className: 'text-sucess',
      bgColor: 'badge-sucess',
      text: 'Hàng trả về đã nhận được'
    },
    refunded: {
      className: 'text-sucess',
      bgColor: 'badge-sucess',
      text: 'Hoàn tiền đã được xử lý',
    },
    completed: {
      className: 'text-sucess',
      bgColor: 'badge-sucess',
      text: 'Quá trình trả hàng hoàn tất',
    }
  }

  return {
    refundStatus,
  }
}

export default RefundStatus;
