import moment from "moment";

export function filterAndFormatData(data) {
  // Sắp xếp dữ liệu theo thời gian tạo
  const sortedData = data.sort(
    (a, b) => moment(a.created_at) - moment(b.created_at),
  );

  // Lọc ra bản ghi cuối cùng của mỗi ngày
  const filteredData = sortedData.reduce((acc, cur) => {
    const dateKey = moment(cur.created_at).format("YYYY-MM-DD"); // Lấy phần ngày từ timestamp sử dụng moment
    acc[dateKey] = cur; // Ghi đè bản ghi cuối cùng của mỗi ngày vào accumulator
    return acc;
  }, {});

  // Định dạng lại dữ liệu
  const result = Object.values(filteredData).map((item) => ({
    ...item,
  }));

  return result;
}


export function findMinMaxDates(data) {
  // Chuyển đổi chuỗi ngày thành các đối tượng Date
  const dates = data.map((item) => new Date(item.created_at));

  // Tìm min và max dates
  const minDate = new Date(Math.min.apply(null, dates));
  const maxDate = new Date(Math.max.apply(null, dates));

  // Định dạng ngày theo "dd/MM/yyyy"
  function formatDate(date) {
    let day = ("0" + date.getDate()).slice(-2);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  return {
    minDate: formatDate(minDate),
    maxDate: formatDate(maxDate),
  };
}


export function calculateXAxisCategoriesForData(data, numCategories = 6) {
  if (!data || data.length === 0) return [];

  // Sắp xếp dữ liệu dựa trên ngày tạo
  const sortedData = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  // Lấy ngày bắt đầu và kết thúc
  const startDate = sortedData[0]?.created_at;
  const endDate = sortedData[sortedData.length - 1]?.created_at;

  // Sử dụng moment.js để tính toán
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  const diff = endMoment.diff(startMoment, "days");

  // Kiểm tra xem có đủ dữ liệu để tạo 6 phân loại hay không
  if (sortedData.length < numCategories) {
    numCategories = sortedData.length;
  }

  const interval = diff / (numCategories - 1);
  let categories = [];

  for (let i = 0; i < numCategories; i++) {
    const categoryDate = i === numCategories - 1
      ? endMoment.clone()
      : startMoment.clone().add(Math.round(interval * i), "days");
    categories.push(categoryDate.format('DD/MM/YYYY'));
  }

  return categories;
}

export function generateDateList(fromDay, currentDay) {
  let dates = [];
  let startDate = moment(fromDay, 'DD/MM/YYYY');
  let endDate = moment(currentDay, 'DD/MM/YYYY');

  // Tính toán số ngày giữa fromDay và currentDay
  let diffDays = endDate.diff(startDate, 'days');

  // Tính toán khoảng cách giữa mỗi ngày
  let interval = Math.floor(diffDays / 5);

  // Thêm fromDay vào mảng
  dates.push(startDate.format('DD/MM/YYYY'));

  // Tính toán và thêm các ngày còn lại vào mảng
  for (let i = 1; i < 5; i++) {
    let date = startDate.clone().add(interval * i, 'days');
    dates.push(date.format('DD/MM/YYYY'));
  }

  // Thêm currentDay vào mảng
  dates.push(endDate.format('DD/MM/YYYY'));

  return dates;
}
