import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastError } from 'helpers/toastHelper';

const ServiceModal = ({
    mainService,
    listOptions,
    titleModal, 
    openModal,
    setOpenModal,
    weight,
    setTags,
    typeModal}) => {

    const options = [
      { value: 1, title: 'Hàng dễ vỡ', price: 'Phí 1,000đ' },
      { value: 2, title: 'Hàng giá trị cao ≥ 1,000,000đ', price: 'Phí bảo hiểm bằng 0.5% giá trị hàng hoá' },
      { value: 7, title: 'Nông sản/ Thực phẩm khô', price: 'Phí 2,000đ' },
      // { value: 10, title: 'Xem hàng', price: 'Miễn phí' },
      { value: 11, title: 'Thử hàng/ đồng kiểm', price: 'Phí 2,000đ/Đơn' },
      { value: 13, title: 'Gọi shop khi không giao được', price: 'Miễn phí' },
      // { value: 19, title: 'Không giao được thu phí', price: 'Phí hoàn' },
      { value: 20, title: 'Hàng nguyên hộp', price: 'Phí 1,000đ/Đơn' },
      { value: 39, title: 'Thực phẩm tươi', price: 'Miễn phí' },
      // { value: 40, title: 'Hàng nhỏ', price: 'Miễn phí' },
      { value: 41, title: 'Hàng không xếp chồng', price: 'Miễn phí' },
      // { value: 42, title: 'Hàng yêu cầu xếp đúng chiều', price: 'Miễn phí' },
      // { value: 75, title: 'Hàng cây cối', price: 'Phí 1,000đ' }
    ]

    const closeModal = () => setOpenModal(false);
    const [optionChecked, setOptionChecked] = useState([]);

    const handleToggle = (e) => {
      const value = e.target.value;
      const checked = e.target.checked;
      if (checked) {
        setOptionChecked((prev) => [...prev, value]);
      } else {
        setOptionChecked((prev) => prev.filter((item) => item !== value));
      }
    }

    const handleSubmmitShowFee = () => {
      setTags(optionChecked)
      closeModal()
      if (weight <= 0) {
        toastError('Vui lòng nhập khối lượng lớn hơn 0');
        return; // Exit the function early if weight is 0
      }
    }

    useEffect(() => {
      typeModal === "vtp" && setTags([])
      setOptionChecked([])
    },[mainService])

  return (
      <Modal
        show={openModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>{titleModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {typeModal === 'ghtk' ? (
          <div>
            <div className="checkbox-grid">
              {options.map((isChecked, index) => (
                <div className="form-check form-switch form-shipping d-flex justify-content-between" key={isChecked.value}>
                  <div>
                    <span>{isChecked.title}</span>
                    <p className='custom-service-option font-14px'>{isChecked.price}</p>
                  </div>
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      value={isChecked.value}
                      id={`checkbox-${index}`}
                      checked={optionChecked.includes(isChecked.value.toString())}
                      onChange={(e) => handleToggle(e)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          ) : (
            <div>
              <div className="checkbox-grid">
                {listOptions.map((isChecked, index) => (
                  <div className="form-check form-switch form-shipping d-flex justify-content-between" key={index}>
                    <div>
                      <span>{isChecked.service_name}</span>
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        value={isChecked.service_code}
                        id={`checkbox-${index}`}
                        checked={optionChecked.includes(isChecked?.service_code?.toString())}
                        onChange={(e) => handleToggle(e)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => handleSubmmitShowFee()}>
            Xong
          </button>
        </Modal.Footer>
      </Modal>
  );
};

export default ServiceModal;