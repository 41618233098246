import {searchHomeSuccess,searchHomeFailed,updateStatusSearch, resetDataSearch} from 'actions/home';
import {delay, put, call} from "redux-saga/effects";
import {searchHome} from 'apis/home';

export function* userSearchHome({payload}) {
  try {
    const {keyword} = payload;
    yield delay(500);
    const resp = yield call(searchHome, {keyword: keyword});
    const {data} = resp;
    if(data.success){
      yield put(searchHomeSuccess(data));
    }else{
      yield put(searchHomeFailed(data));
    }
  } catch (error) {
    console.log(error)
  }
}

export function* userUpdateStatusSearchHome({payload}){
  try {
    const {status} = payload;
    yield put(updateStatusSearch(status))
  } catch (error) {
  }
}

export function* userResetData(){
  try {
    yield put(resetDataSearch());
  } catch (error) {
  }
}
