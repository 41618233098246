import React from "react";

const SearchBoxForSellingPage = (props) => {
  const { setKeyword, keyword } = props;
  const handleInputChange = (e) => {
    const newKeyword = e.target.value;
    setKeyword(newKeyword);
    props.filter(props.statusBox, newKeyword);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div className="mkt-price">
        <div className="search-box">
          <div className="row">
            <div className="col-md-12 col-sm-10 col-xs-12 d-flex invoice-search-box justify-content-center">
              <div className="col-md-4">
                <button
                  type="button"
                  className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "pending" && "bg-success text-white"}`}
                  onClick={() => props.filter("pending", props.keyword)}
                >
                  Chờ xác nhận
                </button>
              </div>
              <div className="col-md-4">
                <button
                  type="button"
                  className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "ready_to_ship" && "bg-success text-white"}`}
                  onClick={() => props.filter("ready_to_ship", props.keyword)}
                >
                  Chờ lấy hàng
                </button>
              </div>
              <div className="col-md-4 text-center ">
                <button
                  type="button"
                  className={`w-100 btn status-box fw-semibold ${props.statusBox === "shipped" && "bg-success text-white"}`}
                  onClick={() => props.filter("shipped", props.keyword)}
                >
                  Đang giao hàng
                </button>
              </div>
            </div>
            <div className="col-md-12 col-sm-10 col-xs-12 d-flex invoice-search-box justify-content-center mt-2">
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={`w-100 btn status-box fw-semibold ${props.statusBox === "delivered" && "bg-success text-white "}`}
                  onClick={() => props.filter("delivered", props.keyword)}
                >
                  Đã giao
                </button>
              </div>
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={`w-100 btn status-box fw-semibold ${props.statusBox === "cancelled" && "bg-success text-white"}`}
                  onClick={() => props.filter("cancelled", props.keyword)}
                >
                  Đã hủy
                </button>
              </div>
             {/* <div className="col-md-3 text-center">
                <button
                  type="button"
                  className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "failed_delivery" && "bg-success text-white"}`}
                  onClick={() => props.filter("failed_delivery", props.keyword)}
                >
                  Giao thất bại
                </button>
  </div>*/}
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "returned" && "bg-success text-white"}`}
                  onClick={() => props.filter("returned", props.keyword)}
                >
                  Trả hàng
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkt-price mt-3">
        <div className="search-box">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12"></div>
              <div className="search-top">
                <input
                  className="form-control me-2 search-input"
                  type="text"
                  placeholder="Nhập mã đơn hàng"
                  aria-label="Search"
                  value={keyword}
                  onChange={handleInputChange}
                />
                <span className="icon-search" />
              </div>
            </div>
          </div>
        </div>
    </form>
  );
};

export default SearchBoxForSellingPage;
