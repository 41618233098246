import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalShareConfirm(props) {
  const { onShare, isOpen, onToggle, option = 'Chia sẻ' } = props;
  const handleClose = () => {
    onToggle();
  };
  const handleShare = () => {
    onShare();
    handleClose();
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} className="modal-confirm" centered>
        <Modal.Header className="flex-column">
          <div className="icon-box">
            <i className="material-icons">&#xeb8b;</i>
          </div>
          <Modal.Title>Xác nhận</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="my-modal-footer">
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="success" onClick={handleShare}>
            {option}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalShareConfirm;
