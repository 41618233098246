import * as fertilizerConst from "constants/fertilizer";

export const fetchFertilizer = params => {
  return {
    type: fertilizerConst.FETCH_FERTILIZER,
    payload: { params }
  }
}

export const fetchFertilizerSuccess = data => {
  return {
    type: fertilizerConst.FETCH_FERTILIZER_SUCCESS,
    payload: { data }
  }
}
