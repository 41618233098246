import React, { forwardRef, useRef, useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import useAutosizeTextArea from "hooks/useAutosizeTextArea";
const EditCommentInput = forwardRef(({ onSubmit, content}, ref, ) => {
  const formRef = useRef(null);
  const [formValue, setFormValue] = useState({
    comment: content,
  });
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      const { value } = ref.current;
      ref.current.setSelectionRange(value.length, value.length);
    }
  },[ref])

  useAutosizeTextArea(ref.current, formValue);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ [name]: value });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      formRef.current.requestSubmit();
    }
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(formValue);
    setFormValue({ comment: "" });
  };

  return (
    <div className="p-2 d-flex cmnt-box">
      <div className="flex-grow-1">
        <Form
          ref={formRef}
          onSubmit={handleUserSubmit}
          className="m-0 position-relative"
        >
          <InputGroup>
            <Form.Control
              as="textarea"
              ref={ref}
              placeholder="Nhập bình luận..."
              name="comment"
              style={{ resize: "none" }}
              value={formValue.comment}
              onChange={handleOnChange}
              rows={1}
              onKeyDown={onKeyDown}
            />
            <Button type="submit">Cập nhật</Button>
          </InputGroup>
        </Form>
      </div>
    </div>
  );
});

export default EditCommentInput;
