export const FETCH_WEATHER = "FETCH_WEATHER";
export const FETCH_WEATHER_SUCCESS = "FETCH_WEATHER_SUCCESS";
export const FETCH_WEATHER_FAILED = "FETCH_WEATHER_FAILED";

export const FETCH_FORECAST_WEATHER = "FETCH_FORECAST_WEATHER";
export const FETCH_FORECAST_WEATHER_SUCCESS = "FETCH_FORECAST_WEATHER_SUCCESS";
export const FETCH_FORECAST_WEATHER_FAILED = "FETCH_FORECAST_WEATHER_FAILED";

export const FETCH_WEATHERS_ID = "FETCH_WEATHERS_ID";
export const FETCH_WEATHERS_ID_SUCCESS = "FETCH_WEATHERS_ID_SUCCESS";
export const FETCH_WEATHERS_ID_FAILED = "FETCH_WEATHERS_ID_FAILED";

export const FETCH_WEATHER_PLACES = "FETCH_WEATHER_PLACES";
export const FETCH_WEATHER_PLACES_SUCCESS = "FETCH_WEATHER_PLACES_SUCCESS";
export const FETCH_WEATHER_PLACES_FAILED = "FETCH_WEATHER_PLACES_FAILED";

export const FETCH_WEATHER_ADDRESS = "FETCH_WEATHER_ADDRESS";
export const FETCH_WEATHER_ADDRESS_SUCCESS = "FETCH_WEATHER_ADDRESS_SUCCESS";
export const FETCH_WEATHER_ADDRESS_FAILED = "FETCH_WEATHER_ADDRESS_FAILED";

export const FETCH_WEATHER_AUDIO = "FETCH_WEATHER_AUDIO";
export const FETCH_WEATHER_AUDIO_SUCCESS = "FETCH_WEATHER_AUDIO_SUCCESS";
export const FETCH_WEATHER_AUDIO_FAILED = "FETCH_WEATHER_AUDIO_FAILED";

export const FETCH_WEATHER_SCHEDULE = "FETCH_WEATHER_SCHEDULE";
export const FETCH_WEATHER_SCHEDULE_SUCCESS = "FETCH_WEATHER_SCHEDULE_SUCCESS";
export const FETCH_WEATHER_SCHEDULE_FAILED = "FETCH_WEATHER_SCHEDULE_FAILED";

export const DELETE_WEATHER_SCHEDULE = "DELETE_WEATHER_SCHEDULE";
export const DELETE_WEATHER_SCHEDULE_SUCCESS = "DELETE_WEATHER_SCHEDULE_SUCCESS";
export const DELETE_WEATHER_SCHEDULE_FAILED = "DELETE_WEATHER_SCHEDULE_FAILED";

export const EDIT_WEATHER_SCHEDULE = "EDIT_WEATHER_SCHEDULE";
export const EDIT_WEATHER_SCHEDULE_SUCCESS = "EDIT_WEATHER_SCHEDULE_SUCCESS";
export const EDIT_WEATHER_SCHEDULE_FAILED = "EDIT_WEATHER_SCHEDULE_FAILED";

export const FETCH_WEATHER_SCHEDULE_BY_ID = "FETCH_WEATHER_SCHEDULE_BY_ID";
export const FETCH_WEATHER_SCHEDULE_BY_ID_SUCCESS = "FETCH_WEATHER_SCHEDULE_BY_ID_SUCCESS";
