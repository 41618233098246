import React, { useRef, useContext, useState, useEffect } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import AppContext from "components/app_context";
import ModalCode from "../forms/modalCode";
import { useDispatch, useSelector } from "react-redux";
import { scanTraceability } from "redux/actions/traceability";
import { getBrowserInfo } from "lib";
import { toastError } from "helpers/toastHelper";
import { useNavigate } from "react-router-dom";

function CodeTraceabilites(props) {
  let traceabilityItem = useSelector((state) => state.traceability.traceabilityItem);
  const dispatch = useDispatch();
  const { current_user } = useContext(AppContext);
  const isLoading = traceabilityItem.isLoading;
  let { data } = traceabilityItem;
  let inputCodeTraceability = useRef(null);
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  const scanCode = (e) => {
    const inputValue = inputCodeTraceability.current?.value ?? "";
    if (!inputValue) {
      toastError("Vui lòng nhập mã code");
    } else if (!/^\d+$/.test(inputValue)) {
      toastError("Mã định danh không hợp lệ");
    } else {
      dispatch(
        scanTraceability({
          code: inputValue,
          scan_type: "enter_code",
          device_id: `Web2nong: ${current_user?.current_device_id ?? getBrowserInfo()}`,
        })
      );

      setIsOpen(true);
      inputCodeTraceability.current.value = null;
    }
  };

  return (
    <>
      <AddMetaTags title={"Truy xuất nguồn gốc"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container traceability-home">
              <div className="row">
                <h5 className="d-flex align-items-center">
                  <button
                    className="border-0 bg-white blue-clr"
                    onClick={handleClick}
                    type="button"
                    style={{ fontSize: "30px" }}
                  >
                    &lsaquo;
                  </button>
                  <span className="blue-clr mt-1">Nhập mã định danh</span>
                </h5>
              </div>
              <div className="code-box">
                <div className="row mt-2">
                  <div className="fst-italic ">Nhập mã định danh in trên sản phẩm</div>
                </div>
                <div className="row mt-4">
                  <input
                    type="text"
                    placeholder="Nhập mã định danh..."
                    className="traceability-code-input"
                    ref={inputCodeTraceability}
                  />
                </div>

                <div className="row mt-2">
                  <button
                    type="button"
                    className="save-btn btn-lg btn-block btn-box"
                    onClick={(e) => scanCode(e)}
                  >
                    Truy xuất
                  </button>
                </div>
                {"id" in data && (
                  <ModalCode
                    data={[
                      data.qr_code,
                      data.name,
                      data.code,
                      data.ingredient.ingredient_address,
                      data.manufactor.manufacture_name,
                    ]}
                    id={data.id}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CodeTraceabilites;
