import React from "react";

export default function CommentHeader(props) {
  const { product_id, activeSort, sortComments } = props;
  return (
    <div className="actions-sort-cmt d-flex mt-3">
      <ul>
        <li
          className={`${activeSort === "total_likes" && "active-sort"} action-sort-like hover`}
          onClick={() => sortComments(product_id, "total_likes")}
        >
          Yêu thích nhất
        </li>
        <li
          className={`${activeSort === "created_at" && "active-sort"} action-sort-new hover`}
          onClick={() => sortComments(product_id, "created_at")}
        >
          Đánh giá mới nhất
        </li>
      </ul>
    </div>
  );
}
