import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { reportImagePost } from "redux/actions/post";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { useLocation } from "react-router-dom";
function ModalReportImage({ post, open, setOpen }) {
  let [reason, setReason] = useState("");
  const location = useLocation();
  const postID = location.pathname.split("/")[2];
  const ImageID = location.pathname.split("/")[4]
  const closeModalReport = () => setOpen(false);
  const dispatch = useDispatch();

  const submitReport = () => {
    if (reason.length <= 0) {
      return toastError("Vui lòng nhập lý do");
    }
    dispatch(reportImagePost({ id: postID, img_id: ImageID, reason: reason }));
    setReason("");
    closeModalReport();
    toastSuccess("Báo cáo thành công");
  };
  return (
    <>
      <Modal show={open} onHide={closeModalReport} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Báo cáo hình ảnh/video vi phạm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            name="message"
            placeholder="Nhập lý do"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => submitReport()}>
            gửi
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ModalReportImage;
