import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, ScrollToTop } from "react-router-dom";
import * as productActions from "actions/product";
import PropTypes from "prop-types";
import SidebarProductsPlaceholder from "components/placeholder/sidebar_products";
import FadeIn from "react-fade-in";
import NumberFormat from "components/number_format";
import { imageUrl } from "lib/index";
function SidebarProducts(props) {
  let { productActionsCreator } = props;
  let { fetchSidebarProduct } = productActionsCreator;

  useEffect(() => {
    fetchSidebarProduct({ page: 1, limit: 10 });
  }, [fetchSidebarProduct]);

  let { data, isLoading } = props.sidebarProduct;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="box-bd newnews position-relative">
      <div className="title  mt-2 text-center align-items-center">
        <span className="txt  primary-clr">LỰA CHỌN HÔM NAY</span>
      </div>
      <div className="container px-0">
        <div className="row row-cols-2">
          {data.map(function (product, index) {
            let product_image = imageUrl(product.images[0]?.name);
            return (
              <div key={index} className="col">
                <div className="news-item sub-news-item hover" style={{ padding: "0" }}>
                  <Link to={`/san-pham/${product?.slug}`}>
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage: `url(${
                          product.images[0] !== undefined ? `${product_image}` : "/images/product-image-default.png"
                        })`,
                      }}
                    ></div>
                    <div className="news-inf px-2">
                      <div className="title-item">
                        <div className="fw-semibold line-clamp-01 text-capitalize">{product.title}</div>
                        {product?.shop?.province_name !== null ? (
                          <div className=" ms-auto">
                            <small className="icon-location01"></small>
                            <small className="">&nbsp;{product?.shop?.province_name}</small>
                          </div>
                        ) : (
                          <div className=" ms-auto">
                            <small className="icon-location01" />
                            <small className=""> Không xác định</small>
                          </div>
                        )}
                      </div>
                      <div className="price-item mb-1">
                        <div className="price01">
                          <span className="d-block">Giá lẻ:</span>
                          {product.retail_price > 0 ? (
                            <div>
                              <span className="fw-bold red-clr">
                                <NumberFormat>{product.retail_price}</NumberFormat>
                              </span>{" "}
                              đ/<small>{product.unit_name}</small>
                            </div>
                          ) : (
                            <span className="red-clr fw-semibold">Liên hệ</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
          {isLoading && (
            <FadeIn>
              <SidebarProductsPlaceholder />
            </FadeIn>
          )}
        </div>
        <div onClick={scrollToTop}>
          <Link to="/cho-2nong" className="d-flex justify-content-center">
            <button type="button" className="more-btn-fll">
              <u className="hover fw-500">Xem thêm</u>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

SidebarProducts.propTypes = {
  productActionsCreator: PropTypes.shape({
    fetchProduct: PropTypes.func,
    fetchProductSuccess: PropTypes.func,
    fetchProductFailed: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    listProducts: state.product.listProducts,
    sidebarProduct: state.product.sidebarProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActionsCreator: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProducts);
