import * as trackConst from 'constants/track_activity';

export const createTrackActivity = params => {
  return {
    type: trackConst.CREATE_TRACK_ACTIVITY,
    payload: {
      params,
    },
  };
};

export const createTrackActivitySuccess = data => {
  return {
    type: trackConst.CREATE_TRACK_ACTIVITY_SUCCESS,
    payload: {
      data,
    },
  };
};


