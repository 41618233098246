import React from "react";
import { Link } from "react-router-dom";
import { formatVND } from "../../marketPriceDetail/Utils/FormatPrice";

const Revenue = ({ reportData, startDate, endDate }) => {

  return (
    <div className="mt-3">
      <div className="text-base font-weight-bolder">Doanh thu chờ xử lý</div>
      <div className="table-result-venue ml-3"> 
        <div className="d-flex items-center justify-content-between mt-1">
          <div className="font-bold text-sm text-slate-700 md:text-base">Chờ xác nhận</div>
          <Link to={`/nguoi-dung/danh-sach-don-hang-doanh-thu?status=pending&startDate=${startDate}&endDate=${endDate}`}>
            <div className="text-blue-500">{formatVND(reportData?.pending)}</div>
          </Link>
        </div>
        <div className="d-flex items-center justify-content-between mt-1">
          <div className="font-bold text-sm text-slate-700 md:text-base">Chờ lấy hàng</div>
          <Link to={`/nguoi-dung/danh-sach-don-hang-doanh-thu?status=ready_to_ship&startDate=${startDate}&endDate=${endDate}`}>
            <div className="text-blue-500">{formatVND(reportData?.ready_to_ship)}</div>
          </Link>
        </div>

        <div className="d-flex items-center justify-content-between mt-1">
          <div className="font-bold text-sm text-slate-700 md:text-base">Đang giao</div>
          <Link to={`/nguoi-dung/danh-sach-don-hang-doanh-thu?status=shipped&startDate=${startDate}&endDate=${endDate}`}>
            <div className="text-blue-500">{formatVND(reportData?.shipped)}</div>
          </Link>
        </div>
      </div>
      <div className="mt-2">
        <div className="text-base font-weight-bolder">Doanh thu thực nhận</div>
        <div className="d-flex items-center justify-content-between mt-1 ml-3">
          <div className="font-bold text-sm text-slate-700 md:text-base">Giao thành công</div>
          <Link to={`/nguoi-dung/danh-sach-don-hang-doanh-thu?status=delivered&startDate=${startDate}&endDate=${endDate}`}>
            <div className="text-blue-500">{formatVND(reportData?.delivered)}</div>
          </Link>
        </div>
        {/* <div className="d-flex items-center justify-content-between mt-1">
          <div className="font-bold text-sm text-slate-700 md:text-base">Trả hàng</div>
          <Link href={`/nguoi-dung/danh-sach-don-hang-doanh-thu?statusBooking=cancelled&userName=${user?.user?.name}&startDate=${startDate}&endDate=${endDate}`}>
            <div className="text-blue-500">{formatVND(reportData?.returned)}</div>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Revenue;
