import * as traceabilityConst from "constants/traceability";

const initialState = {
  listTraceabilities: {
    data: [],
    error: null,
    isLoading: false,
  },
  traceabilityItem: {
    data: {},
    error: null,
    isLoading: false,
    isShowModal: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case traceabilityConst.FETCH_TRACEABILITY: {
      return {
        ...state,
        listTraceabilities: {
          data: initialState.listTraceabilities.data,
          error: null,
          isLoading: true,
        },
      };
    }

    case traceabilityConst.FETCH_TRACEABILITY_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listTraceabilities: {
          data: data.data,
          error: null,
          isLoading: false,
        },
      };
    }
    case traceabilityConst.FETCH_TRACEABILITY_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTraceabilities: {
          data: initialState.listTraceabilities.data,
          error: error,
          isLoading: false,
        },
      };
    }

    case traceabilityConst.DELETE_TRACEABILITY_SUCCESS: {
      const id = action.payload.data;
      return {
        ...state,
        listTraceabilities: {
          data: state.listTraceabilities.data.filter((item) => item.id !== id),

          error: null,
          isLoading: false,
        },
      };
    }
    case traceabilityConst.DELETE_TRACEABILITY_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTraceabilities: {
          data: initialState.listTraceabilities.data,
          error: error,
          isLoading: false,
        },
      };
    }

    case traceabilityConst.SCAN_TRACEABILITY: {
      return {
        ...state,
        traceabilityItem: {
          data: initialState.traceabilityItem.data,
          error: null,
          isLoading: true,
          isShowModal: false,
        },
      };
    }
    case traceabilityConst.SCAN_TRACEABILITY_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        traceabilityItem: {
          data: data.data,
          error: null,
          isLoading: false,
          isShowModal: true,
        },
      };
    }
    case traceabilityConst.SCAN_TRACEABILITY_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        traceabilityItem: {
          data: error,
          error: error,
          isLoading: false,
          isShowModal: false,
        },
      };
    }

    case traceabilityConst.NEW_TRACEABILITY: {
      return {
        ...state,
        traceabilityItem: {
          data: initialState.traceabilityItem.data,
          error: null,
          isLoading: true,
          isShowModal: false,
        },
      };
    }
    case traceabilityConst.NEW_TRACEABILITY_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        traceabilityItem: {
          data: data.data,
          error: null,
          isLoading: false,
          isShowModal: true,
        },
      };
    }
    case traceabilityConst.NEW_TRACEABILITY_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        traceabilityItem: {
          data: error,
          error: error,
          isLoading: false,
          isShowModal: false,
        },
      };
    }

    case traceabilityConst.FETCH_TRACEABILITY_DETAIL: {
      return {
        ...state,
        traceabilityItem: {
          data: initialState.traceabilityItem.data,
          error: null,
          isLoading: true,
          isShowModal: false,
        },
      };
    }
    case traceabilityConst.FETCH_TRACEABILITY_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        traceabilityItem: {
          data: data.data,
          error: null,
          isLoading: false,
          isShowModal: true,
        },
      };
    }
    case traceabilityConst.FETCH_TRACEABILITY_DETAIL_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        traceabilityItem: {
          data: error,
          error: error,
          isLoading: false,
          isShowModal: false,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
