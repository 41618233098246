import React, { useState, useRef } from 'react';
import Loader from 'components/loader';
import moment from 'moment/moment';
import { DOMAIN } from 'constants';
import * as Icon from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { toastError } from 'helpers/toastHelper';

function SearchBoxWeather({
  current_user,
  searchAddress,
  fixProvinceName,
  listWeathers,
  load,
  searchWeatherWithAddress,
  isLoading,
  setKeyword,
  isLoadingAudioWeather,
  audioWeather,
  fetchWeather,
  fetchWeatherAudio,
  keyword,
}) {
  const [isPlay, setIsPlay] = useState(false);
  const audioRef = useRef(null);
  const handleAudioEnded = () => {
    setIsPlay(false);
  };
  const fetchWeatherAfterReset = () => {
    if (!current_user) {
      fetchWeather(LAT_LONG_HCM);
    } else {
      fetchWeather({ lat: current_user.lat, lng: current_user.lng });
    }
  };
  const resetSearch = () => {
    setKeyword('');
    fetchWeatherAfterReset();
  };
  const handlePlay = () => {
    if (!audioWeather) {
      toastError('Chưa tải được thời tiết vui lòng tải lại');
      fetchWeatherAudio({ lat: listWeathers?.current_date?.lat, lng: listWeathers?.current_date?.lng });
    } else {
      if (!isPlay) {
        audioRef.current.play();
        setIsPlay(!isPlay);
        audioRef.current.addEventListener('ended', handleAudioEnded);
      } else {
        audioRef.current.pause();
        setIsPlay(!isPlay);
      }
    }
  };

  return (
    <>
      <div className="col-md-3 col-sm-12 search-box">
        <div className="lt-side p-0 position-relative">
          <div className="wth-main">
            <div className="position-relative wth-time">
              {load ? (
                <div className="collapse position-relative show" id="inputWeather">
                  <input type="text" placeholder="Nhập địa chỉ..." className="inp-addr" disabled />
                  <button type="button" className="btn position-absolute" disabled>
                    Tải...
                  </button>
                </div>
              ) : (
                <div className="collapse position-relative show" id="inputWeather">
                  <input
                    type="text"
                    placeholder="Nhập địa chỉ..."
                    className="inp-addr"
                    onChange={e => setKeyword(e.target.value)}
                    value={keyword}
                  />
                  {keyword && (
                    <button
                      type="button"
                      className="position-absolute"
                      style={{
                        right: '47px',
                        fontSize: '11px',
                        top: '8px',
                        border: 'none',
                        borderRadius: '50%',
                        background: '#e5e5e5',
                      }}
                      onClick={() => resetSearch()}
                    >
                      x
                    </button>
                  )}
                  <button type="button" className="btn position-absolute" onClick={() => searchWeatherWithAddress()}>
                    Tìm
                  </button>
                </div>
              )}
              <div className="addr-txt mt-2">
                <div className="time-today d-flex align-items-center">
                  <div>
                    <span className="fs-7">Hôm nay</span> <small>({moment().format('DD.MM - hh:mm A')}) </small>
                  </div>
                  <div className="d-flex ms-auto align-items-center">
                    <div className="btn-audio">
                      {audioWeather && (
                        <audio className="d-none weather-audio" ref={audioRef}>
                          <source src={audioWeather} type="audio/mp3"></source>
                        </audio>
                      )}
                      {!isLoadingAudioWeather ? (
                        !isPlay ? (
                          <Icon.VolumeOff size={40} onClick={handlePlay} className="icon-play" />
                        ) : (
                          <Icon.VolumeUp size={40} onClick={handlePlay} className="icon-sound-off" />
                        )
                      ) : (
                        <Loader isLoading={isLoading} />
                      )}
                    </div>
                    {current_user && (
                      <Link to={`/nguoi-dung/thiet-lap-thong-bao`}>
                        <button type="button" className="btn-input-localtn">
                          <img src="/images/wth-setting.png" alt="Weather setting" title="Weather setting" />
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
                <div>
                  {current_user !== null && current_user?.province_name !== null && !searchAddress ? (
                    <label className="mb-0 fw-semibold city-selct line-clamp-3">
                      {fixProvinceName(current_user?.province_name)}
                    </label>
                  ) : (current_user === null && !searchAddress) ||
                    (current_user && current_user?.province_name === null && !searchAddress) ? (
                    <label className="mb-0 fw-semibold city-selct line-clamp-3">Thành phố Hồ Chí Minh</label>
                  ) : searchAddress ? (
                    <label className="mb-0 fw-semibold city-selct line-clamp-3">
                      {listWeathers && listWeathers?.current_date?.location_full_name}
                    </label>
                  ) : (
                    <label className="mb-0 fw-semibold city-selct line-clamp-3">Thành phố Hồ Chí Minh</label>
                  )}
                </div>
              </div>
            </div>
            <Loader isLoading={isLoading} />
            <div className="inf-cnt p-3">
              {listWeathers.current_date && (
                <img
                  src={`${DOMAIN}/images/weather_icons/${listWeathers.current_date.weather_status_icon}.png`}
                  alt="weather icon"
                  className="weath-img"
                />
              )}
              {listWeathers && listWeathers.current_date && (
                <div className="mb-1">
                  <div className="temp mt-0">
                    <span className="temp-numb">{Math.round(listWeathers?.current_date?.temp)}</span>
                    <sup>
                      <span className="fw-normal fs-2">o</span>
                    </sup>
                    <span>C</span>
                  </div>

                  <div className="d-flex align-items-center mb-1">
                    <span className="icon-weather01"></span>
                    <span className="txt text-capitalize">{listWeathers?.current_date?.weather_status}</span>
                  </div>

                  <div className="d-flex align-items-center">
                    <span className="icon-rainpre"></span>
                    <span className="txt">Khả năng mưa:&nbsp; </span>
                    <span>{Math.round(listWeathers?.current_date?.feels_like)}%</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchBoxWeather;
