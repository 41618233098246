import * as ttsConstant from "constants/tts";

const initialState = {
  voice: {
    data: null,
    error: null,
    isLoading: false,
  },
};

const tts = (state = initialState, action) => {
  switch (action.type) {
    case ttsConstant.FETCH_VOICE: {
      return {
        ...state,
        voice: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }

    case ttsConstant.FETCH_VOICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        voice: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case ttsConstant.FETCH_VOICE_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        voice: {
          data: null,
          isLoading: false,
          error: error,
        },
      };
    }
    
    case ttsConstant.RESET_VOICE: {
      return {
        ...state,
        voice: {
          data: null,
          isLoading: false,
          error: null,
        },
      };
    }

    default:
      return state;
  }
};

export default tts;