export const FETCH_ALL_MARKET_PRICES = 'FETCH_ALL_MARKET_PRICES';
export const FETCH_ALL_MARKET_PRICES_SUCCESS = 'FETCH_ALL_MARKET_PRICES_SUCCESS';
export const FETCH_ALL_MARKET_PRICES_FAILED = 'FETCH_ALL_MARKET_PRICES_FAILED';

export const FETCH_MARKET_PRICES = 'FETCH_MARKET_PRICES';
export const FETCH_MARKET_PRICES_SUCCESS = 'FETCH_MARKET_PRICES_SUCCESS';
export const FETCH_MARKET_PRICES_FAILED = 'FETCH_MARKET_PRICES_FAILED';

export const FETCH_SIDEBAR_MARKET_PRICES = 'FETCH_SIDEBAR_MARKET_PRICES';
export const FETCH_SIDEBAR_MARKET_PRICES_SUCCESS = 'FETCH_SIDEBAR_MARKET_PRICES_SUCCESS';
export const FETCH_SIDEBAR_MARKET_PRICES_FAILED = 'FETCH_SIDEBAR_MARKET_PRICES_FAILED';

export const FETCH_LOAD_MORE_MARKET_PRICES = 'FETCH_LOAD_MORE_MARKET_PRICES';
export const FETCH_LOAD_MORE_MARKET_PRICES_SUCCESS = 'FETCH_LOAD_MORE_MARKET_PRICES_SUCCESS';
export const FETCH_LOAD_MORE_MARKET_PRICES_FAILED = 'FETCH_LOAD_MORE_MARKET_PRICES_FAILED';

export const LIKE_MARKET_PRICE = 'LIKE_MARKET_PRICE';
export const LIKE_MARKET_PRICE_SUCCESS = 'LIKE_MARKET_PRICE_SUCCESS';
export const LIKE_MARKET_PRICE_FAILED = 'LIKE_MARKET_PRICE_FAILED';

export const DISLIKE_MARKET_PRICE = 'DISLIKE_MARKET_PRICE';
export const DISLIKE_MARKET_PRICE_SUCCESS = 'DISLIKE_MARKET_PRICE_SUCCESS';
export const DISLIKE_MARKET_PRICE_FAILED = 'DISLIKE_MARKET_PRICE_FAILED';

export const FETCH_FOLLOWING_MARKET_PRICE = 'FETCH_FOLLOWING_MARKET_PRICE';
export const FETCH_FOLLOWING_MARKET_PRICE_SUCCESS = 'FETCH_FOLLOWING_MARKET_PRICE_SUCCESS';
export const FETCH_FOLLOWING_MARKET_PRICE_FAILED = 'FETCH_FOLLOWING_MARKET_PRICE_FAILED';
