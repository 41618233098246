import React, { useEffect, useRef, useState } from "react";
import ListFertilizer from "./listFertilizer";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as fertilizerActions from "actions/fertilizer";
import { Collapse } from "react-bootstrap";
import { calculatorReverse } from "apis/fertilizer";
import { fetchProvince, fetchDistrict } from "actions/location";

function TabCalculatorReverse(props) {
  let [weight, setWeight] = useState("");
  let [n_rate, setN_rate] = useState("");
  let [p_rate, setP_rate] = useState("");
  let [k_rate, setK_rate] = useState("");
  let [n, setN] = useState("");
  let [p, setP] = useState("");
  let [k, setK] = useState("");
  let [n_weight, setN_weight] = useState("");
  let [p_weight, setP_weight] = useState("");
  let [k_weight, setK_weight] = useState("");

  let [results, setResult] = useState(null);
  let [show, setShow] = useState(false);

  const { fertilizerActions } = props;
  const { fetchFertilizer } = fertilizerActions; // end action
  const [provinceId, setProvinceId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  let province = useSelector((state) => state.location.listProvinces);
  let district = useSelector((state) => state.location.listDistricts);

  //reducer
  let { fertilizer_list } = props;
  let { data } = fertilizer_list; //end reducer

  useEffect(() => {
    fetchFertilizer();
  }, [fetchFertilizer]);

  useEffect(() => {
    dispatch(fetchProvince());
    if (provinceId) {
      dispatch(fetchDistrict({ province_id: provinceId }));
    }
  }, [provinceId]);

  let getFertilizerIds = () => {
    if ($(".nitrogen:checked").length > 0) {
      var list = $(".nitrogen");
      var checked = $(".nitrogen:checked");
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
      setN($(".nitrogen:checked").val());
    } else {
      $(".nitrogen:disabled").removeAttr("disabled");
      setN(null);
    }

    if ($(".phosphor:checked").length > 0 || $(".phosphor_mix:checked").length > 0) {
      var list = [];
      var checked = [];

      if ($(".phosphor_mix:checked").length > 0) {
        list = $(".phosphor_mix, .phosphor");
        checked = $(".phosphor_mix:checked");
        setP($(".phosphor_mix:checked").val());
      }
      if ($(".phosphor:checked").length > 0) {
        list = $(".phosphor_mix, .phosphor");
        checked = $(".phosphor:checked");
        setP($(".phosphor:checked").val());
      }

      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
    } else {
      $(".phosphor:disabled").removeAttr("disabled");
      $(".phosphor_mix:disabled").removeAttr("disabled");
      setP(null);
    }

    if ($(".kali:checked").length > 0) {
      var list = $(".kali");
      var checked = $(".kali:checked");
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
      setK($(".kali:checked").val());
    } else {
      $(".kali:disabled").removeAttr("disabled");
      setK(null);
    }
  };
  const submitMixMPK = (e) => {
    setShow(true);
    if (!show) {
      calculatorReverse({
        weight_1: n_weight,
        weight_2: p_weight,
        weight_3: k_weight,
        weight: weight,
        n_rate: n_rate,
        p_rate: p_rate,
        k_rate: k_rate,
        npk_manures_1: n,
        npk_manures_2: p,
        npk_manures_3: k,
        province_id: provinceId,
        district_id: districtId,
        address: address,
      }).then((res) => {
        setResult(res.data.data);
      });
    } else {
      setShow(false);
    }
  };
  const detectFertilizer = (value) => {
    if (value == "nitrogen") {
      return "N";
    } else if (value == "phosphor") {
      return "P";
    } else if (value == "kali") {
      return "K";
    } else {
      return "P[P/N]";
    }
  };

  return (
    <>
      <h2> Xác định NPK thừa / thiếu</h2>
      <p>
        Xác định lượng phân bón NPK đã bón cho cây trồng thừa hay thiếu. Ví dụ lượng phân bón cho 1 hecta cây Khổ Qua
        theo khuyến cáo là 300 kg Urê, 400 kg Lân-Super và 240 kg Kali-Clorua, nhưng nhà vườn đã bón 100 kg NPK
        (16-16-8) như vậy lượng NPK thừa hay thiếu. NPK Calculator sẽ giúp bạn tính toán lượng phân phù hợp đáp ứng cho
        cây trồng phát triển tốt nhất.
      </p>
      <form className="npk-frm">
        <div className="row">
          <div className="col-md-6 col-sm-12 kg-unit">
            <label>Khối lượng phân NPK đã bón(KG)</label>
            <input className="form-control mb-2" type="text" onChange={(e) => setWeight(e.target.value)} />
            <span className="">KG</span>
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Tỷ lệ phân NPK đã bón</label>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="N"
                  onChange={(e) => setN_rate(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="P"
                  onChange={(e) => setP_rate(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
                  className="form-control mb-2"
                  type="text"
                  placeholder="K"
                  onChange={(e) => setK_rate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <label className="fw-bold">Khu vực bón phân</label>
            <div className="row">
              <div className="col-md-4">
                <label>Tỉnh/Thành phố</label>
                <select className="form-select" onChange={(e) => setProvinceId(e.target.value)} name="province">
                  {province.data.map((province) => (
                    <option key={province.id} value={province?.id}>
                      {province?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <label>Quận/huyện</label>
                <select className="form-select" name="district" onChange={(e) => setDistrictId(e.target.value)}>
                  {district.data.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <label>Địa chỉ</label>
                <input className="form-control" name="address" onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        <ListFertilizer data={data} detectFertilizer={detectFertilizer} getFertilizerIds={getFertilizerIds} />
        <div className="col-md-12 ">
          <div className="row">
            <div className="col-md-4 kg-unit">
              <label>Khối lượng phân N yêu cầu</label>
              <input className="form-control mb-2" type="text" onChange={(e) => setN_weight(e.target.value)} />
              <span className="">KG</span>
            </div>
            <div className="col-md-4 kg-unit">
              <label>Khối lượng phân P yêu cầu</label>
              <input className="form-control mb-2" type="text" onChange={(e) => setP_weight(e.target.value)} />
              <span className="">KG</span>
            </div>
            <div className="col-md-4 kg-unit">
              <label>Khối lượng phân K yêu cầu</label>
              <input className="form-control mb-2" type="text" onChange={(e) => setK_weight(e.target.value)} />
              <span className="">KG</span>
            </div>
          </div>
        </div>
        <div className="accordion" id="accordionResult">
          <div className="accordion-item bg-transparent">
            <h2 className="accordion-header d-flex justify-content-center" id="headingResult">
              {weight != "" &&
              n_rate != "" &&
              p_rate != "" &&
              k_rate != "" &&
              n != null &&
              p != null &&
              k != null &&
              n != "" &&
              p != "" &&
              k != "" &&
              provinceId != "" &&
              districtId != "" &&
              address != "" ? (
               (
                  <button
                    className="collapsed"
                    onClick={(e) => submitMixMPK(e)}
                    type="button"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Xác định Kết Quả
                  </button>
                )
              ) : (
                <button
                  className="collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseResult"
                  aria-expanded="false"
                  aria-controls="collapseResult"
                  disabled
                >
                  Xác định Kết Quả
                </button>
              )}
            </h2>
            <Collapse in={show}>
              <div className="accordion-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-uppercase">
                        Phân bón
                      </th>
                      <th scope="col" className="text-uppercase">
                        Trọng lượng
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">N</th>
                      <td>{Math.round(results?.result.N)} KG</td>
                    </tr>
                    <tr>
                      <th scope="row">P</th>
                      <td>{Math.round(results?.result.P)} KG</td>
                    </tr>
                    <tr>
                      <th scope="row">K</th>
                      <td>{Math.round(results?.result.K)} KG</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Collapse>
          </div>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    fertilizer_list: state.fertilizer.list_fertilizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fertilizerActions: bindActionCreators(fertilizerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabCalculatorReverse);
