import React, { useState } from "react";
import "./PreviewAttachment.scss";
import ReactPlayer from "react-player";
import { ExclamationCircleFill } from "react-bootstrap-icons";
import ModalReportImage from "./ModalReportImage";
const PreviewImage = ({ src }) => {
  const isVideo = src.includes(".mp4") || src.includes(".mov");

  if (!isVideo) {
    return <img src={src} className="img-fluid" alt="image-detail" />;
  } else {
    return <ReactPlayer className="img-fluid" url={src} controls={true} height={"100%"}/>;
  }
};

const PreviewAttachment = ({ type, data, navigateFile, showNextButton, showPrevButton, isOwner}) => {
  let [open, setOpen] = useState(false);
  const openReportModal = () => {
    setOpen(true);
  };

  return (
    <div className="w-100 h-100 position-relative d-flex">
      {!isOwner &&
        <ExclamationCircleFill
          size={28}
          className="text-white threeDotsVertical"
          fill="#ffa608"
          onClick={openReportModal}
        />
      }
      <ModalReportImage post={data} open={open} setOpen={setOpen} />
      {showPrevButton && (
        <button
          className="position-absolute left-10 button-preview-attachment"
          style={{ zIndex: "10", padding: "3px" }}
          onClick={() => navigateFile("prev")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path cx="50" cy="50" r="40"
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </button>
      )}
      <div className="w-100 h-100 d-flex justify-content-center align-items-center m-auto">
        {type === "image" && <PreviewImage src={data.name} />}
      </div>
      {showNextButton && (
        <button
          className="position-absolute right-10 button-preview-attachment"
          style={{ zIndex: "10" }}
          onClick={() => navigateFile("next")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path cx="50" cy="50" r="40"
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default PreviewAttachment;
