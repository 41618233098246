import React from "react";
import ScrollToTop from "components/ScrollToTop";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { Nav, TabContainer, TabContent, TabPane } from "react-bootstrap";
import useManageContribute from "./Hooks/useManageContribute";
import TabTechnicalProcess from "./Components/TechnicalProcess";
import TabPets from "./Components/Pets";
import TabMarketPrice from "./Components/MarketPrice";

function ManageContribute() {
  const { setKey } = useManageContribute();
  const changeTab = (tab) => {
    setKey(tab);
  };
  return (
    <>
      <ScrollToTop>
        <div className="manage-contribute">
          <AddMetaTags title={"Quản lý đóng góp"} />
          <div className="row">
            <LeftSidebar />
            <div className="col-md-9 col-sm-12">
              <h5 className="title-line-lt text-uppercase fw-bold">
                <span className="d-block">Quản lý đóng góp</span>
                <div className="cont-center saved-items">
                  <TabContainer defaultActiveKey="market_price" onSelect={(tab) => changeTab(tab)}>
                    <Nav
                      as="ul"
                      className="nav nav-mkt-price mb-3"
                      id="mkt-price-tab"
                      justify
                      style={{ backgroundColor: "#fff" }}>
                      <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
                        <Nav.Link as="button" eventKey="market_price">
                          Giá cả thị trường
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
                        <Nav.Link as="button" eventKey="technicalprocess">
                          Quy trình kỹ thuật
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
                        <Nav.Link as="button" eventKey="pests">
                          Sâu bệnh
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <TabContent>
                      <TabPane eventKey="market_price">
                        <TabMarketPrice />
                      </TabPane>
                      <TabPane eventKey="technicalprocess">
                        <TabTechnicalProcess />
                      </TabPane>
                      <TabPane eventKey="pests">
                        <TabPets />
                      </TabPane>
                    </TabContent>
                  </TabContainer>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </ScrollToTop>
    </>
  );
}

export default ManageContribute;
