import React, { useEffect, useState, useRef } from 'react';
import LeftSidebar from 'components/left_sidebar';
import * as marketActions from 'actions/gia_ca_thi_truong';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AddMetaTags from 'components/add_meta_tags';
import * as locationActions from 'actions/location';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { contributePrice, getMarketPrices } from 'apis/gia_ca_thi_truong';
import { toastSuccess } from 'helpers/toastHelper';
import { useSearchParams } from 'react-router-dom';
import { getlistUnits } from 'apis/product';
import SelectOptions from 'components/Select/index.jsx';
import _ from 'lodash';

function ContributeForm(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  let { data: listProvince } = props.listProvinces;
  let { data: listDistrict } = props.listDistricts;
  const param = searchParams.get('id');
  const [isSubmited, setIsSubmited] = useState(false);
  const { locationActionsCreator } = props;
  const { fetchProvince, fetchDistrict } = locationActionsCreator;
  const [title, setTitle] = useState('');
  const [data, setData] = useState(null);
  const [unit, setUnit] = useState('');
  const [type, setType] = useState('');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [price, setPrice] = useState('');
  const [litsUnit, setListUnit] = useState([]);
  const [errorPrice, setErrorPrice] = useState(false);

  const formik = useFormik({
    initialValues: {
      name_price: '',
      unit_price: '',
      type_price: '',
      price: '',
      province: '',
    },
    // rules - yup:
    validationSchema: Yup.object({
      name_price: Yup.string()
        .matches(/^[a-zA-Z].*[\s\.]*$/g, 'Tên mặt hàng phải bắt đầu bằng chữ cái')
        .required('Vui lòng nhập tên mặt hàng'),
      unit_price: Yup.string()
        .matches(/^[a-zA-Z].*[\s\.]*$/g, 'Vui lòng nhập đơn vị')
        .required('Vui lòng nhập đơn vị'),
      type_price: Yup.string().required('Vui lòng chọn loại mặt hàng'),
      price: Yup.string()
        .matches(/^[1-9]\d*$/, 'Vui lòng chỉ nhập số lớn hơn 0')
        .required('Vui lòng nhập giá hiện tại'),
      province: Yup.string().required('Vui lòng chọn tỉnh/thành phố'),
    }),

    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append('title', values.name_price);
      formData.append('price', values.price);
      formData.append('agricultural_type', values.type_price);
      formData.append('province_id', values.province);
      formData.append('district_id', district);
      formData.append('min_price', values.price);
      formData.append('max_price', values.price);
      formData.append('unit', values.unit_price);
      setIsSubmited(true);
      contributePrice({
        formData: formData,
      }).then(response => {
        if (response) {
          toastSuccess('Bạn đã đóng góp thành công');
          resetForm();
          setIsSubmited(false);
          $('#myfile').val('');
        }
      });
    },
  });
  useEffect(() => {
    if (param) {
      getMarketPrices({ id: param }).then(response => setData(response.data.market_place));
    }
  }, [param]);
  useEffect(() => {
    getlistUnits().then(response => setListUnit(response.data.data));
    fetchProvince();
    if (param) {
      fetchDistrict({ province_id: province });
    } else {
      fetchDistrict({ province_id: formik.values.province });
    }
  }, [fetchProvince, fetchDistrict, formik.values.province, province]);

  const initializeFields = () => {
    setUnit(data['unit']);
    setProvince(data['province_id']);
    setDistrict(data['district_id']);
  };
  useEffect(() => {
    if (data) {
      initializeFields();
    }
  }, [data]);

  const checkParamsChanged = () => {
    if (
      (title !== data.title || unit !== data.unit || type !== data.agricultural_type) &&
      (title !== '' || unit !== '' || type !== '')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const resetState = () => {
    setTitle('');
    setType('');
    setPrice('');
    setErrorPrice(false);
    setIsSubmited(false);
  };
  const formForData = () => {
    const formData = new FormData();

    if (parseInt(price) <= 0 || price === '') {
      setErrorPrice(true);
      setIsSubmited(false);
      return;
    }
    if (!checkParamsChanged()) {
      formData.append('market_place_id', data.id);
    }
    formData.append('title', title != '' ? title : data.title);
    formData.append('unit', unit != '' ? unit : data.unit);
    formData.append('agricultural_type', type != '' ? type : data.agricultural_type);
    formData.append('province_id', province != '' ? province : data.province_id);
    formData.append('district_id', district);
    formData.append('price', price);
    formData.append('min_price', price);
    formData.append('max_price', price);
    formData.append('market_place_id', data.id);
    setIsSubmited(true);
    contributePrice({
      formData: formData,
    }).then(response => {
      if (response) {
        toastSuccess('Bạn đã đóng góp thành công');
        resetState();
      }
    });
  };

  const submitForm = e => {
    formForData();
  };

  const setValueProvince = value => {
    formik.setFieldValue('province', value);
  };

  const setValueDistrict = value => {
    formik.setFieldValue('district', value);
  };

  const listProvinceFormatted = _.map(listProvince, province => {
    return {
      value: province.id,
      label: province.name,
    };
  });

  const listDistrictFormatted = _.map(listDistrict, district => {
    return {
      value: district.id,
      label: district.name,
    };
  });

  return (
    <div className="contribute-form">
      <AddMetaTags title={'Đóng góp giá'} />
      <div className="row">
        <LeftSidebar />
        {!data ? (
          <div className="col-md-9 col-sm-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="container profile-page">
                <h2 className="text-center"> Gửi đóng góp giá</h2>
                <div className="row" style={{ marginTop: '60px' }}>
                  <div className="col-md-12">
                    <label style={{ fontSize: '16px' }}>Tên mặt hàng</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nhập tên mặt hàng"
                      name="name_price"
                      value={formik.values.name_price}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.name_price && formik.touched.name_price && (
                      <span className="error">{formik.errors.name_price}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label style={{ fontSize: '16px' }}>Đơn vị</label>
                    <select
                      style={{ marginBottom: '18px' }}
                      className="form-select"
                      name="unit_price"
                      value={formik.values.unit_price}
                      onChange={formik.handleChange}
                    >
                      <option value="default">Chọn đơn vị</option>
                      {litsUnit.map(function (unit) {
                        return (
                          <option key={unit.id} value={unit.name}>
                            {unit.name}
                          </option>
                        );
                      })}
                    </select>
                    {formik.errors.unit_price && formik.touched.unit_price && (
                      <span className="error">{formik.errors.unit_price}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label style={{ fontSize: '16px' }}>Loại mặt hàng</label>
                    <select
                      style={{ marginBottom: '18px' }}
                      className="form-control"
                      name="type_price"
                      value={formik.values.type_price}
                      onChange={formik.handleChange}
                    >
                      <option>Chọn loại mặt hàng</option>
                      <option value="agricultural">Nông sản</option>
                      <option value="fertilizer">Phân bón</option>
                    </select>
                    {formik.errors.type_price && formik.touched.type_price && (
                      <span className="error">{formik.errors.type_price}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label style={{ fontSize: '16px' }}>Tỉnh/Thành phố</label>
                    <SelectOptions data={listProvinceFormatted} setValue={setValueProvince} placeholder="Chọn tỉnh thành" />
                    {formik.errors.province && formik.touched.province && (
                      <span className="error">{formik.errors.province}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label style={{ fontSize: '16px' }}>Quận/Huyện</label>
                    <SelectOptions data={listDistrictFormatted} setValue={setValueDistrict}  placeholder="Chọn quận huyện" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <label style={{ fontSize: '16px' }}>Giá hiện tại</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nhập giá"
                      name="price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.price && formik.touched.price && (
                      <span className="error">{formik.errors.price}</span>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-success fw-bold text-white py-2 px-4" disabled={isSubmited}>
                    Đóng góp
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="col-md-9 col-sm-12">
            <div className="container profile-page">
              <h2 className="text-center"> Gửi đóng góp giá</h2>
              <div className="row" style={{ marginTop: '60px' }}>
                <div className="col-md-12">
                  <label style={{ fontSize: '16px' }}>Tên mặt hàng</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nhập tên mặt hàng"
                    defaultValue={data.title}
                    onChange={e => setTitle(e.target.value)}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <label style={{ fontSize: '16px' }}>Đơn vị</label>
                  <select
                    style={{ marginBottom: '18px' }}
                    className="form-select"
                    defaultValue={unit}
                    onChange={e => setUnit(e.target.value)}
                    disabled
                  >
                    {' '}
                    {litsUnit.map(function (unit) {
                      return (
                        <option key={unit.id} value={unit.name}>
                          {unit.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-6 col-sm-12">
                  <label style={{ fontSize: '16px' }}>Loại mặt hàng</label>
                  {data.agricultural_type === 'fertilizer' ? (
                    <select
                      style={{ marginBottom: '18px' }}
                      className="form-control"
                      value={type}
                      onChange={e => setType(e.target.value)}
                      disabled
                    >
                      <option value="fertilizer">Phân bón</option>
                      <option value="agricultural">Nông sản</option>
                    </select>
                  ) : (
                    <select
                      style={{ marginBottom: '18px' }}
                      className="form-control"
                      value={type}
                      onChange={e => setType(e.target.value)}
                      disabled
                    >
                      <option value="agricultural">Nông sản</option>
                      <option value="fertilizer">Phân bón</option>
                    </select>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <label style={{ fontSize: '16px' }}>Tỉnh/Thành phố</label>
                  <select
                    style={{ marginBottom: '18px' }}
                    className="form-select"
                    value={province}
                    onChange={e => setProvince(e.target.value)}
                    disabled
                  >
                    {' '}
                    {listProvince.map(function (province) {
                      return (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label style={{ fontSize: '16px' }}>Quận/Huyện</label>
                  <select
                    style={{ marginBottom: '18px' }}
                    className="form-select"
                    value={district}
                    onChange={e => setDistrict(e.target.value)}
                    selected={data.district_id}
                    disabled
                  >
                    <option value="">Chọn quận huyện</option>
                    {listDistrict.map(function (district) {
                      return (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <label style={{ fontSize: '16px' }}>Giá hiện tại</label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Nhập giá"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                  />

                  {errorPrice && <span className="error">Giá hiện tại không hợp lệ</span>}
                </div>
              </div>
              <div className="text-center mt-2">
                <button
                  type="submit"
                  className="btn btn-success fw-bold text-white py-2 px-4"
                  onClick={() => submitForm()}
                  disabled={isSubmited}
                >
                  Đóng góp
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export const mapStateToProps = state => {
  return {
    listMarketPrices: state.market.listMarketPrices,
    listProvinces: state.location.listProvinces,
    listDistricts: state.location.listDistricts,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    marketActionsCreators: bindActionCreators(marketActions, dispatch),
    locationActionsCreator: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributeForm);
