import React, { useRef, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { DOMAIN } from "../../constants";
import Loader from "components/loader";
function Quill(props) {
  const editorRef = useRef();
  const [waitingApi, setWaitingApi] = useState(false);
  const handleChangeRef = (imageUrl) => {
    const content = editorRef.current.getEditorContents();
    props.onChange(content);
  };
  // hàm tạo file  ảnh upload lên
  const handleUploadImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = handleFileChange;
    input.click();
  };
  // hàm lấy kết quả api parse ra DOM
  const insertImage = (imageUrl) => {
    const editor = editorRef.current.getEditor();
    const range = editor.getSelection();
    editor.insertEmbed(range.index, "image", imageUrl);
  };
  // Hàm call api
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setWaitingApi(true);
    axios({
      method: "post",
      url: `${DOMAIN}/api/v2/base/upload_file`,
      data: formData,
    }).then((res) => {
      if (res.data.success) {
        insertImage(res.data.data);
        setWaitingApi(false);
      }
    });
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image", "video"],
        ],
        handlers: {
          image: handleUploadImage,
        },
        clipboard: {
          matchVisual: false,
        },
      },
    }),
    []
  );
  return (
    <>
      {waitingApi && <Loader isLoading={waitingApi} />}
      <ReactQuill
        theme="snow"
        ref={editorRef}
        value={props.value}
        onChange={handleChangeRef}
        modules={modules}
        placeholder={props.placeholder}
      />
    </>
  );
}
export default Quill;
