import React from "react";
import { imageUrl } from "lib";
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";

function ProductItem({ data }) {
  return (
    <>
      {data.length > 0 &&
        data.map(function (item, index) {
          let productImage =
            item.images.length > 0 ? imageUrl(item.images[0].name) : "/images/product-image-default.png";
          return (
            <div className="col-sm-4 col-xs-6 col-md-6 col-lg-3 col-12p5 product-home " key={index}>
              <div className="item-lst  hover">
                <Link to={`/san-pham/${data.slug ? item.slug : item.id}`}>
                  <img src={productImage} alt="product photo" title="product photo" className="img-cover" />
                  <div className="prod-inf">
                    <div className="txt-name">{item.title}</div>
                    <div className="locatn">
                      <span className="icon-location01"></span>
                      <span className="txt-city">{item?.province_name ?? " Không xác định"}</span>
                    </div>
                    <div className="price-item">
                      <div className="price01">
                        <small>Giá lẻ:</small>
                        <div className="d-inline-block">
                          <span className="price-clr">
                            &nbsp;<NumberFormat>{Math.round(item.retail_price)}</NumberFormat>
                          </span>{" "}
                          <span>đ/{item.unit_name}</span>
                        </div>
                      </div>
                      <div className="d-block ">Lượt xem: {item.view_count}</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
    </>
  );
}

export default ProductItem;
