import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in';
import { imageUrl } from 'lib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListTopUp } from 'actions/gift';
import ModalShareConfirm from 'components/shared/modalShareConfirm/modal';
import NumberFormat from 'components/number_format';

function TopUpData({ userPoint, handleTopUpData, getNumberDay }) {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.gift.listTopUp);
  const [topUpId, setTopUpId] = useState('');

  useEffect(() => {
    dispatch(fetchListTopUp());
  }, [dispatch]);

  const object = {
    vinaphone: '/images/card_vina.jpg',
    viettel: '/images/card_viettel.jpg',
    vietnamobile: '/images/card_vietnammobile.png',
    mobifone: '/images/card_mobi.jpg',
  };

  return (
    <>
      {data.length > 0 && (
        <div className="mt-2">
          <h5 className="title-line-lt text-uppercase fw-bold mb-3">
            <span className="d-block">Nạp data</span>
          </h5>
          <FadeIn className="row prod-list" childClassName="col-sm-4 col-xs-6 col-md-6 col-lg-3 col-12p5">
            {data.map(function (tc, index) {
              return (
                <div className="item-lst" key={index}>
                  <div
                    className="bg-img-orgnal"
                    style={{ background: `url(${tc.image ? tc.image : object[tc.carrier]})` }}
                  />
                  <div className="prod-inf">
                    <div className="line-clamp-02 fw-bold mb-2">{tc.carrier}</div>
                    <div className="price-item">
                      <div className="price01">
                        <div className="fs-7">
                          Tên gói: <span className="fw-semibold">{tc.capacity}</span>
                        </div>
                      </div>
                      <div className="price02 d-flex">
                        <div className="fs-7">
                          Điểm:{' '}
                          <span className="fw-semibold red-clr">
                            <NumberFormat>{tc?.point}</NumberFormat>
                          </span>
                        </div>
                        <div className="ms-auto">
                          {userPoint >= tc.point && (
                            <button className="btn btn-success btn-sm" onClick={() => setTopUpId(tc.id)}>
                              Nạp data
                            </button>
                          )}
                        </div>
                        {topUpId && (
                          <ModalShareConfirm
                            onToggle={() => setTopUpId('')}
                            onShare={() => handleTopUpData(topUpId)}
                            isOpen={topUpId}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </FadeIn>
        </div>
      )}
    </>
  );
}

export default TopUpData;
