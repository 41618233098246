import React, { useState, useContext, useEffect } from 'react';
import AppContext from 'components/app_context';
import { useDispatch, useSelector } from 'react-redux';
import { toastError } from 'helpers/toastHelper';
import Loader from 'components/loader';
import 'react-h5-audio-player/lib/styles.css';
import 'react-trumbowyg/dist/trumbowyg.min.css';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactStars from 'react-rating-stars-component';
import ModalReport from './modalReport';
import ModalDeleteConfirmComment from 'components/shared/modalDeleteConfirmComment/modal';
import InputBox from './inputBox';
import SubComment from './subComment';
import Editor from '../../../components/simple_editor';
import CommentBody from '../../../components/CommentBody';
import { validateStringEditor } from 'lib';
import {
  createTechnicalProcessComment,
  fetchTechnicalProcessComment,
  likeTechnicalProcessComment,
  dislikeTechnicalProcessComment,
  reportTechnicalProcessComment,
  managerDeleteTechnicalProcessComment,
  deleteTechnicalProcessComment,
  updateTechnicalProcessComment,
  replyTechnicalProcessComment,
  managerUpdateTechnicalProcessComment,
} from 'redux/actions/technical_process';
import { toastErrorLogin } from '../../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

function TechnicalProcessComment({ id, technicalProcessItem }) {
  const { current_user } = useContext(AppContext);
  const dispatch = useDispatch();
  let listComments = useSelector(state => state.technicalProcess.listTechnicalProcessComment.data);
  let isLoading = useSelector(state => state.technicalProcess.listTechnicalProcessComment.isLoading);

  const hideModal = () => setShowModal(false);
  let [showModal, setShowModal] = useState(false);
  let [showInputBox, setShowInputBox] = useState({});
  let [content, setContent] = useState('');
  let [commentContent, setCommentContent] = useState('');
  let [sortField, setSortField] = useState('liked');
  let [reportContent, setReportContent] = useState('');
  let [reportType, setReportType] = useState('');
  let [typeInput, setTypeInput] = useState('');
  let [reportId, setReportId] = useState(null);
  let [commentId, setCommentId] = useState(null);
  let [showSubComment, setShowSubComment] = useState(null);
  let [rating, setRating] = useState(null);
  let [hadRated, setHadRated] = useState(false);
  let [showPicker, setShowPicker] = useState(false);
  let [innerString, setInnerString] = useState('');
  useEffect(() => {
    dispatch(
      fetchTechnicalProcessComment({
        classable_type: 'TechnicalProcess',
        classable_id: id,
        page: 1,
        limit: 10,
        sort: 'total_likes',
      }),
    );
  }, []);

  let rate = technicalProcessItem?.comment?.rate ?? 0;
  const isCommented = rate > 0;

  const createComment = () => {
    if (!current_user) {
      return toastErrorLogin(isMobile);
    }
    const validation = validateStringEditor(content);
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        if (rating !== null) {
          setHadRated(hadRated => (hadRated = true));
        }
        dispatch(
          createTechnicalProcessComment({
            commentable_type: 'TechnicalProcess',
            content,
            commentable_id: id,
            ...(hadRated ? {} : { rate: rating | 0 }),
          }),
        );
        clearEditorState();
    }
  };

  const userlikeOrDislikeComment = (id, userLiked, classableType) => {
    const actionCreator = userLiked ? dislikeTechnicalProcessComment : likeTechnicalProcessComment;
    dispatch(actionCreator({ classable_id: id, classable_type: classableType }));
  };

  const sortComments = type => {
    const fetchOptions = {
      classable_id: id,
      classable_type: 'TechnicalProcess',
      page: 1,
      limit: 10,
    };
    if (type === 'liked') {
      fetchOptions.sort = 'total_likes';
    } else {
      fetchOptions.sort = 'created_at';
    }
    setSortField(type);
    dispatch(fetchTechnicalProcessComment(fetchOptions));
  };
  const showReportModal = (comment_id, comment_type) => {
    setReportId(comment_id);
    setReportType(comment_type);
    setShowModal(true);
  };

  const submitReport = () => {
    if (reportContent === '') {
      toastError('Vui lòng nhập lý do báo cáo');
      return;
    }

    if (!current_user) {
      toastErrorLogin(isMobile);
      return;
    }

    const reportData = {
      id: current_user.id,
      reason: reportContent,
      classable_id: reportId,
      classable_type: reportType,
    };

    dispatch(reportTechnicalProcessComment(reportData));
    setShowModal(false);
    setReportContent('');
  };

  const deleteComment = commentId => {
    const isManager = current_user?.manager_type === 'admin' || current_user?.manager_type === 'smod';
    const action = isManager ? managerDeleteTechnicalProcessComment : deleteTechnicalProcessComment;

    dispatch(action({ id: commentId }));
    setCommentId(null);
  };

  const handleShowInputBox = (commentId, replierId) => {
    const id = commentId;
    setShowInputBox({
      id,
      replierId,
    });
  };
  const submitReplyComment = (content, type) => {
    if (!current_user) {
      toastErrorLogin(isMobile);
      return;
    }
    const validation = validateStringEditor(content);
    const commentData = {
      id: showInputBox.id,
      content: content,
    };
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        if (type === 'reply') {
          dispatch(replyTechnicalProcessComment(commentData));
        } else {
          if (current_user.manager_type !== 'member') {
            dispatch(managerUpdateTechnicalProcessComment(commentData));
          } else {
            dispatch(updateTechnicalProcessComment(commentData));
          }
        }
        setShowInputBox(false);
        setCommentContent('');
    }
  };

  const handleShowChildComment = id => {
    setShowSubComment(id);
  };

  const clearEditorState = () => {
    $('#editor').html('');
    setContent('');
    setShowPicker(false);
    setInnerString('');
  };
  return (
    <div className="inpt-cntn cmnt-box position-relative">
      <div className="editor">
        <Editor
          component="div"
          setShowPicker={setShowPicker}
          showPicker={showPicker}
          onChange={setContent}
          value={content}
          createComment={createComment}
          setInnerString={setInnerString}
          innerString={innerString}
          placeholder="Bình luận của bạn"
        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex align-items-center">
          <span className="mr-2 mt-1 fs-6 fw-semibol font-italic">Hãy đánh giá:</span>
          <ReactStars
            key={content}
            count={5}
            size={30}
            activeColor="#F59E0B"
            value={isCommented ? rate : rating}
            edit={!isCommented && !hadRated}
            half={false}
            onChange={value => setRating(value)}
          />
        </div>
      </div>

      <Loader isLoading={isLoading} />
      {listComments.length > 0 && (
        <div className="actions-sort-cmt d-flex mt-3">
          <ul>
            <li
              className={`${sortField === 'liked' && 'active-sort'} action-sort-like  hover`}
              onClick={() => sortComments('liked')}
            >
              Yêu thích nhất
            </li>
            <li
              className={`${sortField === 'created' && 'active-sort'} action-sort-new hover`}
              onClick={() => sortComments('created')}
            >
              Bình luận mới nhất
            </li>
          </ul>
        </div>
      )}
      <div className="cmt-listing">
        {listComments &&
          listComments.map(function (comment) {
            return (
              <div key={comment.id} className="mt-3">
                <CommentBody comment={comment} />
                <div className="actions d-flex align-items-center my-2">
                  <div className={`${comment?.user_liked ? 'active' : ''} like d-flex align-items-center mr-3`}>
                    <div
                      className="d-inline-block"
                      onClick={() => userlikeOrDislikeComment(comment.id, comment.user_liked, comment.classable_type)}
                    >
                      <span className="icon-like01"></span>
                      <span> {comment.user_liked ? 'Đã thích' : 'Thích'}</span>
                      <small>({comment.total_likes === null ? 0 : comment.total_likes})</small>
                    </div>
                  </div>
                  {comment?.total_answers > 0 && (
                    <div className="d-inline-block cmnt mr-3" onClick={() => handleShowChildComment(comment.id)}>
                      <a className="d-flex align-items-center">
                        <span className="icon-comment-line"></span>&nbsp;
                        <span>Bình luận</span>
                        <small>({comment?.total_answers})</small>
                      </a>
                    </div>
                  )}
                  <div
                    className="d-flex align-items-center"
                    style={{ paddingLeft: '6px' }}
                    onClick={() => {
                      handleShowInputBox(comment.id, comment.user_id);
                      setTypeInput('reply');
                    }}
                  >
                    <span>Trả lời</span>
                  </div>
                  <div className="dropdown post-report ms-auto">
                    <Dropdown>
                      <Dropdown.Toggle as="div" className="dropdown post-report ms-auto">
                        <button className="btn dropdown-toggle">
                          <span className="icon-menu-dot"></span>
                        </button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                        <li className="dropdown-item">
                          <div className="" onClick={() => showReportModal(comment.id, comment.classable_type)}>
                            <a>Vi phạm</a>
                          </div>
                        </li>
                        {current_user?.id == comment.user_id ||
                        current_user?.manager_type == 'admin' ||
                        current_user?.manager_type == 'smod' ? (
                          <li className="dropdown-item">
                            <div
                              className=""
                              onClick={() => {
                                setCommentContent(comment.content);
                                handleShowInputBox(comment.id, comment.user_id);
                                setTypeInput('edit');
                              }}
                            >
                              <a>Cập nhật</a>
                            </div>
                          </li>
                        ) : (
                          ''
                        )}
                        {current_user?.id === comment.user_id ||
                        current_user?.manager_type == 'smod' ||
                        current_user?.manager_type == 'admin' ? (
                          <li className="dropdown-item">
                            <div
                              className=""
                              onClick={() => {
                                setCommentId(comment.id);
                              }}
                            >
                              <a>Xóa</a>
                            </div>
                          </li>
                        ) : (
                          ''
                        )}
                        {commentId && (
                          <ModalDeleteConfirmComment
                            isOpen={commentId}
                            onDelete={() => deleteComment(comment.id, 'comment', current_user?.manager_type)}
                            onToggle={() => setCommentId(null)}
                          />
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <ModalReport
                    hideModal={hideModal}
                    setReportContent={setReportContent}
                    reportContent={reportContent}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    submitReport={submitReport}
                  />
                </div>
                {showInputBox.id === comment.id && (
                  <InputBox
                    setCommentContent={setCommentContent}
                    commentContent={commentContent}
                    current_user={current_user}
                    submitReplyComment={submitReplyComment}
                    typeInput={typeInput}
                    comment={comment}
                  />
                )}
                {showSubComment === comment.id && (
                  <SubComment
                    userlikeOrDislikeComment={userlikeOrDislikeComment}
                    comment={comment}
                    handleShowInputBox={handleShowInputBox}
                    current_user={current_user}
                    showReportModal={showReportModal}
                    ModalReport={ModalReport}
                    showInputBox={showInputBox}
                    setShowInputBox={setShowInputBox}
                    setContent={setContent}
                    content={content}
                    submitReplyComment={submitReplyComment}
                    typeInput={typeInput}
                    setTypeInput={setTypeInput}
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default TechnicalProcessComment;
