import React from 'react';

function NotFound() {
  return (
    <>
      <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center row">
          <div class=" col-md-6">
            <img src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg" alt="" class="img-fluid" />
          </div>
          <div class=" col-md-6 mt-5">
            <p class="fs-3">
              {' '}
              <span class="text-danger">Lỗi!</span> Không tìm thấy trang.
            </p>
            <p class="lead">Không tìm thấy trang của bạn.</p>
            <a href="/" class="btn btn-primary">
              Quay về trang chủ
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
