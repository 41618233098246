import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { imageUrl } from 'lib/index';
import { Link, useLocation } from 'react-router-dom';
import { fetchCatalogueOfProduct } from 'actions/product';

function SidebarCatalogueOfProduct({ marketType }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { data } = useSelector(state => state.product.catalogueOfProducts);
  const [productType, setProductType] = useState('');

  const pathProductTypes = {
    '/cho-2nong': 'farmers_markets',
    '/cho-2nong/nong-san': 'farmers_markets',
    '/cho-2nong/vat-tu-nong-nghiep': 'agricultural_materials_market',
    '/cho-2nong/si-nong-nghiep': 'agricultural_wholesale_market',
    '/cho-2nong/nha-san-xuat': 'producer_market',
    '/danh-sach-doanh-nghiep': 'producer_market',
  };
  const fetchCatalogueData = () => {
    const productType = pathProductTypes[pathname] || (pathname.startsWith('/doanh-nghiep/') ? 'producer_market' : '');
    setProductType(productType);

    dispatch(fetchCatalogueOfProduct({ catalogue_type: productType || marketType, parent_id: 'is_parent' }));
  };
  useEffect(() => {
    fetchCatalogueData();
  }, [pathname, marketType]);

  return (
    <div className="col-md-3 col-sm-12 order-md-2 order-xs-1">
      <div className="cate-prod">
        <div className="captn-tag">Danh mục</div>
        <div className="mt-1">
          {_.chunk(data, 2).map(function (catalogues, index) {
            return (
              <div key={index} className={`${index > 0 ? 'row mt-3' : 'row'}`}>
                {catalogues.map(function (cat, index) {
                  let data = {
                    id: cat.id,
                    name: cat.name,
                    slug: cat.slug,
                    product_type: productType || marketType,
                  };
                  let image = cat.image !== null ? imageUrl(cat.image) : '/images/photos/photo-default.png';
                  return (
                    <div key={index} className="col-6">
                      <Link
                        to={`/san-pham/danh-muc/${cat?.slug}`}
                        className="bg-img box-shadow"
                        state={data}
                        style={{ backgroundImage: `url(${image})` }}
                      >
                        <div>
                          <span className="cate-txt">{cat.name}</span>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default React.memo(SidebarCatalogueOfProduct);
