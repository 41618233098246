import { addProductToCartSuccess, updateNumberProductInCartSuccess, calculateTotalPriceInCartSuccess, removeProductFromCartSuccess} from "actions/cart";
import {put} from 'redux-saga/effects';

function* userAddProductToCart({ payload }) {
  try {
    const {params} = payload;
    yield put(addProductToCartSuccess(params))
  } catch (error) {
    console.log(error);
  }
}
function* userUpdateNumberProductInCart({ payload }) {
  try {
    const {params} = payload;
    yield put(updateNumberProductInCartSuccess(params));
  } catch (error) {
    console.log(error);
  } 
}
function* userCalculatePriceProductInCart({ payload }) {
  try {
    const {params} = payload;
    yield put(calculateTotalPriceInCartSuccess(params));
  } catch (error) {
    
  }
}
function* userRemoveProductFromCart({ payload }) {
  try {
    const {params} = payload;
    yield put(removeProductFromCartSuccess(params));
  } catch (error) {
    
  }
}
export { userAddProductToCart, userUpdateNumberProductInCart, userCalculatePriceProductInCart, userRemoveProductFromCart};
