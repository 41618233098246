import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { connectShipping, getListShipping } from "../../../apis/shipping";
import { useFormik } from "formik";
import { toastError } from 'helpers/toastHelper';

function GiaoHangTietKiem(props) {
  const {
    handleToggle,
    isToggled,
    isOpen,
    shopId,
    current_user,
    handleClickArrow,
  } = props;

  const [message, setMessage] = useState({});
  const [shippingUnit, setShippingUnit] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkListShipping = async () => {
    const { data } = await getListShipping(shopId);
    data.data.forEach((element, index) => {
      if (element?.shipping_code?.toLowerCase() === 'ghtk') {
        setShippingUnit(element)
      }
    })
  }

  const handleKeyUp = (e) => {
    setIsSubmitting(e.target.value !== '');
  }

  const {
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      client_id: "",
      secret_key: "",
      shipping_id: shippingUnit.id,
      shop_id: current_user?.shop?.id,
    },
    onSubmit: async (values, { resetForm }) => {
      const params = {
        client_id: values.client_id,
        secret_key: values.secret_key,
        shipping_id: shippingUnit.id,
        shop_id: current_user?.shop?.id,
      };
      try {
        const response = await connectShipping(params);
        if (response.data.success === true) {
          setMessage({
            message: response.data.data,
            success: response.data.success
          })
          resetForm();
        } else 
        {
          setMessage({
            message: response.data,
            success: response.data.success
          })
        }
      } catch (error) {
        toastError(error.data.data);
        setMessage({
          message: error.data,
          success: error.data.success
        })
      }
    },
  });

  useEffect(() => {
    checkListShipping()
  }, [])
  return (
    <>
      <div className="card-body custom-card-body">
      <div className="d-flex justify-content-between">
        <div onClick={handleClickArrow}>                      
          <h3>Giao hàng tiết kiệm</h3>
        </div>
        <div className="d-flex ml-1">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isToggled}
              onChange={() => handleToggle(shippingUnit.id)}
            />
          </div>
          <div className="custom-arrow">
            <span className="icon-arrow-solid" style={{ fontSize: '10px', marginLeft: '4px', color: 'black' }} onClick={handleClickArrow}></span>
          </div>
        </div>
      </div>
      {isOpen && (
        <>
        
          <div className="form-group">
            <label className="m-0" htmlFor="secret_key">Email:</label>
            <input
              type="text"
              className="form-control"
              id="client_id"
              placeholder="Nhập email"
              onKeyUp={(e) => handleKeyUp(e)}
              onChange={handleChange} />
            <label className="m-0 mt-2" htmlFor="secret_key">Mật khẩu:</label>
            <input
              type="password"
              className="form-control"
              id="secret_key"
              placeholder="Nhập password"
              onKeyUp={(e) => handleKeyUp(e)}
              onChange={handleChange} />
          </div>
          <div className="form-group text-center">
            <div className="d-flex justify-content-end">
              {message.message != '' && message.success == true ? (
                <div className="message-valid-custom">{message.message}</div>
              ) : (
                <div className="message-invalid-custom">{message.message}</div>
              )}
              <button
                type="button"
                className="btn btn-primary-custom"
                onClick={(e) => handleSubmit(e)}
                style={{ top: "3px", right: "5px" }}
                disabled={!isSubmitting}
              >
                {" "}
                Kết nối
              </button>
            </div>
          </div>
        </>
      )}
    </div>
    </>
  );
}

export default GiaoHangTietKiem;
