import React from "react";
import Carousels from "../../../../components/Carousels";
function Slide({ images }) {
  return (
    <>
      {images && (
        <Carousels infoImageFromHome={images} rootUrlImage={null} images={images?.map((item) => ({ original: item.images.name }))} page={"Home"} />
      )}
    </>
  );
}

export default Slide;
