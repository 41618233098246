import * as weatherConst from "constants/weather";

export const fetchWeather = (params) => {
  return {
    type: weatherConst.FETCH_WEATHER,
    payload: {
      params,
    },
  };
};

export const fetchWeatherSuccess = (data) => {
  return {
    type: weatherConst.FETCH_WEATHER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchWeatherFailed = (error) => {
  return {
    type: weatherConst.FETCH_WEATHER_FAILED,
    payload: {
      error,
    },
  };
};
export const fetchWeatherPlaces = (params) => {
  return {
    type: weatherConst.FETCH_WEATHER_PLACES,
    payload: {
      params,
    },
  };
};

export const fetchWeatherPlacesSuccess = (data) => {
  return {
    type: weatherConst.FETCH_WEATHER_PLACES_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchWeatherPlacesFailed = (error) => {
  return {
    type: weatherConst.FETCH_WEATHER_PLACES_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchForecastWeather = (params) => {
  return {
    type: weatherConst.FETCH_FORECAST_WEATHER,
    payload: {
      params,
    },
  };
};

export const fetchForecastWeatherSuccess = (data) => {
  return {
    type: weatherConst.FETCH_FORECAST_WEATHER_SUCCESS,
    payload: {
      data,
    },
  };
};
export const fetchForecastWeatherFailed = (error) => {
  return {
    type: weatherConst.FETCH_FORECAST_WEATHER_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchWeathersId = (params) => {
  return {
    type: weatherConst.FETCH_WEATHERS_ID,
    payload: {
      params,
    },
  };
};

export const fetchWeathersIdSuccess = (data) => {
  return {
    type: weatherConst.FETCH_WEATHERS_ID_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchWeathersIdFailed = (error) => {
  return {
    type: weatherConst.FETCH_WEATHERS_ID_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchWeatherAddress = (params) => {
  return {
    type: weatherConst.FETCH_WEATHER_ADDRESS,
    payload: {
      params,
    },
  };
};

export const fetchWeatherAddressSuccess = (data) => {
  return {
    type: weatherConst.FETCH_WEATHER_ADDRESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchWeatherAddressFailed = (error) => {
  return {
    type: weatherConst.FETCH_WEATHER_ADDRESS_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchWeatherAudio = (params) => {
  return {
    type: weatherConst.FETCH_WEATHER_AUDIO,
    payload: {
      params
    }
  }
}

export const fetchWeatherAudioSuccess = (data) => {
  return {
    type: weatherConst.FETCH_WEATHER_AUDIO_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchWeatherSchedule = (params) => {
  return {
    type: weatherConst.FETCH_WEATHER_SCHEDULE,
    payload: {
      params
    }
  }
}
export const fetchWeatherScheduleSuccess = (data) => {
  return {
    type: weatherConst.FETCH_WEATHER_SCHEDULE_SUCCESS,
    payload: {
      data
    }
  }
}

export const deleteWeatherSchedule = (params) => {
  return {
    type: weatherConst.DELETE_WEATHER_SCHEDULE,
    payload: {
      params
    }
  }
}

export const deleteWeatherScheduleSuccess = (data) => {
  return {
    type: weatherConst.DELETE_WEATHER_SCHEDULE_SUCCESS,
    payload: {
      data
    }
  }
} 

export const fetchWeatherScheduleId = (id) => {
  return {  
    type: weatherConst.FETCH_WEATHER_SCHEDULE_BY_ID,
    payload: {
      id
    }
  }
}

export const fetchWeatherScheduleIdSuccess = (data) => {
  return {  
    type: weatherConst.FETCH_WEATHER_SCHEDULE_BY_ID_SUCCESS,
    payload: {
      data
    }
  }
}