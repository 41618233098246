import axiosService from "services/axiosService";
import { DOMAIN } from "constants";
import qs from "query-string";

const url = "api/v2/weather";

export const getWeather = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/details${query}`);
};

const url_weather_places = "api/v2/weather/weather_places";
export const getWeatherPlaces = () => {
  return axiosService.get(`${DOMAIN}/${url_weather_places}`);
};

const url_address = "api/v2/weather/weather_address";
export const getWeatherWithAddress = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_address}${query}`);
};

export const getAudioWeather = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/details_audio_link${query}`);
}

export const getWeatherSchedule = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/api/v2/notice_schedules${query}`, true);
}

export const getWeatherStatus = () => {
 return axiosService.get(`${DOMAIN}/${url}/weather_status`);
}

export const createWeatherSchedule = (params) => {
  return axiosService.post(`${DOMAIN}/api/v2/notice_schedules`, params);
}
export const deleteWeatherSchedule = (id) => {
  return axiosService.delete(`${DOMAIN}/api/v2/notice_schedules/${id}`, true);
}

export const getWeatherScheduleId = (id) => {
  return axiosService.get(`${DOMAIN}/api/v2/notice_schedules/${id}`, true);
}

export const updateWeatherSchedule = (params) => {
  return axiosService.put(`${DOMAIN}/api/v2/notice_schedules/${params.id}`, params);
}
