import * as giftConst from "constants/gift";

export const fetchCatalogueGiftAndProduct = (params) => {
  return {
    type: giftConst.FETCH_CATALOGUE_GIFT_AND_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchCatalogueGiftAndProductSuccess = (data) => {
  return {
    type: giftConst.FETCH_CATALOGUE_GIFT_AND_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchCatalogueGiftAndProductFailed = (error) => {
  return {
    type: giftConst.FETCH_CATALOGUE_GIFT_AND_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const exchangePointsForGift = (params) => {
  return {
    type: giftConst.EXCHANGE_POINTS,
    payload: {
      params
    }
  }
}
export const exchangePointsForGiftSuccess = (data) => {
  return {
    type: giftConst.EXCHANGE_POINTS_SUCCESS,
    payload: {
      data
    }
  }
}

export const exchangePointsForGiftFailed = (error) => {
  return {
    type: giftConst.EXCHANGE_POINTS_FAILED,
    payload: {
      error
    }
  }
}

export const fetchPointsTransaction = (params) => {
  return {
    type: giftConst.FETCH_POINTS_TRANSACTION,
    payload: {
      params
    }
  }
}

export const fetchPointsTransactionSuccess = (data) => {
  return {
    type: giftConst.FETCH_POINTS_TRANSACTION_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchPointsTransactionFailed = (error) => {
  return {
    type: giftConst.FETCH_POINTS_TRANSACTION_FAILED,
    payload: {
      error
    }
  }
}

export const fetchListTopUp = (params) => {
  return {
    type: giftConst.FETCH_LIST_TOP_UP,
      payload: {
        params
    }
  }
}

export const fetchListTopUpSuccess = (data) => {
  return {
    type: giftConst.FETCH_LIST_TOP_UP_SUCCESS,
     payload: {
      data
    }
  }
}

export const topUpDataForUser = (params) => {
  return {
    type: giftConst.TOP_UP_DATA_USER,
     payload: {
      params
    }
  }
}

export const topUpDataForUserSuccess = (data) => {
  return {
    type: giftConst.TOP_UP_DATA_USER_SUCCESS,
     payload: {
      data
    }
  }
}

export const topUpDataForUserFailed = (error) => {
  return {
    type: giftConst.TOP_UP_DATA_USER_FAILED,
     payload: {
      error
    }
  }
}

export const fetchListGift = (params) => {
  return {
    type: giftConst.FETCH_LIST_GIFT,
    payload: {
      params
    }
  }
}

export const fetchListGiftSuccess = (data) => {
  return {
    type: giftConst.FETCH_LIST_GIFT_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchListGiftFailed = (error) => {
  return {
    type: giftConst.FETCH_LIST_GIFT_FAILED,
    payload: {
      error
    }
  }
}

export const updateQuantityExchangedOfGift = (gift_id) => {
  return {
    type: giftConst.UPDATE_QUANTITY_OF_GIFT,
    payload: {
      gift_id
    }
  }
}

export const updateQuantityExchangedOfGiftSuccess = (gift_id) => {
  return {
    type: giftConst.UPDATE_QUANTITY_OF_GIFT_SUCCESS,
    payload: {
      gift_id
    }
  }
}

export const updateQuantityExchangedOfGiftFailed = (error) => {
  return {
    type: giftConst.UPDATE_QUANTITY_OF_GIFT_FAILED,
    payload: {
      error
    }
  }
}
