import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  managerFetchContributeTechnicalProcess,
  managerFetchContributePests,
  managerFetchContributeMarketPrice,
  managerContributeTechnicalProcess,
  managerDeleteContributeTechnicalProcess,
  managerContributePests,
  managerDeleteContributePest,
  managerDeleteContributeMarketPrice,
  managerApproveContributeMarketPrice,
} from "actions/manager";
import Swal from "sweetalert2";
import Popover from "react-bootstrap/Popover";

const useManageContribute = () => {
  const [key, setKey] = useState("market_price");
  const [numItems, setNumItems] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [activeOverlay, setActiveOverlay] = useState(null);
  const dispatch = useDispatch();
  let contributeTechnicalProcess = useSelector((state) => state.manager.contribute_technical_process);
  let contributePets = useSelector((state) => state.manager.contribute_pests);
  let contributeMarket = useSelector((state) => state.manager.contribute_market_price);

  useEffect(() => {
    switch (key) {
      case "market_price": {
        dispatch(managerFetchContributeMarketPrice({ limit: "" }));
        break;
      }
      case "technicalprocess": {
        dispatch(managerFetchContributeTechnicalProcess({ limit: "" }));
        break;
      }
      case "pests": {
        dispatch(managerFetchContributePests({ limit: "" }));
        break;
      }
      default: {
      }
    }
  }, [key]);
  const handleApproveContributeTechnicalProcess = async (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Duyệt đóng góp này!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(managerContributeTechnicalProcess({ id: id, status: { status: 1 } }));
      }
    });
  };

  const handleDeleteContributeTechnicalProcess = (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Xóa đóng góp này!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(managerDeleteContributeTechnicalProcess(id));
      }
    });
  };

  const handleApproveContributePests = (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Duyệt đóng góp này!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(managerContributePests({ id: id, approve: { approve: "accepted" } }));
      }
    });
  };

  const handleDeleteContributePests = (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Xóa đóng góp này!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          managerDeleteContributePest({
            id: id,
            approve: { approve: "rejected" },
          })
        );
      }
    });
  };

  const handleDeleteContributeMarketPrice = (parentId, childrenId) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Xóa đóng góp này!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          managerDeleteContributeMarketPrice({
            marketPriceId: childrenId,
            parentId: parentId,
          })
        );
      }
    });
  };
  const handleApproveApproveContributeMarketPrice = (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Duyệt đóng góp này!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Hủy",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(managerApproveContributeMarketPrice({ id: id, status: { status: 1 } }));
      }
    });
  };

  const Nodata = () => {
    return (
      <>
        <div style={{ padding: "24px" }}>Không có dữ liệu</div>
      </>
    );
  };

  const expandMore = () => {
    setNumItems((num) => num + 10);
  };

  const showModal = (url) => {
    setOpenModal(true);
    setImageUrl(url);
  };
  const closeModal = () => setOpenModal(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>{activeOverlay?.title}</Popover.Body>
    </Popover>
  );
  const handleTitleClick = (dataItem) => {
    if (activeOverlay && activeOverlay.id === dataItem.id) {
      setActiveOverlay(null); // Nếu click lại vào data item hiện tại, ẩn overlay
    } else {
      setActiveOverlay(dataItem); // Hiển thị overlay cho data item mới
    }
  };

  return {
    setKey,
    key,
    contributeTechnicalProcess,
    contributePets,
    contributeMarket,
    handleApproveContributeTechnicalProcess,
    handleDeleteContributeTechnicalProcess,
    handleApproveContributePests,
    handleDeleteContributePests,
    handleDeleteContributeMarketPrice,
    handleApproveApproveContributeMarketPrice,
    Nodata,
    expandMore,
    numItems,
    setOpenModal,
    openModal,
    showModal,
    closeModal,
    imageUrl,
    popover,
    handleTitleClick,
    activeOverlay,
  };
};

export default useManageContribute;
