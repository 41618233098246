import React, { useState, useEffect } from "react";
import { TabContainer, TabContent, TabPane, Nav } from "react-bootstrap";
import SearchInvoiceBox from "./searchBox";
import BuyingPage from "./buyingPage";
import SellingPage from "./sellingPage";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuyingInvoices, fetchSellingInvoices } from "redux/actions/invoice";
import { fetchBuyingRefundInvoices, fetchSellingRefundInvoices } from "redux/actions/refund_invoice";
import SearchBoxForSellingPage from "./searchBoxForSellingPage";
const Tabs = () => {
  const [tabType, setTabType] = useState("buying");
  const dispatch = useDispatch();
  const listBuyingInvoices = useSelector((state) => state.invoices.listBuyingInvoices.data);
  const listSellingInvoices = useSelector((state) => state.invoices.listSellingInvoices.data);
  const listBuyingRefundInvoices = useSelector((state) => state.refundInvoices.listBuyingRefundInvoices.data);
  const listSellingRefundInvoices = useSelector((state) => state.refundInvoices.listSellingRefundInvoices.data);
  const [statusBox, setStatusBox] = useState('pending');
  const [keyword, setKeyword] = useState("");
  const handleChangeTab = (type) => {
    setTabType(type);
    if (type === "selling") {
      dispatch(fetchSellingInvoices({ page: 1, limit: 24, status: "pending", type: "sales"}));
    }else{
      dispatch(fetchBuyingInvoices({ page: 1, limit: 24, status: "pending", type: "purchase"}));
    }
    setStatusBox("pending");
    setKeyword("");
  };
  useEffect(() => {
    dispatch(fetchBuyingInvoices({ page: 1, limit: 24, status: "pending", type: "purchase" }));
  }, []);

  const filterInvoices = (tab, keyword) => {
    setStatusBox(tab);
    setKeyword(keyword);
    if (tabType === "buying") {
      if (tab === "returned") {
        dispatch(fetchBuyingRefundInvoices({ page: 1, limit: 24, type: "purchase", sku: keyword }));
      } else {
        dispatch(fetchBuyingInvoices({ page: 1, limit: 24, status: tab, type: "purchase", sku_keyword: keyword }));
      }
    } else {
      if (tab === "returned") {
        dispatch(fetchSellingRefundInvoices({ page: 1, limit: 24, type: "sales", sku: keyword }));
      } else {
        dispatch(fetchSellingInvoices({ page: 1, limit: 24, status: tab, type: "sales", sku_keyword: keyword }));
      }
    }
  };

  const statusCheck = (string) => {
    switch (string) {
      case "delivered":
        return { text: "Đã giao hàng", color: "success" };
      case "pending":
        return { text: "Chờ xác nhận", color: "warning" };
      case "cancelled":
        return { text: "Đã hủy", color: "danger" };
      case "failed_delivery":
        return { text: "Giao thất bại", color: "danger" };
      case "returned":
        return { text: "Trả hàng", color: "warning" };
      case "ready_to_ship":
        return { text: "Chờ lấy hàng", color: "warning" };
      case "shipped":
        return { text: "Đang giao", color: "warning" };
      case "rejected":
        return { text: "Người bán từ chối đơn", color: "danger" };
      case "admin_rejected":
        return { text: "Quản trị viên từ chối đơn", color: "danger" };
      default:
        return { text: "", color: "primary" };
    }
  };

  const statusReturnCheck = (string) => {
    switch (string) {
      case "requested":
        return { text: "Yêu cầu đã được gửi", color: "warning" };
      case "approved":
        return { text: "Yêu cầu được chấp nhận", color: "warning" };
      case "rejected":
        return { text: "Yêu cầu bị người bán từ chối", color: "danger" };
      case "cancelled":
        return { text: "Yêu cầu bị huỷ bởi người mua", color: "danger" };
      case "shipped":
        return { text: "Hàng đã được lấy/đang vận chuyển", color: "warning" };
      case "received":
        return { text: "Hàng trả về đã nhận được", color: "success" };
      case "refunded":
        return { text: "Hoàn tiền đã được xử lý", color: "success" };
      case "completed":
        return { text: "Quá trình hoàn tất", color: "success" };
      default:
        return { text: "", color: "primary" };
    }
  };

  return (
    <>
      <TabContainer defaultActiveKey="buying" onSelect={(type) => handleChangeTab(type)}>
        <Nav as="ul" className="nav nav-mkt-price" id="mkt-price-tab" justify style={{ backgroundColor: "#fff" }}>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="buying">
              Đơn Mua
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="selling">
              Đơn Bán
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <SearchBoxForSellingPage
          filter={filterInvoices}
          statusBox={statusBox}
          keyword={keyword}
          setKeyword={setKeyword}
        />
        <TabContent>
          <TabPane eventKey="buying" title="Đơn Mua">
            { statusBox === 'returned' ?
              <BuyingPage data={listBuyingRefundInvoices} statusCheck={statusReturnCheck} />
              :
              <BuyingPage data={listBuyingInvoices} statusCheck={statusCheck} />
            }
          </TabPane>
          <TabPane eventKey="selling" title="Đơn Bán">
            { statusBox === 'returned' ?
              <SellingPage data={listSellingRefundInvoices} statusCheck={statusReturnCheck} />
            :
              <SellingPage data={listSellingInvoices} statusCheck={statusCheck} />
            }
          </TabPane>
        </TabContent>
      </TabContainer>
    </>
  );
};

export default Tabs;
