import React, { useEffect } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import News from "./component/news/index";
import Products from "./component/products/index";
import { fetchPickTop } from "actions/post";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Slide from "./component/SlidePickTop/Slide";
import Loader from "components/loader";
import { Link } from "react-router-dom";
import {createTrackActivity} from 'actions/track_activity';
import {setParamsTrackActivity} from 'lib';

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPickTop({ limit: 5 }));
    dispatch(createTrackActivity(setParamsTrackActivity("Home page", "GET", { limit: 5 }, "home_page")));
  }, [dispatch]);

  const { data, isLoading } = useSelector((state) => state.post.listPickTops);
  const listSlide = _.map(data, (value) => {
    return {
      images: _.first(value.images),
      viewed: value.viewed,
      total_comment: value.total_comment,
      total_like: value.total_like,
      title: value.title,
      id: value.id,
      user_id: value.user_id,
      user_name: value.user_name,
      created_name: value.created_name,
      user_image: value.shop_image,
    };
  });

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  
  return (
    <div className="home-wrapper">
      <AddMetaTags title="Trang chủ" />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <section className="posts-sectn">
            <div className="bd-example position-relative">
              <Loader isLoading={isLoading} />
              <Slide images={listSlide} />
            </div>
          </section>
          <News />
          <Link to={`/tin-tuc`} className="ml-auto">
            <div
              className="view-more background-clr-f5  pointer text-center hover mt-1"
              style={{ height: "40px", paddingTop: "5px" }}
            >
              <span className="cmore fw-semibold fs-6">
                <u className=" fw-500">Xem thêm&nbsp;</u>
              </span>
              <span className="icon-arrow-solid" style={{ fontSize: "6px" }}></span>
            </div>
          </Link>
          <Products />
          <div onClick={scrollToTop}>
            <Link to={`/cho-2nong`} className="ml-auto">
              <div
                className="view-more background-clr-f5  pointer text-center hover mt-1"
                style={{ height: "40px", paddingTop: "5px" }}
              >
                <span className="cmore fw-semibold fs-6">
                  <u className=" fw-500">Xem thêm&nbsp;</u>
                </span>
                <span className="icon-arrow-solid" style={{ fontSize: "6px" }}></span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
