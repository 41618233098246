import { call, put } from 'redux-saga/effects'
import { STATUS_CODE } from 'constants'
import { createCall } from 'apis/call'
import {createCallSuccess} from 'actions/call';

export function* createCallExpert({payload}){
  const {params} = payload;
  const resp = yield call(createCall, params);
  let {data, status} = resp;
  if(status === STATUS_CODE.SUCCESS){
    yield put(createCallSuccess(data))
  }
}