import qs from 'query-string';
import axiosService from 'services/axiosService';
import { DOMAIN, PAGE, PER_PAGE } from 'constants';

const url = 'api/v2/locations';
export const getProvinces = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/list_provinces${query}`)
};

export const getDistricts = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/list_districts${query}`)
};

export const getWards = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/list_wards${query}`)
};
