import React from "react";
function InputField({ setKeyword }) {
  return (
    <div className="row traceability-form">
      <input
        className="rounded-2"
        type="text"
        placeholder="Tên sản phẩm, mã sản phẩm ..."
        onChange={(e) => setKeyword(e.target.value)}
      ></input>
      <span className="icon-search" />
    </div>
  );
}
export default InputField;
