import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SelectField = ({ type, startDate, endDate, parentClass, onChange }) => {

  return (
    <div className={parentClass + ` dflex position-relative`}>
      <DatePicker
        selected={type === 'to' ? new Date(endDate) : new Date(startDate)}
        onChange={(date) => onChange(date, type)}
        dateFormat="dd/MM/yyyy"
        maxDate={new Date()}
        className="datepicker-custom"
      />
      <div className="location-icon">
        <span className="icon-calendar"></span>
      </div>
    </div>
  );
};

export default SelectField;
