import * as productConst from "constants/product";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { checkLoadMore } from "lib";

const initialState = {
  listProducts: {
    data: [],
    error: "",
    isLoading: false,
  },

  listUserProducts: {
    data: [],
    error: "",
    isLoading: false,
  },
  productDetail: {
    data: null,
    isLoading: false,
    error: "",
  },
  highlightProducts: {
    data: [],
    error: "",
    isLoading: false,
  },
  relationProducts: {
    data: [],
    error: "",
    isLoading: false,
  },
  catalogueOfProducts: {
    data: [],
    error: "",
    isLoading: false,
  },
  childCatalogueOfProduct: {
    data: [],
    error: "",
    isLoading: false,
    entry: {},
  },
  commentProducts: {
    data: [],
    error: "",
    isLoading: false,
  },
  favoritesProducts: {
    data: [],
    error: "",
    isLoading: false,
  },
  sidebarProducts: {
    data: [],
    error: "",
  },
  listAnswerComment: {
    data: [],
    error: "",
    isLoading: false,
  },
  parentCatalogue: {
    data: [],
    isLoading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case productConst.MANAGER_DELETE_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        commentProducts: {
          data: state.commentProducts.data.filter((comment) => comment.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.MANAGER_DELETE_SUB_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        commentProducts: {
          data: state.commentProducts.data.filter((comment) => comment.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.DESTROY_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        commentProducts: {
          data: state.commentProducts.data.filter((comment) => comment.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.DESTROY_CHILD_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        listAnswerComment: {
          data: state.listAnswerComment.data.filter((answer) => answer.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.UPDATE_CHILD_COMMENT: {
      return {
        ...state,
        listAnswerComment: {
          data: initialState.listAnswerComment.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case productConst.UPDATE_CHILD_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listAnswerComment: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.MANAGER_UPDATE_CHILD_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listAnswerComment: {
          data: state.listAnswerComment.data.map((answer) => {
            if (answer.id === data.data.id) {
              answer.content = data.data.content;
            }
            return answer;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.UPDATE_CHILD_COMMENT: {
      return {
        ...state,
      };
    }
    case productConst.UPDATE_COMMENT_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentProducts: {
          data: state.commentProducts.data.map((comment) => {
            if (comment.id === data.data.id) {
              const updatedComment = {
                ...comment,
                content: data.data.content,
                comment: {
                  ...comment.comment,
                  rate: data.data.rate,
                },
              };
              return updatedComment;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.MANAGER_UPDATE_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentProducts: {
          data: state.commentProducts.data.map((comment) => {
            if (comment.id === data.data.id) {
              comment.content = data.data.content;
              comment.rate = data.data.rate;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
      };
    }

    case productConst.REPLY_COMMENT_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentProducts: {
          data: state.commentProducts.data.map((comment) => {
            if (comment.id === data.data.comment_id) {
              comment.total_answers = comment.total_answers + 1;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
        listAnswerComment: {
          data: [data.data, ...state.listAnswerComment.data],
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.REPLY_COMMENT_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listAnswerComment: {
          data: initialState.listAnswerComment.data,
          isLoading: false,
          error: error,
        },
      };
    }

    case productConst.FETCH_ANSWER_COMMENT: {
      return {
        ...state,
        listAnswerComment: {
          data: initialState.listAnswerComment.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_ANSWER_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listAnswerComment: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.FETCH_ANSWER_COMMENT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listAnswerComment: {
          data: initialState.listAnswerComment.data,
          isLoading: false,
          error: error,
        },
      };
    }
    case productConst.REPORT_COMMENT_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case productConst.LIKE_COMMENT_PRODUCT_SUCCESS: {
      const { data } = action.payload.data;
      if (data.likeable_type === "Comment") {
        return {
          ...state,
          commentProducts: {
            data: state.commentProducts.data.map((comment) => {
              if (comment.id === data.likeable_id) {
                comment.total_likes = comment.total_likes + 1;
                comment.user_liked = true;
              }
              return comment;
            }),
            isLoading: false,
            error: null,
          },
        };
      } else {
        return {
          ...state,
          listAnswerComment: {
            data: state.listAnswerComment.data.map((answer) => {
              if (answer.id === data.likeable_id) {
                answer.total_likes = answer.total_likes + 1;
                answer.user_liked = true;
              }
              return answer;
            }),
            isLoading: false,
            error: null,
          },
        };
      }
    }
    case productConst.UNLIKE_COMMENT_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      if (data.classable_type === "Comment") {
        return {
          ...state,
          commentProducts: {
            data: state.commentProducts.data.map((comment) => {
              if (comment.id == data.classable_id) {
                comment.total_likes = comment.total_likes - 1;
                comment.user_liked = false;
              }
              return comment;
            }),
            isLoading: false,
            error: null,
          },
        };
      } else {
        return {
          ...state,
          listAnswerComment: {
            data: state.listAnswerComment.data.map((answer) => {
              if (answer.id === data.classable_id) {
                answer.total_likes = answer.total_likes - 1;
                answer.user_liked = false;
              }
              return answer;
            }),
            isLoading: false,
            error: null,
          },
        };
      }
    }
    case productConst.UNLIKE_COMMENT_PRODUCT_FAILED: {
      return { ...state };
    }
    case productConst.LIKE_COMMENT_PRODUCT_FAILED: {
      return { ...state };
    }
    case productConst.FETCH_PRODUCT: {
      return {
        ...state,
        listProducts: {
          data: initialState.listProducts.data,
          isLoading: true,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: checkLoadMore(data),
          error: null,
        },
      };
    }
    case productConst.FETCH_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listProducts: {
          data: initialState.listProducts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_RELATION_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        relationProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_RELATION_PRODUCT_FAILED: {
      const { error } = action.payload;

      toastError(error);
      return {
        ...state,
        relationProducts: {
          data: initialState.relationProducts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_HIGHLIGHT_PRODUCT: {
      return {
        ...state,
        highlightProducts: {
          data: initialState.highlightProducts.data,
          isLoading: true,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_HIGHLIGHT_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        highlightProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_HIGHLIGHT_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        highlightProducts: {
          data: initialState.highlightProducts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case productConst.LOAD_MORE_PRODUCT: {
      return {
        ...state,
        listProducts: {
          data: state.listProducts.data,
          isLoadMore: false,
          isLoading: true,
          error: null,
        },
      };
    }
    case productConst.LOAD_MORE_PRODUCT_SUCCESS: {
      const { data } = action.payload.data;
      const isLoadMore = data.length > 0;
      return {
        ...state,
        listProducts: {
          data: [...state.listProducts.data, ...data],
          isLoadMore: isLoadMore,
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.LOAD_MORE_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listProducts: {
          data: initialState.listProducts.data,
          isLoadMore: false,
          isLoading: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_USER_PRODUCT: {
      return {
        ...state,
        listUserProducts: {
          data: initialState.listUserProducts.data,
          isLoading: true,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_USER_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listUserProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_USER_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listUserProducts: {
          data: initialState.listUserProducts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_PRODUCT_DETAIL_ID: {
      return {
        ...state,
        productDetail: {
          data: initialState.productDetail.data,
          isLoading: true,
          error: "",
        },
      };
    }
    case productConst.FETCH_PRODUCT_DETAIL_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        productDetail: {
          data: data.data,
          isLoading: false,
          error: "",
        },
      };
    }

    case productConst.FETCH_PRODUCT_DETAIL_ID_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case productConst.FETCH_CATALOGUE_OF_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        catalogueOfProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_CATALOGUE_OF_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        catalogueOfProducts: {
          data: initialState.catalogueOfProducts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_CHILD_CATALOGUE_OF_PRODUCT: {
      return {
        ...state,
        childCatalogueOfProduct: {
          ...state.childCatalogueOfProduct,
          data: [],
          isLoading: true,
          entry: initialState.childCatalogueOfProduct.entry,
        },
      };
    }

    case productConst.FETCH_CHILD_CATALOGUE_OF_PRODUCT_SUCCESS: {
      const childCatalogueOfProduct = action.payload;
      return {
        ...state,
        childCatalogueOfProduct: {
          ...state.childCatalogueOfProduct,
          data: childCatalogueOfProduct,
          isLoading: false,
          entry: state.childCatalogueOfProduct.entry,
        },
      };
    }
    case productConst.FETCH_CHILD_CATALOGUE_OF_PRODUCT_FAILED: {
    }
    case productConst.LOAD_MORE_COMMENT_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        commentProducts: {
          data: [...state.commentProducts.data, ...data],
          isLoadMore: data.length < 5 ? false : true,
          isLoading: false,
          error: null,
        },
      };
    }
    case productConst.FETCH_PRODUCT_COMMENT: {
      return {
        ...state,
        commentProducts: {
          data: initialState.commentProducts.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_PRODUCT_COMMENT_SUCCESS: {
      const { data } = action.payload;
      const checkLoadMore = data.data.length < 5 ? false : true;
      return {
        ...state,
        commentProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: checkLoadMore,
          error: null,
        },
      };
    }
    case productConst.FETCH_PRODUCT_COMMENT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        commentProducts: {
          data: initialState.commentProducts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_FAVORITES_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        favoritesProducts: {
          data: data.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case productConst.FETCH_FAVORITES_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case productConst.CREATE_FAVORITE_PRODUCT_SUCCESS: {
      toastSuccess("Lưu sản phẩm thành công");
    }
    case productConst.CREATE_FAVORITE_PRODUCT_FAILED: {
      return { ...state };
    }
    case productConst.FETCH_PRODUCT_BY_USER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listUserProducts: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case productConst.FETCH_PRODUCT_BY_USER_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listProducts: {
          data: initialState.listProducts.data,
          isLoadMore: false,
          isLoading: false,
          error: error,
        },
      };
    }
    case productConst.FETCH_SIDEBAR_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        sidebarProducts: {
          data: data.data,
          error: null,
        },
      };
    }
    case productConst.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        listUserProducts: {
          ...state.listUserProducts,
          data: [action.payload, ...state.listUserProducts.data],
        },
      };
      
    case productConst.CREATE_PRODUCT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {...state}
    };

    case productConst.UPDATE_PRODUCT_SUCCESS:
      const index = state.listUserProducts.data.findIndex((item) => item.id === action.payload.id);
      state.listUserProducts.data[index] = action.payload;
      return {
        ...state,
        listUserProducts: {
          ...state.listUserProducts,
          data: [...state.listUserProducts.data],
        },
      };

    case productConst.DELETE_PRODUCT_SUCCESS:
      const data = state.listUserProducts.data.filter((it) => it.id !== action.payload);
      return {
        ...state,
        listUserProducts: {
          ...state.listUserProducts,
          data: data,
        },
      };
    case productConst.FETCH_PARENT_CATALOG: {
      return {
        ...state,
        parentCatalogue: {
          data: initialState.parentCatalogue.data,
          isLoading: true,
        },
      };
    }
    case productConst.FETCH_PARENT_CATALOG_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        parentCatalogue: {
          ...state.parentCatalogue,
          data: data.data,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
