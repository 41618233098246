import React from "react";
import Modal from "react-bootstrap/Modal";

import ModalDeleteConfirmComment from "components/shared/modalDeleteConfirmComment/modal";
export default function CommentActions(props) {
  const {
    current_user,
    comment,
    LikeComment,
    setIdShowChildComment,
    handleShowInput,
    setTypeInput,
    hideModal,
    showModal,
    isOpenModalDelete,
    setIsOpenModalDelete,
    showReportModal,
    deleteComment,
    submitReport,
    setReportContent,
    reportContent,
    setContent
  } = props;
  return (
    <div className="actions d-flex align-items-center my-2">
      <div className="d-inline-block mr-3">
        <div className={`${comment?.user_liked ? "active" : ""} like d-flex align-items-center`}>
          <div
            className={`hover cmnt d-flex align-items-center`}
            onClick={(x) => LikeComment(comment.classable_id, comment.classable_type, comment.user_liked)}
          >
            <span className="icon-like01 me-1 fs-6"></span>
            {comment.user_liked ? <span>Đã thích &nbsp; </span> : <span>Thích &nbsp; </span>}
            <small>({comment.total_likes === null ? 0 : comment.total_likes})</small>
          </div>
        </div>
      </div>
      <div className="d-inline-block mr-3">
        {comment?.total_answers !== 0 && (
          <div className="d-flex align-items-center cmnt" onClick={() => setIdShowChildComment(comment.id)}>
            <a className="d-flex align-items-center">
              <span className="icon-comment-line"></span>
              <span>Bình luận</span>
              <small>({comment?.total_answers})</small>
            </a>
          </div>
        )}
      </div>
      <div
        className="d-inline-block cmnt"
        onClick={() => {
          handleShowInput(comment.id, comment.user_id);
          setTypeInput("reply");
        }}
      >
        <a>Trả lời</a>
      </div>

      <div className="dropdown post-report ms-auto">
        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span className="icon-menu-dot"></span>
        </button>
        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="true">
          {current_user?.id !== comment.user_id && (
            <li className="dropdown-item">
              <div className="" onClick={() => showReportModal(comment.id, comment.classable_type)}>
                <a>Vi phạm</a>
              </div>
            </li>
          )}
          {current_user?.id == comment.user_id || current_user?.manager_type !== "member" ? (
            <li className="dropdown-item">
              <div
                className=""
                onClick={() => {
                  setContent(comment.content)
                  handleShowInput(comment.id, comment.user_id);
                  setTypeInput("edit");
                }}
              >
                <a>Cập nhật</a>
              </div>
            </li>
          ) : (
            ""
          )}
          {current_user?.id === comment.user_id ||
          current_user?.manager_type == "smod" ||
          current_user?.manager_type == "admin" ? (
            <li className="dropdown-item">
              <div
                className=""
                onClick={() => {
                  setIsOpenModalDelete(comment.id);
                }}
              >
                <a>Xóa</a>
              </div>
            </li>
          ) : (
            ""
          )}
          {isOpenModalDelete && (
            <ModalDeleteConfirmComment
              onDelete={() => deleteComment(comment.id, "comment", current_user?.manager_type)}
              isOpen={isOpenModalDelete}
              onToggle={() => setIsOpenModalDelete(null)}
            />
          )}
        </ul>
      </div>
      <Modal show={showModal} onHide={hideModal} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Báo cáo bình luận vi phạm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            name="message"
            placeholder="Nhập lý do"
            value={reportContent}
            onChange={(e) => setReportContent(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => submitReport(reportContent, comment.id, comment.classable_type)}
          >
            {" "}
            gửi
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
