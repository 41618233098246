import React from "react";
import { Link } from "react-router-dom";

export const SearchResultItem = ({ data }) => {
  return (
    <React.Fragment>
      {data.map((item, index) => {
        return (
          <ListItem items={item.data} title={item.wrap_title} key={index} />
        );
      })}
    </React.Fragment>
  );
};

const ListItem = ({ items, title }) => {
  const objectType = {
    Article: "/tin-tuc/",
    Video: "/videos/",
    Product: "/san-pham/",
    Post: "/p/",
    MarketPrice: "/modules/thong-tin-gia-ca-thi-truong/"
  };

  const subTitle = {
    Article: "Tin tức",
    Video: "Video",
    Product: "Chợ 2 Nông",
    Post: "Diễn đàn nông nghiệp",
    MarketPrice: "Giá cả thị trường"
  }

  const ObjectTypeResult = (type, id) => {
    return `${objectType[type]}${id}`;
  };
  
  return (
    <>
      {items.map((value, index) => (
        <div className="search-result pointer fw-bolder" key={index}>
          <Link to={ObjectTypeResult(value.object_type, value.id)}>
            {subTitle[value.object_type]}: {value.short_search_homepage_title}
          </Link>
        </div>
      ))}
    </>
  );
};
export default SearchResultItem;
