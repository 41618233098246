import React from "react";

const Nodata = ({ loading, error }) => {
  return (
    <React.Fragment>
      {!loading && (
        <div className="py-5 px-3 text-center">
          <div className=" nodata d-flex flex-col items-center justify-center">
            <img
              src="https://help.hainong.vn/images/misc/item-not-found.png"
              alt="not found"
            />
            {error ? (
              <h6 className="fw-bolder">{error}</h6>
            ) : (
              <>
                <h6 className="fw-bolder">Không tìm được kết quả phù hợp</h6>
                <h6 className="fw-bolder">
                  Vui lòng tìm kiếm bằng từ khóa khác
                </h6>
              </>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Nodata;
