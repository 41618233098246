import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TechnicalProcessComment from './comment';
import { FiletypePdf } from 'react-bootstrap-icons';
import ShareSocialGroup from '../../../components/ShareSocialGroup/ShareSocialGroup';
import { userShareArticle } from '../../../redux/actions/post';
import { toastErrorLogin } from 'helpers/toastHelper';
function RenderTechnicalProcess(props) {
  const dispatch = useDispatch();
  const { stepId, data, current_user } = props;
  const [showMore, setShowMore] = useState(false);
  const [isShowModalShare, setIsShowModalShare] = useState(null);
  useEffect(() => {
    setShowMore(false);
  }, [data]);

  const handleShowMore = () => {
    setShowMore(true);
  };
  const shareMarketPriceToHomePage = () => {
    if (!current_user) {
      toastErrorLogin();
    } else {
      let link = window.location.href;
      dispatch(
        userShareArticle({
          title: link,
          post_type: 'public',
          user_shared_id: current_user?.id,
          shared_link: link,
          shared_link_status: 200,
        }),
      );
      setIsShowModalShare(null);
    }
  };

  return (
    stepId == data?.technical_process_catalogue_id && (
      <>
        <div className={`cntry-news ${!showMore && 'tech-process-render'} `}>
          <h5 className="mt-3 font-weight-semibold">{data.title}</h5>
          <div dangerouslySetInnerHTML={{ __html: data.content }} className="mt-3 tech-proc-content "></div>
          {data.pdf_file && (
            <div className="fs-6 ">
              <FiletypePdf size={18} />
              <a className="green-clr fs-7" href={data.pdf_file}>
                Link PDF &nbsp;
              </a>
            </div>
          )}
        </div>
        {showMore == false && (
          <button className="more-btn-fll text-center d-block mt-1 mb-3" onClick={() => handleShowMore()}>
            <u>Xem thêm</u>
          </button>
        )}
        <div className="d-flex justify-content-end">
          <ShareSocialGroup
            shareMarketPriceToHomePage={shareMarketPriceToHomePage}
            setMarketPlaceId={setIsShowModalShare}
            marketPlaceId={isShowModalShare}
            market_place={data}
            share2Nong={true}
          />
        </div>
        <div className="cmt-box mt-2">
          <TechnicalProcessComment id={data.id} technicalProcessItem={data} />
        </div>
      </>
    )
  );
}

export default RenderTechnicalProcess;
