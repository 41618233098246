import React, { useEffect, useContext, useState } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { useDispatch, useSelector } from "react-redux";
import ButtonAddTraceability from "../../../components/ButtonAddTraceability";
import TraceabilityItem from "./partials/traceability";
import InputField from "./forms/inputField";
import { PAGE } from "constants";
import { fetchTraceability, deleteTraceability } from "redux/actions/traceability";
import AppContext from "components/app_context";
import { useNavigate } from "react-router-dom";

function ListTraceabilites(props) {
  const dispatch = useDispatch();
  const listTraceabilites = useSelector((state) => state.traceability.listTraceabilities);
  const data = listTraceabilites.data;
  const { current_user, setUser } = useContext(AppContext);
  let [page, setPage] = useState(PAGE);
  let [keyword, setKeyword] = useState("");
  let [limit, setLimit] = useState(24);
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  useEffect(() => {
    dispatch(
      fetchTraceability({
        id: current_user?.id,
        keyword: keyword,
        page: page,
        limit: limit,
      })
    );
  }, [keyword, page, limit]);

  const userdeleteTraceability = (id) => {
    dispatch(deleteTraceability(id));
    setIsOpen(false);
  };

  return (
    <>
      <AddMetaTags title={"Truy xuất nguồn gốc"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container traceability-home">
              <div className="">
                <h5 className="d-flex align-items-center">
                  <button
                    className="border-0 bg-white blue-clr fs-3"
                    onClick={handleClick}
                    type="button">
                    &lsaquo;
                  </button>
                  <span className="blue-clr mt-1">Danh sách truy xuất</span>
                </h5>
              </div>
              <InputField setKeyword={setKeyword} />
              <TraceabilityItem
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                data={data}
                userdeleteTraceability={userdeleteTraceability}
              />
              <ButtonAddTraceability />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListTraceabilites;
