import * as diagnosticConst from 'constants/diagnostic'

const initialState = {
  listDiagnostics: {
    data: [],
    error: null,
    isLoading: false,
  },
  diagnosticDetail: {
    data: null,
    isLoading: false,
    error: null
  }
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case diagnosticConst.FETCH_DIAGNOSTICS: {
      return {
        ...state,
        listDiagnostics: {
          data: initialState.listDiagnostics.data,
          isLoading: true
        }
      };
    }
    case diagnosticConst.FETCH_DIAGNOSTICS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listDiagnostics:{
          data: data.data,
          error: null,
          isLoading: false
        }
      }
    }

    case diagnosticConst.FETCH_DIAGNOSTICS_DETAILS: {
      return {
        ...state,
        diagnosticDetail: {
          data: initialState.diagnosticDetail.data,
          isLoading: true,
          error: null,
        }
      }
    }

    case diagnosticConst.FETCH_DIAGNOSTICS_DETAILS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        diagnosticDetail: {
          data: data.data,
          isLoading: false,
          error: null,
        }
      }
    }
    
    default:
      return state;
  }

}

export default reducer
