import React, { useEffect, useState, useContext } from 'react';
import AppContext from 'components/app_context';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import parse from 'html-react-parser';
import AudioPlayer from 'react-h5-audio-player';
import SharingArea from '../articleDetail/components/SharingArea';
import ShareSocialGroup from '../../components/ShareSocialGroup';
import Editor from '../../components/simple_editor';
import Comment from '../articleDetail/components/Comment';
import SubComment from '../articleDetail/components/subComment';
import RelativeArticles from '../articleDetail/components/RelativeArticles.jsx';
import ScrollToTop from '../../components/ScrollToTop';
import AddMetaTags from '../../components/add_meta_tags';
import LeftSidebar from '../../components/left_sidebar';
import RightSidebar from '../../components/right_sidebar';
import { getShortVideoDetail } from '../../apis/video';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { validateStringEditor } from '../../lib/index.js';
import { toastError, toastErrorLogin } from 'helpers/toastHelper';
import Loader from 'components/loader';
import {
  fetchRelationArticle,
  createCommentArticle,
  fetchCommentArticle,
  createLikeCommentArticle,
  managerUpdateComment,
  updateComment,
  reportCommentArticle,
  managerDestroyComment,
  managerDestroyChildComment,
  userDestroyComment,
  userDestroyChildComment,
  createUnLikeCommentArticle,
  updateChildComment,
  managerUpdateChildComment,
  userReplyComment,
} from '../../redux/actions/article';
import { getRelationShortVideo } from '../../apis/article.js';

const VideoDetail = () => {
  const { current_user, isMobile } = useContext(AppContext);
  const dispatch = useDispatch();
  const { shortVideoId } = useParams();
  const [shortVideoDetail, setShortVideoDetail] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [innerString, setInnerString] = useState('');
  const [contentRoot, setContentRoot] = useState('');
  const [activeSort, setActiveSort] = useState('liked');

  const [numOfComment, setNumOfComment] = useState(5);
  const [content, setContent] = useState('');
  const [loadMore, setLoadMore] = useState(false);
  const [idShowChildComment, setIdShowChildComment] = useState(null);
  const [showInputBox, setShowInputBox] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [reportType, setReportType] = useState('');
  const [reportContent, setReportContent] = useState('');

  const [relationShortVideo, setRelationShortVideo] = useState([]);

  const { data: comments, isLoading } = useSelector(state => state.article.commentArticles);
  const { data: replyComment } = useSelector(state => state.article.replyCommentArticle);

  useEffect(() => {
    getShortVideoDetail(shortVideoId)
      .then(res => {
        setShortVideoDetail(res.data.data);
        getRelationShortVideo({ id: res.data.data.id, limit: 20 }).then(response => {
          setRelationShortVideo(response.data.data);
        });
        dispatch(fetchCommentArticle({ classable_id: res.data.data?.id, classable_type: 'ShortVideo' }));
      })
      .catch(error => {
        console.error('Failed to fetch short video detail:', error);
      });
  }, [dispatch, shortVideoId]);

  const renderMediaByType = () => {
    const media = shortVideoDetail?.media_type;
    switch (media) {
      case 'file':
        return <ReactPlayer url={shortVideoDetail?.video_url} controls={true} width={"100%"} />;
      case 'youtube':
        return <ReactPlayer url={shortVideoDetail?.video_url} controls={true} width={"100%"} />;
      case 'link':
        return <ReactPlayer url={shortVideoDetail?.video_url} controls={true} width={"100%"} />;
      default:
        return null;
    }
  };

  const sortComments = (id, type) => {
    if (type === 'liked') {
      setActiveSort(type);
      dispatch(
        fetchCommentArticle({
          classable_id: id,
          classable_type: shortVideoDetail?.classable_type,
          sort: 'total_likes',
        }),
      );
    } else {
      setActiveSort(type);
      dispatch(
        fetchCommentArticle({
          classable_id: id,
          classable_type: shortVideoDetail?.classable_type,
          sort: 'created_at',
        }),
      );
    }
  };

  const hideModal = () => setShowModal(false);

  const handleLoadMore = () => {
    setNumOfComment(num => num + 7);
    setLoadMore(true);
  };
  const actionLikeComment = (id, liked, type) => {
    if (current_user) {
      if (!liked) {
        dispatch(
          createLikeCommentArticle({
            classable_id: id,
            classable_type: type,
            id: shortVideoDetail?.id,
            type: shortVideoDetail?.classable_type,
          }),
        );
      } else {
        dispatch(
          createUnLikeCommentArticle({
            classable_id: id,
            classable_type: type,
            id: shortVideoDetail?.id,
            type: shortVideoDetail?.classable_type,
          }),
        );
      }
    } else toastErrorLogin(isMobile);
  };

  const handleShowReplyBox = (id, replier) => {
    if (current_user) {
      setShowInputBox({
        id,
        replier,
      });
    } else toastErrorLogin(isMobile);
  };

  const showReportModal = (comment_id, comment_type) => {
    if (current_user) {
      setReportId(comment_id);
      setReportType(comment_type);
      setShowModal(true);
    } else toastErrorLogin(isMobile);
  };
  const submitComment = () => {
    if (!current_user) {
      return toastErrorLogin(isMobile);
    }
    const validation = validateStringEditor(contentRoot);
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        dispatch(
          createCommentArticle({
            commentable_id: shortVideoDetail.id,
            commentable_type: 'ShortVideo',
            content: contentRoot,
          }),
        );
        clearEditorState();
    }
  };

  const submitReplyComment = (id, content, type) => {
    const validation = validateStringEditor(content);
    const commentData = {
      id: id,
      content: content,
    };
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        if (type === 'reply') {
          dispatch(userReplyComment(commentData));
        } else {
          if (current_user.manager_type !== 'member') {
            dispatch(managerUpdateComment(commentData));
          } else {
            dispatch(updateComment(commentData));
          }
        }
        setContent('');
        setShowInputBox(false);
    }
  };

  const submitReport = reason => {
    if (reason === '') {
      toastError('Vui lòng nhập lý do báo cáo');
    } else {
      dispatch(
        reportCommentArticle({
          reason: reason,
          classable_id: reportId,
          classable_type: reportType,
        }),
      );
      setShowModal(false);
      setReportContent('');
    }
  };

  const deleteComment = (id, type, role) => {
    if (type === 'comment') {
      if (role === 'admin' || role === 'smod') {
        dispatch(managerDestroyComment({ id: id }));
      } else {
        dispatch(userDestroyComment({ id: id }));
      }
    } else {
      if (role === 'admin' || role === 'smod') {
        dispatch(managerDestroyChildComment({ id: id }));
      } else {
        dispatch(userDestroyChildComment({ id: id }));
      }
    }
  };
  const clearEditorState = () => {
    $('#editor').html('');
    setContentRoot('');
    setShowPicker(false);
    setInnerString('');
  };

  return (
    <>
      <ScrollToTop>
        <div className="detail-article">
          <AddMetaTags title={shortVideoDetail?.title} />
          <div className="row">
            <LeftSidebar />
            <div className="col-md-6 col-sm-12">
              <div className="cont-center">
                <div className="cntry-news">
                  <div className="d-flex justify-content-between">
                    {shortVideoDetail && (
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/videos">Videos</Link>
                          </li>
                          <li className="breadcrumb-item active d-flex align-items-center" aria-current="page">
                            <span className="icon-arrow-black"></span>
                            <span>{shortVideoDetail?.short_video_catalogue_name}</span>
                          </li>
                        </ol>
                      </nav>
                    )}
                    <small className="grey-light-clr fst-italic cap articles_time d-block">
                      {moment(shortVideoDetail?.created_at).format('DD/MM/YYYY ')}
                    </small>
                  </div>
                  <h3 className="mt-3">{shortVideoDetail && parse(shortVideoDetail.title)}</h3>
                  <div className="mt-3">{renderMediaByType()}</div>
                </div>
                <div className="mt-3">
                  <div className="cmt-box">
                    <Loader isLoading={isLoading} />
                    <div className="inpt-cntn cmnt-box position-relative">
                      <div className="editor">
                        <Editor
                          component="div"
                          setShowPicker={setShowPicker}
                          showPicker={showPicker}
                          onChange={setContentRoot}
                          value={contentRoot}
                          createComment={submitComment}
                          setInnerString={setInnerString}
                          innerString={innerString}
                          placeholder="Bình luận của bạn"
                        />
                      </div>
                    </div>
                    {shortVideoDetail && comments.length > 0 && (
                      <div className="actions-sort-cmt d-flex mt-3">
                        <ul>
                          <li
                            className={`${activeSort === 'liked' && 'active-sort'} action-sort-like hover`}
                            onClick={() => sortComments(shortVideoDetail.id, 'liked')}
                          >
                            Yêu thích nhất
                          </li>
                          <li
                            className={`${activeSort === 'created' && 'active-sort'} action-sort-new hover`}
                            onClick={() => sortComments(shortVideoDetail.id, 'created')}
                          >
                            Bình luận mới nhất
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className="cmt-listing">
                      {shortVideoDetail &&
                        comments.slice(0, numOfComment).map(function (comment, index) {
                          return (
                            <div key={index}>
                              <Comment
                                comment={comment}
                                current_user={current_user}
                                actionLikeComment={actionLikeComment}
                                setIdShowChildComment={setIdShowChildComment}
                                handleShowReplyBox={handleShowReplyBox}
                                showReportModal={showReportModal}
                                handleLoadMore={handleLoadMore}
                                setContent={setContent}
                                content={content}
                                hideModal={hideModal}
                                showModal={showModal}
                                reportContent={reportContent}
                                setReportContent={setReportContent}
                                submitReplyComment={submitReplyComment}
                                idShowChildComment={idShowChildComment}
                                showInputBox={showInputBox}
                                submitReport={submitReport}
                                deleteComment={deleteComment}
                              >
                                <SubComment
                                  id={comment.id}
                                  current_user={current_user}
                                  comment={comment}
                                  actionLikeComment={actionLikeComment}
                                  replyComment={replyComment}
                                  handleShowReplyBox={handleShowReplyBox}
                                  submitReport={submitReport}
                                  setShowInputBox={setShowInputBox}
                                  showReportModal={showReportModal}
                                  hideModal={hideModal}
                                  showModal={showModal}
                                  showInputBox={showInputBox}
                                  updateChildComment={updateChildComment}
                                  managerUpdateChildComment={managerUpdateChildComment}
                                  reportContent={reportContent}
                                  setReportContent={setReportContent}
                                />
                              </Comment>
                            </div>
                          );
                        })}
                    </div>
                    {!loadMore && comments.length > 5 && numOfComment <= comments.length && (
                      <span className="load-more-comment-post" onClick={() => handleLoadMore()}>
                        Xem thêm bình luận
                      </span>
                    )}
                  </div>
                  <RelativeArticles isVideoPage={true} data={relationShortVideo} />
                </div>
              </div>
            </div>
            <RightSidebar />
          </div>
        </div>
      </ScrollToTop>
    </>
  );
};

export default VideoDetail;
