export const FETCH_POST_DETAIL = "FETCH_POST_DETAIL";
export const FETCH_POST_DETAIL_SUCCESS = "FETCH_POST_DETAIL_SUCCESS";
export const FETCH_POST_DETAIL_FAILED = "FETCH_POST_DETAIL_FAILED";

export const LIKE_POST_DETAIL = "LIKE_POST_DETAIL";
export const LIKE_POST_DETAIL_SUCCESS = "LIKE_POST_DETAIL_SUCCESS";

export const FETCH_COMMENT_POST_DETAIL = "FETCH_COMMENT_POST_DETAIL";
export const FETCH_COMMENT_POST_DETAIL_SUCCESS = "FETCH_COMMENT_POST_DETAIL_SUCCESS";
export const FETCH_COMMENT_POST_DETAIL_FAIL = "FETCH_COMMENT_POST_DETAIL_FAIL";

export const LIKE_COMMENT_POST_DETAIL = "LIKE_COMMENT_POST_DETAIL"
export const LIKE_COMMENT_POST_DETAIL_SUCCESS = "LIKE_COMMENT_POST_DETAIL_SUCCESS"

export const FETCH_SUBCOMMENT_POST_DETAIL = "FETCH_SUBCOMMENT_POST_DETAIL"
export const FETCH_SUBCOMMENT_POST_DETAIL_SUCCESS = "FETCH_SUBCOMMENT_POST_DETAIL_SUCCESS"

export const COMMENT_POST_DETAIL = "COMMENT_POST_DETAIL"
export const COMMENT_POST_DETAIL_SUCCESS = "COMMENT_POST_DETAIL_SUCCESS"

export const REPLY_COMMENT_POST_DETAIL = "REPLY_COMMENT_POST_DETAIL"

export const UPDATE_COMMENT_WITH_PATTERN = "UPDATE_COMMENT_WITH_PATTERN"
export const MODIFY_REPLY_DATA = "MODIFY_REPLY_DATA"

export const MODIFY_EDIT_DATA = "MODIFY_EDIT_DATA";

export const EDIT_COMMENT_POST_DETAIL = "EDIT_COMMENT_POST_DETAIL";
export const EDIT_COMMENT_POST_DETAIL_SUCCESS = "EDIT_COMMENT_POST_DETAIL_SUCCESS";

export const DELETE_COMMENT_POST_DETAIL = "DELETE_COMMENT_POST_DETAIL";
export const DELETE_COMMENT_POST_DETAIL_SUCCESS = "DELETE_COMMENT_POST_DETAIL_SUCCESS";

export const DELETE_SUBCOMMENT_POST_DETAIL = "DELETE_SUBCOMMENT_POST_DETAIL"
export const DELETE_SUBCOMMENT_POST_DETAIL_SUCCESS = "DELETE_SUBCOMMENT_POST_DETAIL_SUCCESS"

export const EDIT_SUBCOMMENT_POST_DETAIL = "EDIT_SUBCOMMENT_POST_DETAIL"
export const EDIT_SUBCOMMENT_POST_DETAIL_SUCCESS = "EDIT_SUBCOMMENT_POST_DETAIL_SUCCESS"

export const FAST_FOLLOW_USER_IN_POST_DETAIL = "FAST_FOLLOW_USER_IN_POST_DETAIL";
export const FAST_FOLLOW_USER_IN_POST_DETAIL_SUCCESS = "FAST_FOLLOW_USER_IN_POST_DETAIL_SUCCESS";

export const FAST_UNFOLLOW_USER_IN_POST_DETAIL = "FAST_UNFOLLOW_USER_IN_POST_DETAIL";
export const FAST_UNFOLLOW_USER_IN_POST_DETAIL_SUCCESS = "FAST_UNFOLLOW_USER_IN_POST_DETAIL_SUCCESS";