export const MANAGER_UPDATE_COMMENT = 'MANAGER_UPDATE_COMMENT';
export const MANAGER_UPDATE_COMMENT_SUCCESS = 'MANAGER_UPDATE_COMMENT_SUCCESS';
export const MANAGER_UPDATE_COMMENT_FAILED = 'MANAGER_UPDATE_COMMENT_FAILED';

export const MANAGER_UPDATE_ANSWER_COMMENT = 'MANAGER_UPDATE_ANSWER_COMMENT';
export const MANAGER_UPDATE_ANSWER_COMMENT_SUCCESS = 'MANAGER_UPDATE_ANSWER_COMMENT_SUCCESS';
export const MANAGER_UPDATE_ANSWER_COMMENT_FAILED = 'MANAGER_UPDATE_ANSWER_COMMENT_FAILED';

export const MANAGER_DELETE_POST = 'MANAGER_DELETE_POST';
export const MANAGER_DELETE_POST_SUCCESS = 'MANAGER_DELETE_POST_SUCCESS';
export const MANAGER_DELETE_POST_FAILED = 'MANAGER_DELETE_POST_FAILED';

export const MANAGER_UPDATE_POST = 'MANAGER_UPDATE_POST';
export const MANAGER_UPDATE_POST_SUCCESS = 'MANAGER_UPDATE_POST_SUCCESS';
export const MANAGER_UPDATE_POST_FAILED = 'MANAGER_UPDATE_POST_FAILED';

export const FETCH_WARNING_COMMENT = 'FETCH_WARNING_COMMENT';
export const FETCH_WARNING_COMMENT_SUCCESS = 'FETCH_WARNING_COMMENT_SUCCESS';
export const FETCH_WARNING_COMMENT_FAILED = 'FETCH_WARNING_COMMENT_FAILED';

export const DELETE_WARNING_COMMENT = 'DELETE_WARNING_COMMENT';
export const DELETE_WARNING_COMMENT_SUCCESS = 'DELETE_WARNING_COMMENT_SUCCESS';
export const DELETE_WARNING_COMMENT_FAILED = 'DELETE_WARNING_COMMENT_FAILED';

export const MANAGER_DELETE_COMMENT_POST_DETAIL = 'MANAGER_DELETE_COMMENT_POST_DETAIL';
export const MANAGER_DELETE_COMMENT_POST_DETAIL_SUCCESS = 'MANAGER_DELETE_COMMENT_POST_DETAIL_SUCCESS';
export const MANAGER_DELETE_COMMENT_POST_DETAIL_FAILED = 'MANAGER_DELETE_COMMENT_POST_DETAIL_FAILED';

export const MANAGE_CONTRIBUTE_TECHNICAL_PROCESS = "MANAGE_CONTRIBUTE_TECHNICAL_PROCESS";
export const MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS = "MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS";
export const MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED = "MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED";

export const MANAGE_CONTRIBUTE_PESTS = "MANAGE_CONTRIBUTE_PESTS";
export const MANAGE_CONTRIBUTE_PESTS_SUCCESS = "MANAGE_CONTRIBUTE_PESTS_SUCCESS";
export const MANAGE_CONTRIBUTE_PESTS_FAILED = "MANAGE_CONTRIBUTE_PESTS_FAILED";

export const MANAGE_DELETE_CONTRIBUTE_PESTS = "MANAGE_DELETE_CONTRIBUTE_PESTS";
export const MANAGE_DELETE_CONTRIBUTE_PESTS_SUCCESS = "MANAGE_DELETE_CONTRIBUTE_PESTS_SUCCESS";
export const MANAGE_DELETE_CONTRIBUTE_PESTS_FAILED = "MANAGE_DELETE_CONTRIBUTE_PESTS_FAILED";

export const MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS = "MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS";
export const MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS = "MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS";
export const MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED = "MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED";

export const FETCH_TECHNICAL_PROCESS_FOR_APPROVE = "FETCH_TECHNICAL_PROCESS_FOR_APPROVE";
export const FETCH_TECHNICAL_PROCESS_FOR_APPROVE_SUCCESS = "FETCH_TECHNICAL_PROCESS_FOR_APPROVE_SUCCESS";
export const FETCH_TECHNICAL_PROCESS_FOR_APPROVE_FAILED = "FETCH_TECHNICAL_PROCESS_FOR_APPROVE_FAILED";

export const FETCH_CONTRIBUTE_PESTS = "FETCH_CONTRIBUTE_PESTS";
export const FETCH_CONTRIBUTE_PESTS_SUCCESS = "FETCH_CONTRIBUTE_PESTS_SUCCESS";
export const FETCH_CONTRIBUTE_PESTS_FAILED = "FETCH_CONTRIBUTE_PESTS_FAILED";

export const FETCH_CONTRIBUTE_MARKET_PRICE = "FETCH_CONTRIBUTE_MARKET_PRICE";
export const FETCH_CONTRIBUTE_MARKET_PRICE_SUCCESS = "FETCH_CONTRIBUTE_MARKET_PRICE_SUCCESS";
export const FETCH_CONTRIBUTE_MARKET_PRICE_FAILED = "FETCH_CONTRIBUTE_MARKET_PRICE_FAILED";

export const MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE = "MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE";
export const MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_SUCCESS = "MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_SUCCESS";
export const MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_FAILED = "MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_FAILED";

export const MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE = "MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE";
export const MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_SUCCESS = "MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_SUCCESS";
export const MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_FAILED = "MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_FAILED";
