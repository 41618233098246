import axiosService from 'services/axiosService';
import qs from 'query-string';
import { DOMAIN } from 'constants';
import { get_user } from 'lib';

const home_url = 'api/v2/home/search';

export const searchHome = params => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.get(`${DOMAIN}/${home_url}${query}`, isAuth);
};
