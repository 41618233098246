import React from "react";
import ButtonShareFacebook from "../ButtonShareFacebook";
import ButtonShareZalo from "../ButtonShareZalo";
import CopyUrl from "../CopyURL/CopyUrl";
import ModalShareConfirm from "../shared/modalShareConfirm/modal";
export default function ShareSocialGroup(props) {
  const { shareMarketPriceToHomePage, setMarketPlaceId, marketPlaceId, market_place, share2Nong } = props;
  const link = window.location.href;
  return (
    <div className="dropdown post-report">
      <button
        className="btn dropdown-toggle px-3"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ backgroundColor: "#009a59", color: "#fff", height: "100%" }}
      >
        <span className="icon-share01" style={{ fontSize: "20px", content: "" }}></span>
      </button>
      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="true" style={{ width: "12rem" }}>
        <li className="dropdown-item">
          <div style={{ height: "32", width: "32", cursor: "pointer" }} className="mr-2">
            <ButtonShareFacebook>
              <span className="ml-2" style={{ fontSize: "16px", color: "#000" }}>
                Chia sẻ Facebook
              </span>
            </ButtonShareFacebook>
          </div>
        </li>

        <li className="dropdown-item">
          <div style={{ height: "32", width: "32", cursor: "pointer" }} className="mr-2">
            <ButtonShareZalo>
              <span className="ml-2" style={{ fontSize: "16px", color: "#000" }}>
                Chia sẻ Zalo{" "}
              </span>
            </ButtonShareZalo>
          </div>
        </li>
        {share2Nong && (
          <li className="dropdown-item">
            <div className="mr-2 hainong-icon">
              <a role="button" onClick={() => setMarketPlaceId(market_place.id)}>
                <img
                  src="/images/logo_2nong.png"
                  alt="2Nong-share"
                  style={{ height: "32", width: "32", cursor: "pointer", backgroundColor: "#009a59" }}
                />
                <span className="ml-2" style={{ fontSize: "16px", color: "#000" }}>
                  Chia sẻ diễn đàn{" "}
                </span>
              </a>
            </div>{" "}
            <ModalShareConfirm
              onShare={() => shareMarketPriceToHomePage()}
              isOpen={marketPlaceId}
              onToggle={() => setMarketPlaceId(null)}
            />
          </li>
        )}
        <li className="dropdown-item">
          <CopyUrl url={link} />
        </li>
      </ul>
    </div>
  );
}
