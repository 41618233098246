import React from "react";
import Modal from "react-bootstrap/Modal";
function ModalReport({ showModal, hideModal, reportContent, setReportContent, submitReport }) {
  return (
    <>
      <Modal
        show={showModal}
        onHide={hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Báo cáo bình luận vi phạm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            name="message"
            placeholder="Nhập lý do"
            value={reportContent}
            onChange={(e) => setReportContent(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => submitReport()}>
            gửi
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ModalReport;
