import React, { useEffect, useState} from "react";
import ListFertilizer from "./listFertilizer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as fertilizerActions from "actions/fertilizer";
import { mixNPK, changeNPK} from "apis/fertilizer";
import { Collapse } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

function TabChangeNPK(props) {
  //ref
  
  let [n, setN] = useState("");
  let [p, setP] = useState("");
  let [k, setK] = useState("");
  let [results, setResult] = useState(null);
  let [show, setShow] = useState(false);
  // action
  const { fertilizerActions } = props;
  const { fetchFertilizer } = fertilizerActions; // end action
  //reducer

  let { fertilizer_list } = props;
  let { data } = fertilizer_list; //end reducer

  const validateSchema = Yup.object().shape({
    weight: Yup.number().required(),
    weight_change: Yup.number().required(),
    n_rate: Yup.number().required(),
    p_rate: Yup.number().required(),
    k_rate: Yup.number().required(),
    n_rate_change: Yup.number().required(),
    p_rate_change: Yup.number().required(),
    k_rate_change: Yup.number().required(),
  })
  // dispatch action
 const {errors, isSubmitting, touched, handleSubmit, handleChange, setSubmitting} = useFormik({
  initialValues: {
    weight: '',
    weight_change: '',
    n_rate: '',
    p_rate: '',
    k_rate: '',
    n_rate_change: '',
    p_rate_change: '',
    k_rate_change: '',
  },
  validationSchema: validateSchema ,
  onSubmit: async (values) => {
    if(n && p && k) {
      if(!show){
        setSubmitting(true);
        changeNPK({
          current_weight: values.weight,
          "npk_manures[]": [n,p,k],
          n_rate_current: values.n_rate,
          p_rate_current: values.p_rate,
          k_rate_current: values.k_rate,
          n_rate_change: values.n_rate_change,
          p_rate_change: values.p_rate_change,
          k_rate_change: values.k_rate_change,
          desired_weight: values.weight_change
        }).then((response) => {
          setShow(true);
          setSubmitting(false);
          setResult(response.data.data);
        })
      }else{
        setShow(false)
      }
      
    }else{
      alert("vui lòng chọn đủ loại phân ")
    }
  }
 })
  useEffect(() => {
    fetchFertilizer();
  }, [fetchFertilizer]);

  let getFertilizerIds = () => {
    if ($(".nitrogen:checked").length > 0) {
      var list = $(".nitrogen");
      var checked = $(".nitrogen:checked");
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
      setN($(".nitrogen:checked").val());
    } else {
      $(".nitrogen:disabled").removeAttr("disabled");
      setN(null);
    }

    if (
      $(".phosphor:checked").length > 0 ||
      $(".phosphor_mix:checked").length > 0
    ) {
      var list = [];
      var checked = [];

      if ($(".phosphor_mix:checked").length > 0) {
        list = $(".phosphor_mix, .phosphor");
        checked = $(".phosphor_mix:checked");
        setP($(".phosphor_mix:checked").val());
      }
      if ($(".phosphor:checked").length > 0) {
        list = $(".phosphor_mix, .phosphor");
        checked = $(".phosphor:checked");
        setP($(".phosphor:checked").val());
      }

      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
    } else {
      $(".phosphor:disabled").removeAttr("disabled");
      $(".phosphor_mix:disabled").removeAttr("disabled");
      setP(null);
    }

    if ($(".kali:checked").length > 0) {
      var list = $(".kali");
      var checked = $(".kali:checked");
      for (var i = 0; i < list.length; i++) {
        list[i].disabled = "disabled";
        checked.removeAttr("disabled");
      }
      setK($(".kali:checked").val());
    } else {
      $(".kali:disabled").removeAttr("disabled");
      setK(null);
    }
  };

  const detectFertilizer = (value) => {
    if (value == "nitrogen") {
      return "N";
    } else if (value == "phosphor") {
      return "P";
    } else if (value == "kali") {
      return "K";
    } else {
      return "P[P/N]";
    }
  };
  // end function

  return (
    <>
      <h2>Trộn NPK từ loại này sang loại khác</h2>
      <p>
      Trộn phân NPK có tỉ lệ này sang tỉ lệ khác bằng cách thêm/bớt khối lượng cho từng loại phân N, P, K phù hợp. Ví dụ: Nông dân đang có 100kg phân NPK được trộn từ phân Ure (N), phân Kali Clorua (K) và phân Lân nung chảy (P) theo tỉ lệ là 2:2:1, sau đó, người này muốn bón 150kg phân NPK cũng được trộn từ 3 loại phân trên theo tỉ lệ 2:1:1 thì công thức này sẽ giúp họ biết được cần phải thêm/bớt khối lượng của từng loại phân Ure, Kali Clorua, Lân nung chảy là bao nhiêu cho phù hợp
      </p>
      <form className="npk-frm" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-sm-12 kg-unit">
            <label>Khối lượng phân bón NPK đang có (KG)</label>
            <input
              className={`form-control mb-2 ${errors.weight && touched.weight && 'border border-danger'}`}
              type="text"
              name="weight"
              onChange={handleChange}
            />
            <span className="">KG</span>
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Tỉ lệ phân bón đang có</label>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <input
              className={`form-control mb-2 ${errors.n_rate && touched.n_rate && 'border border-danger'}`}
              type="text"
                  placeholder="N"
                  name="n_rate"
                  onChange={handleChange}
                  />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
              className={`form-control mb-2 ${errors.p_rate && touched.p_rate && 'border border-danger'}`}
              type="text"
                  placeholder="P"
                  name="p_rate"
                  onChange={handleChange}
                  />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
              className={`form-control mb-2 ${errors.p_rate && touched.p_rate && 'border border-danger'}`}
              type="text"
                  placeholder="K"
                  name="k_rate"
                  onChange={handleChange}
                  />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 kg-unit">
            <label>Khối luợng phân bón NPK mong muốn (KG)</label>
            <input
              className={`form-control mb-2 ${errors.weight_change && touched.weight_change && 'border border-danger'}`}
              type="text"
              name="weight_change"
              onChange={handleChange}
            />
            <span className="">KG</span>
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Tỉ lệ phân bón mong muốn</label>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <input
              className={`form-control mb-2 ${errors.n_rate_change && touched.n_rate_change && 'border border-danger'}`}
              type="text"
                  placeholder="N"
                  name="n_rate_change"
                  onChange={handleChange}
                  />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
              className={`form-control mb-2 ${errors.p_rate_change && touched.p_rate_change && 'border border-danger'}`}
              type="text"
                  placeholder="P"
                  name="p_rate_change"
                  onChange={handleChange}
                  />
              </div>
              <div className="col-md-4 col-sm-12">
                <input
              className={`form-control mb-2 ${errors.k_rate_change && touched.k_rate_change && 'border border-danger'}`}
              type="text"
                  placeholder="K"
                  name="k_rate_change"
                  onChange={handleChange}
                  />
              </div>
            </div>
          </div>
        </div>
        <ListFertilizer
          data={data}
          detectFertilizer={detectFertilizer}
          getFertilizerIds={getFertilizerIds}
        />
        <div className="accordion" id="accordionResult">
          <div className="accordion-item bg-transparent">
            <h2
              className="accordion-header d-flex justify-content-center"
              id="headingResult"
            >
               <button
                  className="collapsed"
                  type="submit"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  disabled={isSubmitting}
                >
                  Xác định Kết Quả
                </button>
            </h2>
            <Collapse in={show}>
              <div className="accordion-body">
                <p>Khối lượng phân đang có</p>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-uppercase">
                        Phân bón
                      </th>
                      <th scope="col" className="text-uppercase">
                        Trọng lượng
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {results?.npk_current_values?.map(function (result, index) {
                      return (
                        <tr key={index}>
                          <th scope="row">{result?.name}</th>
                          <td>{result?.value} KG</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <p>Khối lượng phân mong muốn</p>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-uppercase">
                        Phân bón
                      </th>
                      <th scope="col" className="text-uppercase">
                        Trọng lượng
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {results?.npk_change_values?.map(function (result, index) {
                      return (
                        <tr key={index}>
                          <th scope="row">{result?.name}</th>
                          <td>{result?.value} KG</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <p>Khối lượng thêm/bớt từng loại phân từng loại phân N/P/K để ra phân NPK mong muốn</p>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-uppercase">
                        Phân bón
                      </th>
                      <th scope="col" className="text-uppercase">
                        kết quả
                      </th>
                      <th scope="col" className="text-uppercase">
                        Trọng lượng
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {results?.result?.map(function (result, index) {
                      return (
                        <tr key={index}>
                          <th scope="row">{result?.name}</th>
                          <td>{result?.result}</td>
                          <td>{result?.value} KG</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

            </Collapse>
          </div>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    fertilizer_list: state.fertilizer.list_fertilizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fertilizerActions: bindActionCreators(fertilizerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabChangeNPK);
