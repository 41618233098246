import { call, put } from "redux-saga/effects";
import { STATUS_CODE } from "constants";
import { readVoice } from "apis/tts";

import {fetchVoiceSuccess, fetchVoiceFailed} from "actions/tts";

export function* userFetchVoice({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(readVoice, params);
    const { data, status } = response;
    const {success} = data;
    if (status === STATUS_CODE.SUCCESS && success) {
      yield put(fetchVoiceSuccess(data));
    } else {
      yield put(fetchVoiceFailed(data));
    }
  } catch (error) {
    console.error(error);
  }
}
