import React, { useEffect, useState } from "react";
import AddMetaTags from "components/add_meta_tags";
import { Row, Col } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import LeftSidebar from "components/left_sidebar";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { fetchWeatherSchedule, deleteWeatherSchedule} from "actions/weather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ModalConfirmDelete from "components/shared/modalDeleteConfirmComment/modal";

function WeatherSetting() {
  const dispath = useDispatch();
  let [weatherId, setWeatherId] = useState(null);

  useEffect(() => {
    dispath(fetchWeatherSchedule({ page: 1, limit: 10 }));
  }, []);

  const { data } = useSelector((state) => state.weather.listWeatherSchedule);
  const handleDeleteWeatherSchedule = (id) => {
    dispath(deleteWeatherSchedule(id));
  }

  return (
    <>
      <div className="setting-weather">
        <AddMetaTags title="thiết lập thời tiết" />
        <Row as="div">
          <LeftSidebar />
          <Col as="div" md={9}>
            <div className="cont-center">
              <Row as="div">
                <h5 className="title-line-lt text-uppercase fw-bold mt-3 d-flex align-items-center">
                  <span className="d-block">Thiết lập thông báo thời tiết</span>
                  <Link to="/nguoi-dung/thiet-lap-thong-bao/tao-moi" className="plus-">
                    <PlusCircleFill
                      size={32}
                      className="ml-2"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                </h5>
                <div style={{width: '100%', overflowX: "scroll"}}>
                  <Table striped bordered hover>
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th>Tên sự kiện</th>
                        <th>Nhiệt độ</th>
                        <th>Thời tiết</th>
                        <th>Địa chỉ</th>
                        <th>Chức năng</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map(function (data, index) {
                        return (
                          <tr key={index}>
                            <td style={{minWidth: "100px"}}>{data.title}</td>
                            <td>{data.trigger_condition}{data.trigger_point}</td>
                            <td style={{minWidth: "100px"}}>{data.weather_status ? data.weather_status : 'Tất cả'}</td>
                            <td style={{minWidth: "100px"}}>{data.address}</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <Link to={`/nguoi-dung/thiet-lap-thong-bao/chinh-sua/${data.id}`}>
                                  <Button variant="success" size="sm" >
                                    Sửa
                                  </Button>

                                </Link>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    className="ml-2"
                                    onClick={() => setWeatherId(data.id)}
                                  >
                                    Xóa
                                  </Button>
                                {weatherId && <ModalConfirmDelete isOpen={weatherId} onDelete={() => handleDeleteWeatherSchedule(weatherId)} onToggle={() => setWeatherId(null)}/>}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WeatherSetting;
