import React, { useContext } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { dislikeMarketPrice } from "actions/gia_ca_thi_truong";
import AppContext from "components/app_context";
function ListFollowingMarketPrice({ listFollowingMarketPrices }) {
  const dispatch = useDispatch();
  const { current_user } = useContext(AppContext);

  const removeItemFromEntity = (id) => {
    dispatch(
      dislikeMarketPrice({
        id: current_user?.id,
        classable_type: "MarketPlace",
        classable_id: id,
      })
    );
  };

  return (
    <>
      <div className="row row-cols-2 d-flex">
        {listFollowingMarketPrices?.length > 0 ? (
          listFollowingMarketPrices.map(function (market, index) {
            return (
              <div key={index} className="col">
                <div className="lst-item box-link">
                  <Link to={`/modules/thong-tin-gia-ca-thi-truong/${market.slug || market.id}`} className="">
                    <div className="d-flex align-items-center">
                      <div className="me-2 d-flex align-items-center">
                        <img
                          src={market.image ?? "/images/photos/no_image.png"}
                          alt="product image"
                          title="product image"
                        />
                      </div>

                      <div className="prod-inf">
                        <h6>
                          <span>{market.title}</span>
                        </h6>
                        <div className="d-flex">
                          <div>
                            <small className="d-flex align-items-center">
                              <span className="icon-location01 me-1"></span>
                              <span className="">{market.province_name}</span>
                            </small>
                            <small className="date d-flex align-items-center">
                              <span className="icon-time align-middle me-1"></span>
                              <span>{moment(market.created_at).format("DD/MM/YYYY")}</span>
                            </small>
                            <small className="user d-flex align-items-center">
                              <span className="icon-user01 align-middle me-1"></span>
                              <span>{market.user_name ?? "Admin"}</span>
                            </small>
                          </div>
                          <div className="ms-auto">
                            <div className="prod-price mb-0">
                              {market && market.get_market_price_last.price > 0 ? (
                                <div className="red-clr fs-4 fw-bold">
                                  <NumberFormat>{market && market.get_market_price_last.price}</NumberFormat>{" "}
                                  <span className="fs-7">đ/{market.unit}</span>
                                </div>
                              ) : (
                                <span className="red-clr">&nbsp;Liên hệ</span>
                              )}
                            </div>
                            <small className="d-block text-right redu-pri">
                              {market && market.get_market_price_last.price_difference <= 0 ? (
                                <span className="">
                                  <NumberFormat>{market.get_market_price_last.price_difference}</NumberFormat>
                                  &nbsp;đ/
                                  {market.unit}
                                </span>
                              ) : (
                                <span className="d-block text-right incr-pri">
                                  +<NumberFormat>{market.get_market_price_last.price_difference}</NumberFormat>
                                  &nbsp;đ/
                                  {market.unit}
                                </span>
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className="text-right btn-save">
                    <a
                      role="button"
                      className={`icon-tag fs-3 ${market.user_liked ? "active" : ""}`}
                      title="Save"
                      onClick={() => removeItemFromEntity(market.classable_id)}
                    ></a>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="font-italic">Bạn chưa quan tâm sản phẩm nào</div>
        )}
      </div>
    </>
  );
}

export default ListFollowingMarketPrice;
