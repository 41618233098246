import React, { useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import * as authActions from 'actions/auth'
import AuthPlaceholder from "./placeholder/auth"
import FadeIn from "react-fade-in"
import { redirectLogin } from "lib"
import { useSelector } from 'react-redux'
import {setParamsTrackActivity} from 'lib';
import {createTrackActivity} from 'actions/track_activity';

function Auth(props) {
  let { authActionsCreator } = props;
  let { fetchLogin } = authActionsCreator;
  let search = useLocation().search;
  let access_token = new URLSearchParams(search).get('access_token');
  let redirect_url = useSelector(state=> state.login.current_url.path)
  let {isLogin } = props.user_data;
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(access_token === null){
      redirectLogin();
    }else{
      fetchLogin(access_token);
      dispatch(createTrackActivity(setParamsTrackActivity("User login", "POST", {}, "user_login")));
    }
  }, [fetchLogin, access_token]);

  useEffect(() => {
    if(!isLogin){
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
      navigate(redirect_url);
    }
  }, [isLogin]);

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <FadeIn>
          <AuthPlaceholder />
        </FadeIn>
      </div>
    </section>
  );
}


const mapStateToProps = state => {
  return {
    user_data: state.login.user_data
  };
};

const mapDispatchToProps = dispatch => ({
  authActionsCreator: bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
