import qs from 'query-string';
import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
const url = '/api/v2/diagnostic_infos';

export const fetchDiagnostics = (params = null) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`)
}

export const fetchDiagnosticsDetail = (id) => {
 return axiosService.get(`${DOMAIN}/${url}/${id}`);
}
