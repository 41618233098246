import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import qs from 'query-string';

export const getPopupAdvertisements = params => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/api/v2/banners${query}`);
};

export const getPopupAdvertisementDetail = id => {
  return axiosService.get(`${DOMAIN}/api/v2/banners/${id}`);
};
