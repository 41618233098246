import useManageContribute from "../Hooks/useManageContribute";
import moment from "moment";
import ModalViewImage from "./ModalViewImage";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
const TechnicalProcess = () => {
  const {
    contributeTechnicalProcess,
    handleApproveContributeTechnicalProcess,
    handleDeleteContributeTechnicalProcess,
    expandMore,
    numItems,
    showModal,
    openModal,
    closeModal,
    imageUrl,
    popover,
    handleTitleClick,
    activeOverlay,
  } = useManageContribute();
  const { data } = contributeTechnicalProcess;
  return (
    <div className="data-table">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="align-middle text-s">
              Tiêu đề
            </th>
            <th scope="col" className="align-middle text-s">
              Ảnh
            </th>
            <th scope="col" className="align-middle text-s">
              Ngày đóng góp
            </th>
            <th scope="col" className="align-middle text-s">
              Người đăng
            </th>
            <th scope="col" className="align-middle text-s">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.slice(0, numItems).map(function (data) {
              return (
                <tr className="text-capitalize fs-14" key={data.id}>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popover}
                    show={activeOverlay?.id === data.id}>
                    <td
                      className="align-middle w-300 line-clamp-01 table-title"
                      onClick={() => handleTitleClick(data)}
                      onMouseOut={() => {
                        handleTitleClick({ id: 1 });
                      }}>
                      {data.title}
                    </td>
                  </OverlayTrigger>
                  <td className="align-middle min-w-100">
                    <img
                      className="image-contributing-cursor"
                      src={data.image}
                      alt="Image"
                      style={{ width: "100px", height: "100px" }}
                      onClick={() => showModal(data.image)}
                    />
                  </td>
                  <ModalViewImage openModal={openModal} closeModal={closeModal} image={imageUrl} />
                  <td className="align-middle">{moment(data.created_at).format("DD-MM-YYYY hh:mm")}</td>
                  <td className="align-middle">{data.user_name}</td>
                  <td className="align-middle table_actions">
                    <button
                      className="btn btn-danger btn-sm mr-2"
                      onClick={() => handleDeleteContributeTechnicalProcess(data.id)}>
                      Từ chối
                    </button>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleApproveContributeTechnicalProcess(data.id)}>
                      Duyệt
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="font-italic font-light text-capitalize mt-3">Không có đóng góp</div>
          )}
        </tbody>
      </table>
      {data.length > 10 && numItems < data.length && (
        <div className="post-more background-clr-f5  mt-3 w-100  hover" onClick={() => expandMore()}>
          <span className="txt">
            <u
              style={{
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
              }}>
              Xem thêm
            </u>
          </span>
          <span className="icon-arrow-solid"></span>
        </div>
      )}
    </div>
  );
};

export default TechnicalProcess;
