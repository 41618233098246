import React, { useRef } from 'react';
import NotificationItem from './notificationItem';

const Notifications = ({ data = [], removeNoti, viewNoti, loadMoreNoti }) => {
  const endRef = useRef(null);

  const loadMoreNotification = () => {
    if (endRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = endRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        loadMoreNoti();
      }
    }
  };

  return (
    <div
      className={`notification scrollbar dropdown-menu dropdown-menu-end ${data.length > 0 ? '' : 'd-none'}`}
      aria-labelledby="dropdownNotify"
      ref={endRef}
      onScroll={loadMoreNotification}
    >
      <ul>
        {data.map((noti, index) => (
          <NotificationItem key={index} notification={noti} viewNoti={viewNoti} removeNoti={removeNoti} />
        ))}
      </ul>
    </div>
  );
};

export default Notifications;
