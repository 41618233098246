import * as locationConst from 'constants/location';

export const fetchProvince = params => {
  return {
    type: locationConst.FETCH_PROVINCE,
    payload: {
      params,
    },
  };
};


export const fetchProvinceSuccess = data => {
  return {
    type: locationConst.FETCH_PROVINCE_SUCCESS,
    payload: {
      data,
    },
  };
};


export const fetchProvinceFailed = error => {
  return {
    type: locationConst.FETCH_PROVINCE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchDistrict = params => {
  return {
    type: locationConst.FETCH_DISTRICT,
    payload: {
      params,
    },
  };
};

export const fetchDistrictSuccess = data => {
  return {
    type: locationConst.FETCH_DISTRICT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchDistrictFailed = error => {
  return {
    type: locationConst.FETCH_DISTRICT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchWard = params => {
  return {
    type: locationConst.FETCH_WARD,
    payload: {
      params,
    },
  };
};

export const fetchWardSuccess = data => {
  return {
    type: locationConst.FETCH_WARD_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchWardFailed = error => {
  return {
    type: locationConst.FETCH_WARD_FAILED,
    payload: {
      error,
    },
  };
};
