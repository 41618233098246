
const ComplaintStatus = () => {

  const complaintStatus = {
    none: {
      className: 'badge-success',
      text: 'Không có khiếu nại',
    },
    pending: {
      className: 'badge-warning',
      text: 'Đang chờ xử lý'
    },
    resolved: {
      className: 'badge-success',
      text: 'Đã giải quyết'
    },
    rejected: {
      className: 'badge-danger',
      text: 'Bị từ chối'
    },
    cancelled: {
      className: 'badge-danger',
      text: 'Huỷ khiếu nại'
    },
  }

  return {
    complaintStatus,
  }
}

export default ComplaintStatus;
