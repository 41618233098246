import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import {toastSuccess} from 'helpers/toastHelper';

function ModalAddEmployee({editData, show, onToggle, onAdd, onEdit, businessId}) {  
  useEffect(() => {
    if(editData){
      const permission = editData?.permission.reduce((obj, key) => {
        obj[key] = key;
        return obj;
      }, {});

      setValues({
        phone: editData.user_phone,
        products: Boolean(permission["products"]),
        invoices: Boolean(permission["invoice_users"]),
        notification: Boolean(editData.notification === 1)
      });
    }else{
      setValues({
        phone: '',
        products: false,
        invoices: false,
        notification: false
      })
    }
    return () => {
      resetForm();
      setValues({
        phone: '',
        products: false,
        invoices: false,
        notification: false
      })
    }
  }, [editData]);

  const formSchema = Yup.object().shape({
    phone: Yup.string().required("Không được để trống").matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Vui lòng nhập số điện thoại hợp lệ'),
  });

  const {values, errors, isSubmitting,handleChange, handleSubmit,resetForm, setSubmitting, touched, setValues}= useFormik({
    initialValues: {
      phone: '',
      products: false,
      invoices: false,
      notification: false
    },
     validationSchema: formSchema,
    onSubmit: async(values) => {
      setSubmitting(true);
      const keysPermission = [];
      if(values.invoices){
        keysPermission.push('invoice_users');
      }
       if (values.products) {
        keysPermission.push('products');
      }
      let payload = {
        business_association_users_attributes: {
          regency: 'normal',
          notification: values.notification,
          permission:  keysPermission
        },  
      phone: values.phone,
      }
      if (editData) {
        onEdit(payload, () => {onToggle();toastSuccess("Sửa nhân viên thành công"); resetForm()}, editData.id)
      }else{
       await onAdd(payload, () => { onToggle(); toastSuccess("Thêm nhân viên thành công"); resetForm()})
      }
    }
  })

  return (
    <>
      <Modal show={show || editData?.id} onHide={onToggle}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton style={{backgroundColor: '#009a59', color: '#fff'}}>
            <Modal.Title>{editData ? 'Sửa nhân viên' : 'Thêm nhân viên'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Nhập số điện thoại nhân viên</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nhập số điện thoại nhân viên"
                  autoFocus 
                  onChange={handleChange}
                  name="phone"
                  value={values.phone}
                  disabled={Boolean(editData)}
                />
              </Form.Group>
              {errors.phone && touched.phone &&  <Form.Label className="alert-invalid ">{errors.phone}</Form.Label> }
              <Form.Group
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">Phân quyền</Form.Label>
              </Form.Group>
              <Form.Group className="mb-3" controlId="products_checkbox">
                <Form.Check type="checkbox" label="Đăng sản phẩm" name="products" value="products" onChange={handleChange} checked={values.products}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="invoices_checkbox">
                <Form.Check type="checkbox" label="Duyệt đơn hàng" name="invoices" value="invoices" onChange={handleChange} checked={values.invoices}/>
              </Form.Group>
              <Form.Group className="mb-3" controlId="notification_checkbox">
                <Form.Check type="checkbox" label="Nhận thông báo" name="notification" value="notification" onChange={handleChange} checked={values.notification}/>
              </Form.Group> 
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {editData ? 'Sửa' : 'Thêm'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ModalAddEmployee;
