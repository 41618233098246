import React, { useEffect, useState } from 'react';
import ScrollToTop from 'components/ScrollToTop';
import AddMetaTags from 'components/add_meta_tags';
import LeftSidebar from 'components/left_sidebar';
import ListProduct from './Components/ListProduct';
import UserInformation from './Components/UserInformation';
import NumberFormat from "components/number_format";
import { useCart } from './Context';
import { checkSeller } from '../../apis/invoice';

function Cart() {
  const { listProductInCarts, totalPriceProductInCart } = useCart();
  const [productsWithBusinessId, setProductsWithBusinessId] = useState([]);
  const [productsWithoutBusinessId, setProductsWithoutBusinessId] = useState([]);

  const getShopStatus = async (item) => {
    const { data: productData } = await checkSeller({ product_id: item.id });
    const hasBusinessId = item.business_association_id;

    if (hasBusinessId) {
      const { data: businessData } = await checkSeller({ business_association_id: item.business_association_id });
      return businessData?.data ? 'active' : 'disable';
    }

    return productData?.data ? 'active' : 'disable';
  };

  const fetchProductsStatus = async () => {
    const productsWithBusinessId = await Promise.all(
      listProductInCarts
        .filter(product => product.business_association_id)
        .map(async (item) => ({
          ...item,
          shop_status: await getShopStatus(item),
        }))
    );

    const productsWithoutBusinessId = await Promise.all(
      listProductInCarts
        .filter(product => !product.business_association_id)
        .map(async (item) => ({
          ...item,
          shop_status: await getShopStatus(item),
        }))
    );

    setProductsWithBusinessId(productsWithBusinessId);
    setProductsWithoutBusinessId(productsWithoutBusinessId);
  };

  const allProductsActive = productsWithBusinessId.every(product => product.shop_status === 'active') && productsWithoutBusinessId.every(product => product.shop_status === 'active');

  useEffect(() => {
    fetchProductsStatus();
  }, [listProductInCarts]);

  return (
    <>
      <ScrollToTop>
        <AddMetaTags title={"Giỏ hàng"} />
        <div className='cart'>
          <div className='row'>
            <LeftSidebar />
            <div className="col-md-9 col-sm-12">
              <div className="bg-white border rounded-3">
                <section className='w-100 p-4 table-responsive'>
                  {productsWithBusinessId.length > 0 && (
                    <ListProduct listProductInCarts={productsWithBusinessId} />
                  )}
                  {productsWithoutBusinessId.length > 0 && (
                    <ListProduct listProductInCarts={productsWithoutBusinessId} />
                  )}
                  <div className="total-price">
                    <p className="fw-bold mb-1 mt-2">
                      Tổng tiền: <NumberFormat>{totalPriceProductInCart}</NumberFormat>₫
                    </p>
                  </div>
                  <UserInformation hiddenButton={!allProductsActive} />
                </section>
              </div>
            </div>
          </div>
        </div>
      </ScrollToTop>
    </>
  );
}

export default Cart;
