import React, { useState } from "react";
import DatePicker from "react-datepicker";
import SelectProvice from "./select";
function SearchBox(props) {
  const {
    listProvinces,
    searchMarketPrices,
    setKeyword,
    setProvinceId,
    date,
    keyword,
    setDate,
    fetchAllMarketPrices,
    agriculturalType,
    reset,
    setReset,
    fetchFollowingMarketPrice,
  } = props;

  const resetSearch = () => {
    setReset(true);
    setKeyword("");
    setDate(null);
    setProvinceId(null);
    if (agriculturalType === "follow") {
      fetchFollowingMarketPrice({ page: 1 });
    } else {
      fetchAllMarketPrices({
        page: 1,
        agricultural_type:
          agriculturalType === "all" || agriculturalType === "follow"
            ? ""
            : agriculturalType,
      });
    }
  };
  return (
    <>
      <div className="search-box">
        <form>
          <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-12">
              <input
                className="form-control mb-2"
                type="text"
                placeholder="Mặt hàng"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12 mb-2">
              <div className="position-relative">
                <SelectProvice
                  listProvinces={listProvinces}
                  setProvinceId={setProvinceId}
                  isReset={reset}
                  setReset={setReset}
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12 position-relative">
              <label className='date-search-market-price'>
                <DatePicker
                  className="form-control mb-2"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Chọn ngày"
                  selected={date ? new Date(date) : ""}
                  onChange={(date) => setDate(date)}
                />
                <span className="icon-calendar"></span>
              </label>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-12 btn-pd d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm btn-primary text-center mr-2 "
                onClick={searchMarketPrices}
              >
                Tìm kiếm
              </button>
              <button
                type="button"
                className="btn btn-sm btn-warning text-center text-uppercase"
                onClick={resetSearch}
              >
                Bỏ lọc
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default SearchBox;
