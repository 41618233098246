export const STATUS_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    UPDATED: 202,
    BAD_GATEWAY: 502,
    AUTHORIZATION: 401,
    BAD_REQUEST: 400,
    NO_CONTENT: 204,
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    INTERNAL_SERVER: 500,
    REQUEST_TIMEOUT: 408,
};

var DOMAIN_ENV = "";
var CALLBACK_URL_ENV = "";
var id_portal = "";
var LUCKY_EVENT_URL = "";
var HAINONG_DOMAIN = "";

export var event_domain = "";
if (process.env.NODE_ENV === "none") {
    DOMAIN_ENV = "https://dev.panel.hainong.vn";
    CALLBACK_URL_ENV = "http://localhost:3001/auth";
    id_portal = "https://dev.id.hainong.vn";
    LUCKY_EVENT_URL = "https://dev.luckywheel.event.hainong.vn";
    event_domain = "dev";
    HAINONG_DOMAIN = "http://localhost:3001";
}

if (process.env.NODE_ENV === "production") {
    DOMAIN_ENV = "https://panel.hainong.vn";
    CALLBACK_URL_ENV = "https://hainong.vn/auth";
    LUCKY_EVENT_URL = "https://luckywheel.event.hainong.vn";
    id_portal = "https://id.hainong.vn";
    event_domain = "";
    HAINONG_DOMAIN = "https://hainong.vn";
}

if (process.env.NODE_ENV === "development") {
    DOMAIN_ENV = "https://dev.panel.hainong.vn";
    CALLBACK_URL_ENV = "https://dev.hainong.vn/auth";
    LUCKY_EVENT_URL = "https://dev.luckywheel.event.hainong.vn";
    id_portal = "https://dev.id.hainong.vn";
    event_domain = "dev";
    HAINONG_DOMAIN = "https://dev.hainong.vn";
}

if (process.env.NODE_ENV === "uat") {
    DOMAIN_ENV = "https://uat.panel.hainong.vn";
    CALLBACK_URL_ENV = "https://uat.hainong.vn/auth";
    LUCKY_EVENT_URL = "https://uat.luckywheel.event.hainong.vn";
    id_portal = "https://uat.id.hainong.vn";
    event_domain = "uat";
    HAINONG_DOMAIN = "https://uat.hainong.vn";
}

if (process.env.NODE_ENV === "staging") {
    DOMAIN_ENV = "https://staging.panel.hainong.vn";
    CALLBACK_URL_ENV = "https://staging.hainong.vn/auth";
    LUCKY_EVENT_URL = "https://staging.luckywheel.event.hainong.vn";
    id_portal = "https://staging.id.hainong.vn";
    event_domain = "staging";
    HAINONG_DOMAIN = "https://staging.hainong.vn";
}

export const ROLE = ["admin", "smod", "mod", "member"];
export const WEB_SOCKET_DOMAIN = "https://calling.hainong.vn";
export const DOMAIN = DOMAIN_ENV;
export const CALLBACK_URL = CALLBACK_URL_ENV;
export const SSO_ENDPOINT = id_portal;
export const PAGE = 1;
export const PER_PAGE = 24;
export const LAT_LONG_HCM = {lat: 10.8058, lng: 106.6382};
export const event_url = LUCKY_EVENT_URL;
export const HAINONG = HAINONG_DOMAIN;
