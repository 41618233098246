
import React from 'react';
import SectionWrapper from '../wrapper/SectionWrapper';
import { Col, Container, Image, Row } from 'react-bootstrap';
import MedalImg from '../../../assets/images/medal.png';

const EnterpriseInformation = ({ enterprise }) => {
  return (
    <>
      <SectionWrapper>
        <Container className="my-2">
          <Row>
            <Col xs={4}>
              <div className="position-relative">
                <Image src={enterprise.image} alt="anh doanh nghiep" fluid thumbnail />
              </div>
              {!!enterprise.prestige && (
                <div className="position-absolute enterprise-pretige">
                  <Image src={MedalImg} alt="" sizes="" fluid />
                </div>
              )}
            </Col>
            <Col xs={8}>
              <div className="pl-2">
                <div className="address mb-3">
                  <span className="fw-bold">Địa chỉ: </span>
                  <span className="mr-1">{enterprise.address ?? ''}</span>
                  <span className="mr-1">{enterprise.district_name ?? ''}</span>
                  <span className="mr-1">{enterprise.province_name ?? ''}</span>
                </div>
                <div className="email mb-3">
                  <span className="fw-bold">Email: </span>
                  <a href={`mailto:${enterprise.email ?? ''}`} target="_blank">
                    <span className="mr-1">{enterprise.email ?? ''}</span>
                  </a>
                </div>
                <div className="phone mb-3">
                  <span className="fw-bold">Điện thoại: </span>
                  <a href={`tel:${enterprise.phone ?? ''}`} target="_blank">
                    <span className="mr-1">{enterprise.phone ?? ''}</span>
                  </a>
                </div>
                <div className="website mb-3">
                  <span className="fw-bold">Website: </span>
                  <a href={enterprise.website ?? ''} target="_blank">
                    <span className="mr-1">{enterprise.website ?? ''}</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default EnterpriseInformation;