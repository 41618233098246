import { call, put } from "redux-saga/effects";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { STATUS_CODE } from "constants/index";
import {
  getTraceability,
  getTraceabilityById,
  deleteTrace,
  scanTraceability,
  newTraceability,
} from "apis/traceability";
import {
  fetchTraceabilityFailed,
  fetchTraceabilitySuccess,
  deleteTraceabilitySuccess,
  deleteTraceabilityFailed,
  scanTraceabilityFailed,
  scanTraceabilitySuccess,
  newTraceabilityFailed,
  newTraceabilitySuccess,
  fetchTraceabilityDetailSuccess,
  fetchTraceabilityDetailFailed,
} from "actions/traceability";

export function* fetchTraceabilities({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(getTraceability, params);
    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchTraceabilitySuccess(data));
    } else {
      yield put(fetchTraceabilityFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteTraceabilities({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(deleteTrace, params);
    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteTraceabilitySuccess(params));
      toastSuccess("Bạn đã xóa thành công");
    } else {
      yield put(deleteTraceabilityFailed(params));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* scanTraceabilities({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(scanTraceability, params);
    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(scanTraceabilitySuccess(data));
    } else {
      yield put(scanTraceabilityFailed(data));
    }
  } catch (error) {
    yield put(scanTraceabilityFailed({ error: "Mã định danh không hợp lệ" }));
    toastError("Mã định danh không hợp lệ");
  }
}

export function* newTraceabilities({ payload }) {
  try {
    const { params } = payload;
    const response = yield call(newTraceability, params);
    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(newTraceabilitySuccess(data));
    } else {
      yield put(newTraceabilityFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchTraceabilitiesDetail({ payload }) {
  try {
    const id = payload.id;

    const response = yield call(getTraceabilityById, id);
    const { data, status } = response;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchTraceabilityDetailSuccess(data));
    } else {
      yield put(fetchTraceabilityDetailFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}
