import { call, put } from 'redux-saga/effects'
import { STATUS_CODE } from 'constants'
import { toastError } from 'helpers/toastHelper'
import { fetchDiagnostics, fetchDiagnosticsDetail } from 'apis/diagnostic'
import {fetchDiagnosticsSuccess, fetchDiagnosticInfoDetailsSuccess} from 'actions/diagnostic';

export function* fetchDiagnostic({payload}) {
  try {
    const {params}  = payload;
    const resp = yield call(fetchDiagnostics, params);
    const {data, status} = resp;
    if (status === STATUS_CODE.SUCCESS){
      yield put(fetchDiagnosticsSuccess(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* fetchDiagnosticDetail({ payload}) {
  try {
    const {params} = payload;
    const resp = yield call(fetchDiagnosticsDetail, params.id);
    const {data, status} = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchDiagnosticInfoDetailsSuccess(data))
    }
  } catch (error) {
    toastError(error);
  }
}
