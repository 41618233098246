import React, { useState, useEffect } from 'react';
import AddMetaTags from 'components/add_meta_tags';
import LeftSidebar from 'components/left_sidebar';
import { useLocation } from 'react-router-dom';
import { getPopupAdvertisementDetail } from '../../apis/banner';
import parse from "html-react-parser"

const BannerDetail = () => {
  const location = useLocation();
  const [detail, setDetail] = useState(null);

  const fetchBannerDetail = async () => {
    const { data } = await getPopupAdvertisementDetail(location.state);
    setDetail(data.data);
  };

  useEffect(() => {
    fetchBannerDetail();
  }, [location]);

  return (
    <>
      <AddMetaTags title={'Chi tiết quảng cáo'} />
      <div className="tts">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <h2 className="text-center">Chi tiết quảng cáo</h2>
            {detail && (
              <div className="cont-center saved-items">
                <div className="row p-3 ">
                  <div className="col-md-12 col-sm-12">
                    <div className="row my-3">
                      <label className="font-weight-bold">Tên Popup quảng cáo</label>
                      <input type="text" disabled value={detail.name} />
                    </div>
                    <div className="row my-3">
                      <label className="font-weight-bold">Hình ảnh quảng cáo</label>
                      <div className="">
                        <img src={detail.image} alt="image-banner" />
                      </div>
                    </div>
                    <div className="row my-3">
                      <label className="font-weight-bold">Nội dung</label>
                      {parse(detail.description)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerDetail;
