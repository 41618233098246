import React, { lazy } from 'react';
import Desktop from "../Desktop";
import Mobile from '../Mobile';

const EnterpriseInfo = ({ enterprise, isMobile }) => {

  return (
    <>
      {isMobile ? <Mobile enterprise={enterprise} /> : <Desktop enterprise={enterprise} /> }
    </>
  );
};

export default React.memo(EnterpriseInfo);
