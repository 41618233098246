import * as marketActions from "actions/gia_ca_thi_truong";
import { bindActionCreators } from "redux";
import { PAGE } from "constants";
import { connect } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import React from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import _ from "lodash";
import * as locationActions from "actions/location";
import AppContext from "components/app_context";
import { toastErrorLogin } from "helpers/toastHelper";
import Tabs from "./components/tabs";

function GiaCaThiTruong(props) {
  const [page, setPage] = useState(PAGE);
  const { fetchAllMarketPrices, fetchLoadMoreMarketPrices, fetchFollowingMarketPrice } = props.marketActionsCreators;
  const { fetchProvince } = props.locationActionsCreator;
  const { data, isLoadMore, isLoading } = props.listMarketPrices;
  const { data: listProvinces } = props.listProvinces;
  const { data: listFollowingMarketPrices } = props.listFollowingMarketPrices;
  const [date, setDate] = useState(null);
  const [agriculturalType, setAgriculturalType] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [provinceId, setProvinceId] = useState(null);
  const { current_user, isMobile } = useContext(AppContext);
  const resetPage = () => setPage(1);

  const handleLoadMore = () => {
    setPage(page + 1);
    fetchLoadMoreMarketPrices({
      page: page + 1,
      agricultural_type: agriculturalType === "all" ? "" : agriculturalType,
    });
  };

  useEffect(() => {
    if (agriculturalType === "follow") {
      fetchFollowingMarketPrice({ page: page });
    } else {
      if(agriculturalType !== "follow") {
        fetchAllMarketPrices({
          page: page,
          agricultural_type: agriculturalType === "all" ? "" : agriculturalType,
        });
      }

      fetchProvince();
    }
  }, [agriculturalType]);

  const searchMarketPrices = () => {
    if (agriculturalType !== "follow") {
      fetchAllMarketPrices({
        keyword: keyword,
        province_id: provinceId,
        date: date,
        agricultural_type: agriculturalType === "all" ? "" : agriculturalType,
      });
    } else {
      if (current_user) {
        fetchFollowingMarketPrice({
          keyword: keyword,
          province_id: provinceId,
          date: date,
        });
      }
    }
  };

  const handleCancelSearch = () => {
    fetchAllMarketPrices({ page: page });
    fetchProvince();
    setKeyword("");
    setDate(null);
    setProvinceId(null);
    fetchAllMarketPrices({
      page: 1,
      agricultural_type: agriculturalType === "all" ? "" : agriculturalType,
    });
  };

  const onClick = (e) => {
    if (current_user === null) {
      e.preventDefault();
      toastErrorLogin(isMobile);
    }
  };

  if (listProvinces.filter((item) => item.name === "Tất cả").length == 0)
    listProvinces.unshift({ id: null, name: "Tất cả" });

  const listProvincesFormatted = _.map(listProvinces, (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
  return (
    <>
      <AddMetaTags title={"Giá cả thị trường"} />
      <div className="gctt">
        <div className="row">
          <LeftSidebar isShoWidgetMarketPrice={false} />
          <div className="col-md-9 col-sm-12 mkt-price">
            <div className="d-flex align-items-center text-center">
              <div className="w-100 text-center">
                <h4 className=" text-uppercase fw-bold my-2">Giá cả thị trường</h4>
              </div>
              {current_user && (
                <div className="d-flex justify-content-end ">
                  <Link
                    to={`/modules/gia-ca-thi-truong/dong-gop`}
                    className="btn btn-sm contribute ms-auto"
                    onClick={onClick}>
                    <img src="/images/donggop_icon.png" alt="image" title="Đóng Góp" />
                    <span className="txt">Đóng góp</span>
                  </Link>
                </div>
              )}
            </div>

            <Tabs
              data={data}
              keyword={keyword}
              setAgriculturalType={setAgriculturalType}
              isLoading={isLoading}
              isLoadMore={isLoadMore}
              resetPage={resetPage}
              handleLoadMore={() => handleLoadMore()}
              handleCancelSearch={() => handleCancelSearch()}
              listProvinces={listProvincesFormatted}
              setKeyword={setKeyword}
              searchMarketPrices={searchMarketPrices}
              setProvinceId={setProvinceId}
              setDate={setDate}
              date={date}
              listFollowingMarketPrices={listFollowingMarketPrices}
              fetchAllMarketPrices={fetchAllMarketPrices}
              agriculturalType={agriculturalType}
              fetchFollowingMarketPrice={fetchFollowingMarketPrice}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => {
  return {
    listMarketPrices: state.market.listMarketPrices,
    listProvinces: state.location.listProvinces,
    listFollowingMarketPrices: state.market.followingMarketPrice,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    marketActionsCreators: bindActionCreators(marketActions, dispatch),
    locationActionsCreator: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiaCaThiTruong);
