import { call, put } from 'redux-saga/effects'
import { STATUS_CODE } from 'constants'
import { toastError } from 'helpers/toastHelper'
import { getExperts } from 'apis/expert'
import {fetchExpertSuccess} from 'actions/expert';
import { setParamsTrackActivity } from "lib";
import {track_activities} from "apis/track_activity";

export function* fetchExpert() {
  try {
    const resp = yield call(getExperts);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchExpertSuccess(data));
    }
  } catch (error) {
    toastError(error);
  }
}