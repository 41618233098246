import * as managerConst from "constants/manager";
import { toastError, toastSuccess } from "helpers/toastHelper";
const initialState = {
  dataUpdateComment: {
    data: [],
    error: null,
    isLoading: false,
  },
  dataUpdateAnswerComment: {
    data: null,
    error: null,
    isLoading: false,
  },
  warning_comments: {
    data: [],
    total_warning: null,
    error: null,
    isLoading: false,
  },
  contribute_technical_process: {
    data: [],
    isLoading: false,
    error: null,
    isLoadMore: false,
  },
  contribute_pests: {
    data: [],
    isLoading: false,
    error: null,
    isLoadMore: false,
  },
  contribute_market_price: {
    data: [],
    isLoading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case managerConst.MANAGER_UPDATE_COMMENT: {
      return {
        ...state,
        dataUpdateComment: {
          data: initialState.dataUpdateComment.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case managerConst.MANAGER_UPDATE_COMMENT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        dataUpdateComment: {
          data: state.dataUpdateComment.data.map((comment) => {
            if (comment.id == data.data.id) {
              comment.content = data.data.content;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case managerConst.MANAGER_UPDATE_ANSWER_COMMENT: {
      return {
        ...state,
        dataUpdateComment: {
          data: initialState.dataUpdateComment.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case managerConst.MANAGER_UPDATE_ANSWER_COMMENT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        dataUpdateAnswerComment: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.FETCH_WARNING_COMMENT: {
      return {
        ...state,
        warning_comments: {
          data: initialState.warning_comments.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case managerConst.FETCH_WARNING_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        warning_comments: {
          data: data.data,
          isLoading: false,
          total_warning: data.total_report,
          error: null,
        },
      };
    }

    case managerConst.FETCH_WARNING_COMMENT_FAILED: {
      const { data } = action.payload;
      toastError(data.data);
      return { ...state };
    }

    case managerConst.DELETE_WARNING_COMMENT: {
      return {
        ...state,
        warning_comments: {
          data: state.warning_comments.data,
          total_warning: state.warning_comments.total_report,
          error: null,
          isLoading: true,
        },
      };
    }
    case managerConst.DELETE_WARNING_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        warning_comments: {
          data: state.warning_comments.data.filter((warning) => warning.id != data.id),
          total_warning: state.warning_comments.total_report,
          error: null,
          isLoading: false,
        },
      };
    }
    case managerConst.FETCH_TECHNICAL_PROCESS_FOR_APPROVE: {
      return {
        ...state,
        contribute_technical_process: {
          data: initialState.contribute_technical_process.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case managerConst.FETCH_TECHNICAL_PROCESS_FOR_APPROVE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_technical_process: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case managerConst.FETCH_TECHNICAL_PROCESS_FOR_APPROVE_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    case managerConst.FETCH_CONTRIBUTE_PESTS: {
      return {
        ...state,
        contribute_pests: {
          data: initialState.contribute_pests.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case managerConst.FETCH_CONTRIBUTE_PESTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_pests: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case managerConst.FETCH_CONTRIBUTE_PESTS_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }
    case managerConst.FETCH_CONTRIBUTE_MARKET_PRICE: {
      return {
        ...state,
        contribute_market_price: {
          data: initialState.contribute_market_price.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case managerConst.FETCH_CONTRIBUTE_MARKET_PRICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_market_price: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case managerConst.FETCH_CONTRIBUTE_MARKET_PRICE_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }
    case managerConst.MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_technical_process: {
          data: state.contribute_technical_process.data.filter((t) => t.id != data.data.id),
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    case managerConst.MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_technical_process: {
          data: state.contribute_technical_process.data.filter((t) => t.id != data.id),
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    case managerConst.MANAGE_CONTRIBUTE_PESTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_pests: {
          data: state.contribute_pests.data.filter((t) => t.id !== data.data.id),
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.MANAGE_CONTRIBUTE_PESTS_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    case managerConst.MANAGE_DELETE_CONTRIBUTE_PESTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_pests: {
          data: state.contribute_pests.data.filter((t) => t.id !== data.data.id),
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.MANAGE_DELETE_CONTRIBUTE_PESTS_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    case managerConst.MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        contribute_market_price: {
          data: state.contribute_market_price.data.filter((m) => m.id !== data.data.market_place_id),
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    case managerConst.MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_SUCCESS: {
      const { data } = action.payload;
      const { parentId } = data;
      return {
        ...state,
        contribute_market_price: {
          data: state.contribute_market_price.data.filter((m) => m.id !== parentId),
          isLoading: false,
          error: null,
        },
      };
    }

    case managerConst.MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_FAILED: {
      toastError("bạn ko đủ quyền để truy cập");
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default reducer;
