import { call, put } from "redux-saga/effects";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { STATUS_CODE } from "constants";
import {
  getAllMarketPrices,
  getMarketPrices,
  likeMarketPrice,
  dislikeMarketPrice,
  fetchFollowingMarketPrice,
} from "apis/gia_ca_thi_truong";
import {
  fetchAllMarketPricesSuccess,
  fetchAllMarketPricesFailed,
  fetchMarketPricesSuccess,
  fetchMarketPricesFailed,
  fetchLoadMoreMarketPricesSuccess,
  fetchLoadMoreMarketPricesFailed,
  fetchFollowingMarketPriceSuccess,
  fetchSideBarMarketPricesSuccess,
  fetchSideBarMarketPricesFailed,
  likeMarketPriceSuccess,
  likeMarketPriceFailed,
  dislikeMarketPriceSuccess,
} from "actions/gia_ca_thi_truong";
import { toastErrorLogin } from '../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

export function* fetchAllMarketPrices({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAllMarketPrices, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchAllMarketPricesSuccess(data));
    } else {
      yield put(fetchAllMarketPricesFailed(data));
    }
  } catch (error) {}
}
export function* fetchSideBarMarketPrices({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAllMarketPrices, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchSideBarMarketPricesSuccess(data));
    } else {
      yield put(fetchSideBarMarketPricesFailed(data));
    }
  } catch (error) {}
}

export function* fetchMarketPriceDetail({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getMarketPrices, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS && data.data.length > 0) {
      yield put(fetchMarketPricesSuccess(data));
    }
    else yield put(fetchMarketPricesFailed(data));
  } catch (error) {
    yield put(fetchMarketPricesFailed(error));
  }
}

export function* fetchLoadMoreMarketPrices({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAllMarketPrices, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMoreMarketPricesSuccess(data));
    } else {
      yield put(fetchLoadMoreMarketPricesFailed(data));
    }
  } catch (error) {}
}

export function* userLikeMarketPrice({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likeMarketPrice, params);
    const { data, status } = resp;
    const {success} = data;
    if (status === STATUS_CODE.SUCCESS && success) {
      yield put(likeMarketPriceSuccess(data));
      toastSuccess("Bạn đã quan tâm mặt hàng này");
    }else {
      yield put(likeMarketPriceFailed(data));
    }
  } catch (error) {}
}

export function* userDislikeMarketPrice({ payload }) {
  try {
    const { params } = payload;
    const id = params.classable_id;
    const resp = yield call(dislikeMarketPrice, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(dislikeMarketPriceSuccess({ marketPriceId: id }));
      toastSuccess("Bạn đã bỏ quan tâm mặt hàng này");
    }
  } catch (error) {}
}

export function* userFetchFollowingMarketPrice({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(fetchFollowingMarketPrice, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFollowingMarketPriceSuccess(data));
    }
  } catch (error) {
    toastErrorLogin(isMobile)
  }
}
