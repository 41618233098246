import React, { useEffect, useState } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { toastSuccess, toastError } from "helpers/toastHelper";
import { getWeatherStatus, createWeatherSchedule } from "apis/weather";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ArrowLeft } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeatherScheduleId } from "actions/weather";
import { updateWeatherSchedule, getWeatherScheduleId } from "apis/weather";
function New(props) {
  const nagivate = useNavigate();
  const { weatherId } = useParams();
  const dispatch = useDispatch();
  const { data: dataEdit } = useSelector((state) => state.weather.weatherScheduleId);
  let [listWeatherStatus, setListWeatherStatus] = useState([]);
  let [formData, setformData] = useState(null);
  let [title, setTitle] = useState(formData && formData.title);
  let [trigger_point, setTrigger_point] = useState(formData && formData.trigger_point);
  let [trigger_condition, setTrigger_condition] = useState(formData && formData.setTrigger_condition);
  let [address, setAddress] = useState(formData && formData.address);
  let [weather_status, setWeatherStatus] = useState(formData && formData.weather_status);

  useEffect(() => {
    getWeatherStatus().then((res) => {
      if (res) {
        setListWeatherStatus(res.data.data);
      }
    });
    if (weatherId) {
      getWeatherScheduleId(weatherId).then((res) => {
        if (res) {
          setformData(res.data.data);
        }
      });
      // dispatch(fetchWeatherScheduleId(weatherId));
    }
  }, [weatherId]);

  const handleUpdateWeatherSchedule = (e) => {
    e.preventDefault();
    updateWeatherSchedule({
      id: weatherId,
      title: !title ? formData.title : title,
      trigger_point: !trigger_point ? formData.trigger_point : trigger_point,
      trigger_condition: !trigger_condition ? formData.trigger_condition : trigger_condition,
      weather_status: !weather_status ? formData.weather_status : weather_status,
      address: !address ? formData.address : address,
    }).then((res) => {
      if (res) {
        toastSuccess("Sửa thiết lập thông báo thành công");
        nagivate("/nguoi-dung/thiet-lap-thong-bao");
      }
    });
  };
  // const formik = useFormik({
  //   initialValues: {
  //     title:  !!data && data.title,
  //     weather_status: 'Bầu trời quang đãng',
  //     address: '',
  //     trigger_point: '',
  //     trigger_condition: '>=',
  //   },
  //   validationSchema: Yup.object().shape({
  //     title: Yup.string().required("Tiêu đề không được bỏ trống"),
  //     trigger_point: Yup.string().required("Nhiệt độ không được bỏ trống").matches(/^\d+$/, 'Chỉ được nhập số'),
  //     address: Yup.string().required("Địa chỉ không được bỏ trống")
  //   }),
  //   onSubmit: (values, { resetForm }) => {
  //     createWeatherSchedule({
  //       title: values.title,
  //       weather_status: values.weather_status,
  //       address: values.address,
  //       trigger_point: values.trigger_point,
  //       trigger_condition: values.trigger_condition,
  //     }).then((response) => {
  //       if (response) {
  //         toastSuccess("Tạo thông báo thành công");
  //         nagivate("/nguoi-dung/thiet-lap-thong-bao");
  //       }
  //     });
  //   },
  // });

  return (
    <>
      <div className="new-setting-weather">
        <AddMetaTags title={"chỉnh sửa thiết lập thời tiết"} />
        <Row>
          <LeftSidebar />
          <Col as="div" md={9}>
            <div className="cont-center">
              <h5 className="title-line-lt text-uppercase fw-bold mt-3 d-flex align-items-center line-none">
                <ArrowLeft
                  size={24}
                  fill="#009a59"
                  style={{ cursor: "pointer" }}
                  onClick={() => nagivate("/nguoi-dung/thiet-lap-thong-bao")}
                />
                <span className="d-block ml-2">{props.isEditMode ? "Chỉnh sửa" : "Tạo mới"} Thông báo thời tiết</span>
              </h5>
            </div>
            <form onSubmit={(e) => handleUpdateWeatherSchedule(e)}>
              <div className="field-new-setting-weather">
                <Row>
                  <Col md={6}>
                    <label>Tên sự kiện</label>
                    <input
                      className="form-control"
                      type="text"
                      // value={title}
                      defaultValue={formData && formData.title}
                      name="title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {/* {formik.errors.title && formik.touched.title && (
                      <label className="alert-invalid">
                        {formik.errors.title}
                      </label>
                    )} */}
                  </Col>
                  <Col md={6}>
                    <label>Nhiệt độ</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue={formData && formData.trigger_point}
                      name="trigger_point"
                      onChange={(e) => setTrigger_point(e.target.value)}
                      // onChange={formik.handleChange}
                    />
                    {/* {formik.errors.trigger_point &&
                      formik.touched.trigger_point && (
                        <label className="alert-invalid">
                          {formik.errors.trigger_point}
                        </label>
                      )} */}
                  </Col>
                  <Col md={6}>
                    <label>Điều kiện</label>
                    <div className="input-group">
                      <select
                        className="custom-select"
                        id="inputGroupSelect02"
                        // onChange={formik.handleChange}
                        defaultValue={formData && formData.trigger_condition}
                        name="trigger_condition"
                        onChange={(e) => setTrigger_condition(e.target.value)}
                      >
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                      </select>
                      <div className="input-group-append">
                        <input
                          defaultValue={formData && formData.address}
                          className="form-control"
                          type="text"
                          style={{ borderRadius: "0px" }}
                          // onChange={formik.handleChange}
                          name="address"
                          placeholder="Địa chỉ: TPHCM, Bến tre"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* {formik.errors.address && formik.touched.address && (
                          <label className="alert-invalid">
                            {formik.errors.address}
                          </label>
                    )} */}
                  </Col>
                  <Col md={6}>
                    <label>Thời tiết</label>
                    <Form.Select
                      defaultValue={formData && formData.weather_status}
                      name="weather_status"
                      onChange={(e) => setWeatherStatus(e.target.value)}
                    >
                      <option value="">Tất cả</option>
                      {listWeatherStatus &&
                        listWeatherStatus.map(function (data, index) {
                          return (
                            <option value={data.lable} key={index}>
                              {data.lable}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Row>

                <div className="text-center mt-3">
                  <button type="submit" className="btn btn-danger" disabled={listWeatherStatus ? false : true}>
                    {props.isEditMode ? "Cập nhật" : " Tạo mới"}
                  </button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default New;
