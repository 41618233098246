import React from "react";
import { Link } from "react-router-dom";
const ButtonAddTraceability = (props) => {
  return (
    <div>
      <Link className="traceability-add-button" to="/modules/truy-xuat-nguon-goc/tao-moi">
        <span style={{ fontSize: "50px", color: "#fff", marginBottom: "10px" }}>+</span>
      </Link>
    </div>
  );
};
export default ButtonAddTraceability;
