import React, { useState, useEffect } from "react";
import Tabs from "./tabs";
import { useDispatch, useSelector } from "react-redux";
import { fetchSidebarArticle } from "actions/article";
import _ from "lodash";

function News() {
  let [tabs, setTabs] = useState(1);
  const dispatch = useDispatch();
  const { data: listNews } = useSelector((state) => state.article.sidebarArticles);
  let listSubNews = _.slice(listNews, 1, 6);

  useEffect(() => {
    if (tabs === 0) {
      dispatch(fetchSidebarArticle({ page: 1, limit: 6, article_type: "Article" }));
    } else {
      dispatch(fetchSidebarArticle({ page: 1, limit: 6, article_type: "Article", is_feature: 1 }));
    }
  }, [tabs]);
  return (
    <>
      <section className="news-sectn">
        <nav className="d-flex align-items-center mb-3">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className="nav-item nav-link animated-btn active"
              id="hotnews-tab"
              data-toggle="tab"
              href="#hotnews"
              role="tab"
              aria-controls="hotnews"
              aria-selected="true"
              onClick={() => setTabs(1)}
            >
              Tin nổi bật
              <div className="fire_flame_eff">
                <div className="red flame"></div>
                <div className="orange flame"></div>
                <div className="yellow flame"></div>
                <div className="white flame"></div>
              </div>
            </a>
            <a
              className="nav-item nav-link animated-btn new-btn"
              id="newnews-tab"
              data-toggle="tab"
              href="#newnews"
              role="tab"
              aria-controls="newnews"
              aria-selected="false"
              onClick={() => setTabs(0)}
            >
              Tin mới
              <div className="flashit">
                <p>new</p>
              </div>
            </a>
          </div>
        </nav>
        {/* tabs */}
        <Tabs listSubNews={listSubNews} newsFutures={listNews.length > 0 && listNews[0]} />
      </section>
    </>
  );
}

export default News;
