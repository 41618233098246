import React, { useEffect } from 'react';
import AddMetaTags from "components/add_meta_tags";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as sitemapActions from 'actions/sitemap'
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2'

function Sitemap(props) {
  let {fetchSitemap} = props.sitemapActions;
  let {data: listSiteMaps, isLoading} = props.listSiteMap;

  useEffect(() => {
    fetchSitemap();
  }, [fetchSitemap]);

  useEffect(() => {
    if(listSiteMaps){
      downloadSiteMap();
    }
  }, [listSiteMaps])

 async function downloadSiteMap(){
    renderDownloadSiteMapSuccess();
  }
  
  function renderDownloadSiteMapSuccess () {
    Swal.fire({
      icon: 'success',
      title: 'Đã download sitemap',
      showConfirmButton: false,
      timer: 1500
    }
    )
  }
  
  return (
    <div>
      <AddMetaTags title={"Sitemap"} />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    listSiteMap: state.sitemap.listSitemap,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    sitemapActions: bindActionCreators(sitemapActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sitemap);