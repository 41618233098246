import * as expertConst from 'constants/expert'
import * as callConst from 'constants/call'
const initialState = {
  listExpert: {
    data: [],
    error: null,
    isLoading: false,
  },
  room_call: {
    token: null,
    room_name: null,
    isLoading: false,
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case expertConst.FETCH_EXPERTS: {
      return {
        ...state,
        listExpert: {
          data: initialState.listExpert.data,
          isLoading: true
        }
      };
    }
    case expertConst.FETCH_EXPERTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listExpert:{
          data: data.data,
          error: null,
          isLoading: false
        }
      }
    }
    case callConst.CREATE_CALL: {
      return {
        ...state,
        room_call: {
          token: initialState.room_call.token,
          room_name: initialState.room_call.room_name,
          isLoading: true,
        }
      }
    }

    case callConst.CREATE_CALL_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        room_call: {
          token: data.data.token,
          room_name: data.data.room_name,
          isLoading: false,
        }
      }
    }

    default:
      return state;
  }

}

export default reducer;