import React, { useEffect, useContext } from "react";
import AppContext from "components/app_context";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import RightSidebar from "components/right_sidebar";
import { useDispatch } from "react-redux";
import { fetchCatalogueTechnicalProcess } from "redux/actions/technical_process";
import { Link } from "react-router-dom";

import Catalogue from "./components/catalogue";

function TechnicalProcess() {
  const { current_user } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCatalogueTechnicalProcess({ limit: "", parent_id: "is_parent" }));
  }, []);

  return (
    <>
      <AddMetaTags title={"Quy trình kỹ thuật"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12 mkt-price">
            <div className="d-flex align-items-center mb-3">
              <h5 className="title-line-lt text-uppercase fw-bold mb-0">Quy trình kỹ thuật</h5>
              {current_user && (
                <div className="d-flex ms-auto">
                  <Link to={`/dong-gop-quy-trinh-ky-thuat`} className="btn btn-sm contribute ms-auto">
                    <img src="/images/donggop_icon.png" alt="image" title="Đóng Góp" />
                    <span className="txt">Đóng góp</span>
                  </Link>
                </div>
              )}
            </div>
            <Catalogue />
          </div>
          <RightSidebar />
        </div>
      </div>
    </>
  );
}
export default TechnicalProcess;
