import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NumberFormat from 'components/number_format';
import { imageUrl } from 'lib';

const InvoiceItems = ({ sellerName, invoiceItems, totalPrice }) => {
  return (
    <section className="mb-3">
      <div className="row">
        <div className="col-md-12">
          <Card className="mt-3">
            <Card.Header>
              <p className="fw-bold">Shop {sellerName}</p>
            </Card.Header>
            <Card.Body>
              <table className="table align-middle mb-0 bg-white">
                <thead className="bg-light">
                  <tr>
                    <th>Tên sản phẩm</th>
                    <th>Giá tiền</th>
                    <th>Số lượng</th>
                    <th>Tổng tiền</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceItems?.map((product) => (
                    <tr key={product?.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <Link to={`/san-pham/${product?.product_id}`}>
                            <img
                              src={product?.product_images === null || product?.product_images === undefined ? '/images/photos/no_image.png' : imageUrl(product?.product_images[0]?.name)}
                              className="rounded-circle"
                              alt=""
                              style={{ width: '45px', height: '45px' }}
                            />
                          </Link>
                          <div className="ms-3">
                            <Link to={`/san-pham/${product?.product_id}`}>
                              <p className="fw-bold mb-1">{product?.product_name}</p>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="fw-bold mb-1">
                          <NumberFormat>{product?.price}</NumberFormat>₫
                        </p>
                      </td>
                      <td>
                        <div className="cart-quantity">
                          {product?.quantity}
                        </div>
                      </td>
                      <td>
                        <p className="fw-bold mb-1">
                          <NumberFormat>{product?.price_total}</NumberFormat>₫
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='total-price'>
                <p className='fw-bold mb-1 mt-2'>
                  Tổng tiền: <NumberFormat>{totalPrice}</NumberFormat>₫
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default InvoiceItems;
