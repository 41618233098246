import { Link } from "react-router-dom";

function Diagnostics(props){
  let { data, diagnostic_name } = props;
  return (
    <>
       <div className="col-md-6 col-sm-12">
        <div className="cont-center">
          <h5 className="title-line-lt text-uppercase fw-bold">
            { diagnostic_name === null ? 'Cẩm nang sâu bệnh' : diagnostic_name }
          </h5>
          <div className="row news-more">
          {
            data.map(function (diagnostic, index) {
              return (
                <div className="col-md-6 col-sm-12" key={index}>
                  <Link to={`/diagnostics/${diagnostic?.slug}`} className="news-item d-flex align-items-center">
                    <div className="pt-show">
                      <div className="bg-img " style={{backgroundImage: `url(${diagnostic.image})`}}/>
                    </div>
                      <div className="ctnt line-clamp-03 artile-title">
                        {diagnostic?.title}
                      </div>
                  </Link>
              </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
   
  )
}
export default Diagnostics;