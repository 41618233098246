import React from "react";
import NumberFormat from "../../../components/number_format";
import { Link } from "react-router-dom";
export default function ProductRelation({ data }) {
  return (
    <div className="row mt-1 prod-list">
      <h5 className="title-line-lt text-uppercase fw-bold ml-2 mb-3">
        <span className="d-block">sản phẩm liên quan</span>
      </h5>
      {data.map(function (product, index) {
        let product_image =
          product.images[0]?.name ?? "/images/product-image-default.png";
        return (
          <div key={product.id} className="col-md-3 col-sm-6 col-xs-12">
            <div className="item-lst">
              <Link to={`/san-pham/${product?.slug}`}>
                <div
                  className="bg-img-orgnal"
                  style={{ backgroundImage: `url(${product_image})` }}
                />
                <div className="prod-inf">
                  <div className="txt-name">{product.title}</div>
                  <div className="locatn">
                    <span className="icon-location01" />
                    <span className="txt-city">
                      {product.province_name ?? "Không xác định "}
                    </span>
                  </div>
                  <div className="price-item">
                    <div className="price01 mb-2">
                      <div className="d-block">
                        <small className="fw-semibold">Giá lẻ</small>
                      </div>
                      {product.retail_price > 0 ? (
                        <div>
                          <span className="price-clr">
                            &nbsp;
                            <NumberFormat>
                              {product.retail_price}
                            </NumberFormat>{" "}
                            đ/
                            {product.unit_name}
                          </span>
                        </div>
                      ) : (
                        <span className="price-clr"> Liên hệ</span>
                      )}
                    </div>
                    <div className="price02">
                      <div className="d-block">
                        <small className="fw-semibold">Giá sỉ</small>
                      </div>
                      {product.wholesale_price > 0 ? (
                        <div>
                          <span className="price-clr">
                            &nbsp;
                            <NumberFormat>
                              {product.wholesale_price}
                            </NumberFormat>{" "}
                            đ/
                            {product.unit_name}
                          </span>
                        </div>
                      ) : (
                        <span className="price-clr"> Liên hệ</span>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}
