import * as sitemapConst from 'constants/sitemap'

export const fetchSitemap = params => {
  return {
    type: sitemapConst.FETCH_SITEMAP,
    payload: {
      params,
    },
  };
};

export const fetchSitemapSuccess = data => {
  return {
    type: sitemapConst.FETCH_SITEMAP_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchSitemapFailed = error => {
  return {
    type: sitemapConst.FETCH_SITEMAP_FAILED,
    payload: {
      error,
    },
  };
};