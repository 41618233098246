import React, { useEffect } from "react";
import ProductItem from "./productItem";
import { fetchProduct } from "actions/product";
import { useDispatch, useSelector } from "react-redux";

function Products() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.product.listProducts);

  useEffect(() => {
    dispatch(fetchProduct({ sort_type: "bestseller", limit: 15 }));
  }, []);

  return (
    <>
      <section className="prod-sectn">
        <h5 className="title-line-lt text-uppercase fw-bold my-3">Lựa chọn hôm nay</h5>
        <div className="row prod-list">
          <ProductItem data={data.length > 0 && data} />
        </div>
      </section>
    </>
  );
}

export default Products;
