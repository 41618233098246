import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from 'components/app';
import configureStore from './store/configureStore';
import ReactPWAInstallProvider from 'react-pwa-install';

const store = configureStore();
// serviceWorker.register()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ReactPWAInstallProvider enableLogging>
        <Suspense fallback={<div>Loading... </div>}>
          <App />
        </Suspense>
      </ReactPWAInstallProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
