import { call, put } from 'redux-saga/effects'
import { fetchFertilizerSuccess } from 'actions/fertilizer'
import { getManures } from 'apis/fertilizer'
import {STATUS_CODE} from 'constants/index';

export function* fetchFertilizer({ payload }) {
  try {
    const resp = yield call(getManures);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFertilizerSuccess(data));
    }
  } catch (error) {
  }
}