import React, {useEffect, useRef} from 'react';
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { useDispatch, useSelector } from 'react-redux';
import {fetchPointsTransaction} from 'actions/gift';
import { Card, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import Loader from "components/loader";

function StoreGiftDetail() {
  const dispatch = useDispatch();
  const {data, isLoading} = useSelector(state => state.gift.pointsTransactions);
  let statusRef = useRef(null);

  useEffect(() => {
    dispatch(fetchPointsTransaction({page: 1}));
  }, [dispatch]);

  const status = {
    pending: 'Chờ xác nhận',
    accepted: 'Đã chấp nhận',
    rejected: 'Đã từ chối	',
  }

  const background = {
    pending: 'badge badge-warning',
    accepted: 'badge badge-success',
    rejected: 'badge badge-danger',
  }
  
  const handleSearchPointsTransaction = () => {
    dispatch(fetchPointsTransaction({status: statusRef.current.value}));
  }
  
  return (
    <>
      <div className="points-transaction">
        <AddMetaTags title='Nhật ký đổi quà' />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12 order-md-1 order-xs-2">
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold text-uppercase fs-3">Nhật ký đổi quà</span>
            </div>
            <div className="cont-center">
              <div className='row'>
                <div className="col-md-4 col-sm-12">
                  <select className="form-select form-select-sm mb-2" aria-label=".form-select-sm example" ref={statusRef}>
                    <option value="">Tất cả</option>
                    <option value="accepted">{status['accepted']}</option>
                    <option value="pending">{status['pending']}</option>
                    <option value="rejected">{status['rejected']}</option>

                  </select>
                </div>
                <div className='col-md-4'>
                  <button type="button" className="btn btn-primary" onClick={() => handleSearchPointsTransaction()}>
                    Tìm kiếm
                  </button>
                </div>
                <Loader isLoading={isLoading}/>
                  <Card className="p-0 mt-3">
                    <Card.Body>
                      <Table bordered className="m-0">
                        <thead>
                          <tr>
                            <th>Tên quà</th>
                            <th>Điểm đổi</th>
                            <th>Ngày đổi</th>
                            <th>Trạng thái</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((gift, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{gift?.gift_name}</span>
                                </td>
                                <td>
                                  <span>{gift?.points}</span>
                                </td>
                                <td>
                                  <span>{moment(gift.created_at).format("DD/MM/YYYY H:M")}</span>
                                </td>
                                <td>
                                  <span className={background[gift?.status]}> {status[gift?.status]}</span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Card.Body>
                 </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreGiftDetail;