import * as technicalProcessConst from "constants/technical_process";

export const fetchTechnicalProcess = (params) => {
  return {
    type: technicalProcessConst.FETCH_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const fetchTechnicalProcessSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchTechnicalProcessFailed = (error) => {
  return {
    type: technicalProcessConst.FETCH_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchTechnicalProcessId = (params) => {
  return {
    type: technicalProcessConst.FETCH_TECHNICAL_PROCESS_ID,
    payload: {
      params,
    },
  };
};

export const fetchTechnicalProcessIdSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_TECHNICAL_PROCESS_ID_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchTechnicalProcessIdFailed = (error) => {
  return {
    type: technicalProcessConst.FETCH_TECHNICAL_PROCESS_ID_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchLoadMoreTechnicalProcess = (params) => {
  return {
    type: technicalProcessConst.FETCH_LOAD_MORE_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const fetchLoadMoreTechnicalProcessSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_LOAD_MORE_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchLoadMoreTechnicalProcessFailed = (error) => {
  return {
    type: technicalProcessConst.FETCH_LOAD_MORE_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const createTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.CREATE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const createTechnicalProcessCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.CREATE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.CREATE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.FETCH_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const fetchTechnicalProcessCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.FETCH_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const likeTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.LIKE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const likeTechnicalProcessCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.LIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const likeTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.LIKE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const dislikeTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.DISLIKE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const dislikeTechnicalProcessCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.DISLIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const dislikeTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.DISLIKE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const reportTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.REPORT_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const reportTechnicalProcessCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.REPORT_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reportTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.REPORT_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const deleteTechnicalProcessComment = (id) => {
  return {
    type: technicalProcessConst.DELETE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      id,
    },
  };
};

export const deleteTechnicalProcessCommentSuccess = (id) => {
  return {
    type: technicalProcessConst.DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      id,
    },
  };
};

export const deleteTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.DELETE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};
export const managerDeleteTechnicalProcessComment = (id) => {
  return {
    type: technicalProcessConst.MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      id,
    },
  };
};

export const managerDeleteTechnicalProcessCommentSuccess = (id) => {
  return {
    type: technicalProcessConst.MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      id,
    },
  };
};

export const managerDeleteTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const replyTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.REPLY_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};
export const replyTechnicalProcessCommentSucess = (data) => {
  return {
    type: technicalProcessConst.REPLY_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};
export const replyTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.REPLY_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const updateTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.UPDATE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};
export const updateTechnicalProcessCommentSucess = (data) => {
  return {
    type: technicalProcessConst.UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};
export const updateTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.UPDATE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const deleteChildTechnicalProcessComment = (id) => {
  return {
    type: technicalProcessConst.DELETE_CHILD_COMMENT_TECHNICAL_PROCESS,
    payload: {
      id,
    },
  };
};

export const deleteChildTechnicalProcessCommentSuccess = (id) => {
  return {
    type: technicalProcessConst.DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      id,
    },
  };
};

export const deleteChildTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const managerDeleteChildTechnicalProcessComment = (id) => {
  return {
    type: technicalProcessConst.MANAGER_DELETE_CHILD_COMMENT,
    payload: {
      id,
    },
  };
};

export const managerDeleteChildTechnicalProcessCommentSuccess = (id) => {
  return {
    type: technicalProcessConst.MANAGER_DELETE_CHILD_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};

export const managerDeleteChildTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.MANAGER_DELETE_CHILD_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchTechnicalProcessChildComment = (params) => {
  return {
    type: technicalProcessConst.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};

export const fetchTechnicalProcessChildCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchTechnicalProcessChildCommentFailed = (error) => {
  return {
    type: technicalProcessConst.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const updateTechnicalProcessChildComment = (params) => {
  return {
    type: technicalProcessConst.UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};
export const updateTechnicalProcessChildCommentSuccess = (data) => {
  return {
    type: technicalProcessConst.UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const replyTechnicalProcessChildComment = (params) => {
  return {
    type: technicalProcessConst.REPLY_CHILD_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};
export const replyTechnicalProcessChildCommentSucess = (data) => {
  return {
    type: technicalProcessConst.REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};
export const replyTechnicalProcessChildCommentFailed = (error) => {
  return {
    type: technicalProcessConst.REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const managerUpdateTechnicalProcessComment = (params) => {
  return {
    type: technicalProcessConst.MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS,
    payload: {
      params,
    },
  };
};
export const managerUpdateTechnicalProcessCommentSucess = (data) => {
  return {
    type: technicalProcessConst.MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data,
    },
  };
};
export const managerUpdateTechnicalProcessCommentFailed = (error) => {
  return {
    type: technicalProcessConst.MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchCatalogueTechnicalProcess = (params) => {
  return {
    type:  technicalProcessConst.FETCH_CATALOGUE_TECHNICAL_PROCESS,
    payload: {
      params
    }
  }
}

export const fetchCatalogueTechnicalProcessSuccess = data => {
  return {
    type: technicalProcessConst.FETCH_CATALOGUE_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data
    }
  }
}


export const fetchCataloguesChildren = (parent_id) => {
  return {
    type: technicalProcessConst.FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS,
    payload: {
      parent_id
    }
  }
}

export const fetchCataloguesChildrenSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDetailCatalogues = (catalogueId) => {
  return {
    type: technicalProcessConst.FETCH_DETAIL_CATALOGUES,
    payload: {
      catalogueId
    }
  }
}

export const fetchDetailCataloguesSuccess = (data) => {
  return {
    type: technicalProcessConst.FETCH_DETAIL_CATALOGUES_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDetailCataloguesFailed = (error) => {
  return {
    type: technicalProcessConst.FETCH_DETAIL_CATALOGUES_FAILED,
    payload: {
      error
    }
  }
}

export const resetDataCataloguesChildren = () => {
  return{
    type: technicalProcessConst.RESET_DATA_CHILDREN_TECHNICAL_PROCESS
  }
}

export const resetDataCataloguesChildrenSuccess = () => {
  return{
    type: technicalProcessConst.RESET_DATA_CHILDREN_TECHNICAL_PROCESS_SUCCESS
  }
}