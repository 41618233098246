import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { getCataloguesProducts } from "../../../apis/catalogue";
import { getlistUnits } from "../../../apis/product";
export const exportExampleExcel = async () => {
  try {
    // get API data
    const [listCatalog, listUnit] = await Promise.all([getCataloguesProducts(), getlistUnits()]);
    //handle data
    const catalogues = listCatalog.data.data.map((item) => item.name);
    const units = listUnit.data.data.map((item) => item.name);
    const catalogues_fomulae = `"${catalogues.join(",")}"`;
    const unit_fomulae = `"${units.join(",")}"`;
    // create workbook & worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sản phẩm của doanh nghiệp");

    // worksheet template
    worksheet.columns = [
      { header: "Mã sản phẩm", key: "code", width: 20 },
      { header: "Tên sản phẩm *", key: "title", width: 30 },
      { header: "Chọn chợ *", key: "product_type", width: 40 },
      { header: "Danh mục", key: "catalogue", width: 40 },
      { header: "Số lượng *", key: "quantity", width: 30 },
      { header: "Đơn vị", key: "unit", width: 10 },
      { header: "Giá lẻ *", key: "retail_price", width: 30 },
      { header: "Giá sỉ", key: "wholesale_price", width: 30 },
      { header: "Mô tả", key: "description", width: 60 },
      { header: "Hình ảnh", key: "images", width: 60 },
    ];
    worksheet.addRow({
      code: "BbDl01",
      title: "Bơ Bút Đà Lạt",
      product_type: "Chợ nhà sản xuất",
      catalogue: "NÔNG SẢN NHÀ NÔNG",
      quantity: 1000,
      unit: "KG",
      retail_price: 10000,
      wholesale_price: 10000,
      description: "Bơ đà lạt thơm ngon, giòn dẻo",
      images: "https://nongsananphat.com/wp-content/uploads/2020/09/b%C6%A1.jpg",
    });
    worksheet.addRow({
      code: "BDx02",
      title: "Bưởi da xanh",
      product_type: "Chợ nhà sản xuất",
      catalogue: "NÔNG SẢN NHÀ NÔNG",
      quantity: 1000,
      unit: "KG",
      retail_price: 10000,
      wholesale_price: 10000,
      description: "Bưởi Bình Minh thơm ngon, giòn dẻo",
      images:
        "https://nongsanchogao.com/thumbs/570x560x1/upload/product/buoi-da-xanh-tui-15kg-202107061346330961-5717.jpg",
    });

    worksheet.getRow(1).font = { bold: true };
    // add validation
    worksheet.dataValidations.add("A2:A9999", {
      type: "textLength",
      allowBlank: false,
      showErrorMessage: true,
      operator: "between",
      formulae: [1, 7],
      error: "Mã sản phẩm không được bỏ trống và chứa 1 đến 7 kí tự",
    });

    worksheet.dataValidations.add("B2:B9999", {
      type: "textLength",
      allowBlank: false,
      showErrorMessage: true,
      operator: "greaterThan",
      formulae: [1],
      error: "Tên sản phẩm không được bỏ trống và ít nhất 1 kí tự",
    });
    worksheet.dataValidations.add("C2:C9999", {
      type: "textLength",
      allowBlank: false,
      showErrorMessage: true,
      error: "Chợ không được bỏ trống",
    });
    worksheet.dataValidations.add("D2:D9999", {
      type: "list",
      allowBlank: false,
      formulae: [`${catalogues_fomulae}`],
    });
    worksheet.dataValidations.add("E2:E9999", {
      type: "decimal",
      showErrorMessage: true,
      operator: "greaterThan",
      formulae: [1],
      error: "Chỉ chấp nhận số và phải lớn hơn 0",
    });
    worksheet.dataValidations.add("F2:F9999", {
      type: "list",
      allowBlank: false,
      formulae: [`${unit_fomulae}`],
    });
    worksheet.dataValidations.add("G2:G9999", {
      type: "decimal",
      showErrorMessage: true,
      operator: "greaterThan",
      formulae: [1],
      error: "Chỉ chấp nhận số và phải lớn hơn 0",
    });
    worksheet.dataValidations.add("H2:H9999", {
      type: "decimal",
      showErrorMessage: true,
      error: "Chỉ chấp nhận số",
    });
    worksheet.dataValidations.add("I2:I9999", {
      type: "textLength",
      showErrorMessage: true,
      operator: "lessThanOrEqual",
      formulae: [200],
      showErrorMessage: true,
      error: "Vui lòng nhập không quá 200 kí tự",
    });
    worksheet.getColumn("A").numFmt = "@";
    // export file .xlsx
    workbook.xlsx.writeBuffer().then((buffer) => saveAs(new Blob([buffer]), `Products.xlsx`));
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
