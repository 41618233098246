import { call, put } from "redux-saga/effects";
import {
  managerUpdateComment,
  managerUpdateAnswerComment,
  managerDeletePost,
  managerFetchCommentWarnings,
  managerDeleteWarning,
  fetchTechnicalProcessWaitingForApprove,
  fetchContributePests,
  fetchContributeMarketPrice,
  approveContributeTechnicalProcess,
  deleteContributeTechnicalProcess,
  approveContributePests,
  deleteContributePests,
  deleteContributeMarketPrice,
  approveContributeMarketPrice
} from "apis/manager";
import { STATUS_CODE } from "constants/index";
import {
  managerUpdateCommentSuccess,
  managerUpdateCommentFailed,
  managerUpdateAnswerCommentSuccess,
  managerFetchCommentWarningsSuccess,
  managerFetchCommentWarningsFailed,
  managerDeleteWarningSuccess,
  managerFetchContributeTechnicalSuccess,
  managerFetchContributePestsSuccess,
  managerFetchContributeMarketPriceSuccess,
  managerContributeTechnicalProcessSuccess,
  managerContributeTechnicalProcessFailed,
  managerDeleteContributeTechnicalProcessSuccess,
  managerDeleteContributeTechnicalProcessFailed,
  managerContributePestsSuccess,
  managerContributePestsFailed,
  managerDeleteContributePestSuccess,
  managerDeleteContributePestFailed,
  managerDeleteContributeMarketPriceSuccess,
  managerDeleteContributeMarketPriceFailed,
  managerApproveContributeMarketPriceSuccess,
  managerApproveContributeMarketPriceFailed
} from "actions/manager";
import {
  deletePostSuccess,
} from "actions/post";

import { toastSuccess } from "helpers/toastHelper";
export function* managerUpdateComments({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerUpdateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateCommentSuccess(data));
    } else {
      yield put(managerUpdateCommentFailed(data));
    }
  } catch (error) {}
}

export function* managerUpdateAnswerComments({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerUpdateAnswerComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateAnswerCommentSuccess(data));
    }
  } catch (error) {}
}

export function* managerDeletePosts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerDeletePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deletePostSuccess(params.id));
    }
  } catch (error) {}
}

export function* managerFetchCommentWarning({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerFetchCommentWarnings, params);
    const { data, status } = resp;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerFetchCommentWarningsSuccess(data));
    }else{
      yield put(managerFetchCommentWarningsFailed(data));
    }
  }catch(error) {
  }
}

export function* managerDeleteWarnings({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerDeleteWarning, params);
    const {status } = resp;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerDeleteWarningSuccess(params));
    }
  }catch(error) {
  }
}

export function* managerFetchContributeTechnicalProcess({payload}){
  try {
    const { params } = payload;
    const response = yield call(fetchTechnicalProcessWaitingForApprove, params);
    const {data, status } = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerFetchContributeTechnicalSuccess(data));
    }
  } catch (error) {
    
  }
}

export function* managerFetchContributePests({payload}){
  try {
    const { params } = payload;
    const response = yield call(fetchContributePests, params);
    const {data, status } = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerFetchContributePestsSuccess(data));
    }
  } catch (error) {
    
  }
}
 
export function* managerFetchContributeMarketPrice({payload}){
  try {
    const { params } = payload;
    const response = yield call(fetchContributeMarketPrice, params);
    const {data, status } = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerFetchContributeMarketPriceSuccess(data));
    }
  } catch (error) {
    
  }
}

export function* managerApproveContributeTechnicalProcess({payload}){
  try {
    const { params } = payload;
    const response = yield call(approveContributeTechnicalProcess, params);
    const {data, status } = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerContributeTechnicalProcessSuccess(data));
    }
  } catch (error) {
    yield put(managerContributeTechnicalProcessFailed(error))
  }
}
export function* managerDeleteContributeTechnicalProcess({payload}){
  try {
    const { params } = payload;
    const response = yield call(deleteContributeTechnicalProcess, params);
    const {status } = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerDeleteContributeTechnicalProcessSuccess({id: params}));
    }
  } catch (error) {
    yield(put(managerDeleteContributeTechnicalProcessFailed(error)))
  }
}

export function* managerApproveContributePests({payload}){
  try {
    const { params } = payload;
    const response = yield call(approveContributePests, params);
    const {status, data} = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerContributePestsSuccess(data));
    }
  } catch (error) {
    yield put(managerContributePestsFailed(error))
  }
}

export function* managerDeleteContributePests({payload}){
  try {
    const { params } = payload;
    const response = yield call(deleteContributePests, params);
    const {status, data} = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerDeleteContributePestSuccess(data));
    }
  } catch (error) {
    yield put(managerDeleteContributePestFailed(error))
  }
}

export function* managerApproveContributeMarketPrice({payload}){
  try {
    const { params } = payload;
    const response = yield call(approveContributeMarketPrice, params);
    const {status, data} = response;
    if(data.success){
      yield put(managerApproveContributeMarketPriceSuccess(data));
    }else {
      yield put(managerApproveContributeMarketPriceFailed(data))

    }
  } catch (error) {
    yield put(managerApproveContributeMarketPriceFailed(error))
  }
}

export function* managerDeleteContributeMarketPrice({payload}){
  try {
    const { params } = payload;
    const response = yield call(deleteContributeMarketPrice, params);
    const {status} = response;
    if(status === STATUS_CODE.SUCCESS){
      yield put(managerDeleteContributeMarketPriceSuccess(params));
    }
  } catch (error) {
    yield put(managerDeleteContributeMarketPriceFailed(error))
  }
}
