export const FETCH_PROVINCE = 'FETCH_PROVINCE';
export const FETCH_PROVINCE_SUCCESS = 'FETCH_PROVINCE_SUCCESS';
export const FETCH_PROVINCE_FAILED  = 'FETCH_PROVINCE_FAILED';

export const FETCH_DISTRICT = 'FETCH_DISTRICT';
export const FETCH_DISTRICT_SUCCESS = 'FETCH_DISTRICT_SUCCESS';
export const FETCH_DISTRICT_FAILED  = 'FETCH_DISTRICT_FAILED';

export const FETCH_WARD = 'FETCH_WARD';
export const FETCH_WARD_SUCCESS = 'FETCH_WARD_SUCCESS';
export const FETCH_WARD_FAILED  = 'FETCH_WARD_FAILED';
