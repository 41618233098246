const  DetectAction = ({children, status}) => {
  switch(status){
    case "pending": {
      return children;
    }
    case "ready_to_ship": {
      return children;
    }
    case "shipped": {
      return null;
    }
    case "failed_delivery": {
      return null;
    }
    case "delivered": {
      return null;
    }
    case "cancelled": {
      return null;
    }
    case "returned": {
      return null;
    }
    default: {
      return children;
    }
  }
}
export default DetectAction;