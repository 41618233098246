import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchProductOfEnterprises } from 'actions/business';
import { useDispatch, useSelector } from 'react-redux';
import AddMetaTags from '../../components/add_meta_tags';
import LeftSidebar from '../../components/left_sidebar';
import SidebarCatalogueOfProduct from '../../components/sidebar_catalogueOfProduct';
import Enterprise from './components/Enterprise';
import ProductOfEnterprise from './components/ProductOfEnterprise';
import { isMobile } from 'react-device-detect';
import BreadcrumbEnterprise from './components/BreadcrumbEnterprise';

const EnterpriseDetail = () => {
  const [seeMore, setSeeMore] = useState(false);
  const { state } = useLocation();
  const enterprise = state?.enterprise;
  const dispatch = useDispatch();

  const { data: products } = useSelector(state => state.business.list_product_of_enterprise);

  const handleSeeMoreInfo = () => setSeeMore(true);

  const fetchProductOfEnterprise = () => {
    if (enterprise) {
      dispatch(fetchProductOfEnterprises({ business_association_id: enterprise?.id }));
    }
  };

  useEffect(() => {
    fetchProductOfEnterprise();
  }, []);

  return (
    <div className="enterprise-detail">
      <AddMetaTags title={enterprise?.name} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-6 col-sm-12 order-md-1 order-xs-2">
          <div className="cont-center">
            <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">Chợ sản xuất</p>
            <BreadcrumbEnterprise />
            <Enterprise enterprise={enterprise} seeMore={seeMore} onSeeMore={handleSeeMoreInfo} isMobile={isMobile}/>
            {products && <ProductOfEnterprise products={products} />}
          </div>
        </div>
        <SidebarCatalogueOfProduct />
      </div>
    </div>
  );
};

export default EnterpriseDetail;
