
import React, { useEffect, useState } from 'react';
import { getPopupAdvertisements } from '../../apis/banner';
import { Link } from 'react-router-dom';

const Banner = ({ location }) => {
  const [currentBanner, setCurrentBanner] = useState(null);
  const [isCloseBanner, setIsCloseBanner] = useState(false);
  const [lastShownIds, setLastShownIds] = useState({});

  const module_paths = {
    market: '/cho-2nong',
    market_price: '/modules/gia-ca-thi-truong',
    post: '/',
    video: '/videos',
    article: '/tin-tuc',
    technical_process: '/quy-trinh-ky-thuat',
    weather: '/modules/thoi-tiet',
  };

  const onCloseBanner = () => setIsCloseBanner(true);

  const getKeyByValue = (object, value) => {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null;
  };
  const module = getKeyByValue(module_paths, location.pathname);

  const fetchBanners = async () => {
    setIsCloseBanner(true);
    const { data } = await getPopupAdvertisements({
      show_position: Object.keys(module_paths).find(key => module_paths[key] === location.pathname),
    });
    let banners = data.data;

    if (banners.length > 0) {
      const lastShownBannerId = lastShownIds[module];
      let nextBannerIndex = 0;

      if (lastShownBannerId) {
        const lastIndex = banners.findIndex(banner => banner.id === lastShownBannerId);
        nextBannerIndex = (lastIndex + 1) % banners.length;
      }

      const nextBanner = banners[nextBannerIndex];
      setCurrentBanner(nextBanner);
      setLastShownIds(prev => ({ ...prev, [module]: nextBanner.id }));
    } else {
      setCurrentBanner(null);
    }

    setIsCloseBanner(false);
  };

  useEffect(() => {
    if (module) {
      fetchBanners();
    } else {
      setIsCloseBanner(true);
      setCurrentBanner(null);
    }
  }, [location.pathname]);

  return (
    <>
      {!isCloseBanner && currentBanner && (
        <div className="row position-relative d-flex justify-content-center ">
          <div
            className="col-md-9 col-xs-12 col-lg-8 col-sm-12
            position-fixed mx-auto"
            style={{
              ...{ [currentBanner.location]: 0 },
              height: '100px',
              zIndex: '10000000',
            }}
          >
            <Link to={`/quang-cao/${currentBanner.id}`} state={currentBanner.id}>
              <img src={currentBanner.image} alt="banner" className="w-100 h-100" />
            </Link>
            <div
              className="d-block position-absolute pointer"
              style={{
                top: '5px',
                right: '20px',
                zIndex: '100000000',
                background: '#e5e5e5',
                padding: '3px',
              }}
              onClick={() => onCloseBanner()}
            >
              <span className="icon-close d-block"></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
