import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalReportPrice = ({showReportPrice, handleCloseReportPrice, reportPrice, setReportPrice, submitReportPrice}) => {
  return (
    <div>
      <Modal
        show={showReportPrice}
        onHide={handleCloseReportPrice}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Báo cáo giá cả</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            name="message"
            placeholder="Nhập lý do"
            value={reportPrice}
            onChange={e => setReportPrice(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => submitReportPrice(reportPrice)}>
            gửi
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalReportPrice;