import { useMemo } from 'react';
import moment from 'moment';

const useFilterLastCreatedDataPerDay = (data) => {
  const filteredData = useMemo(() => {
    const dataByDate = {}; // Đối tượng này sẽ lưu trữ dữ liệu cuối cùng của mỗi ngày

    data.forEach(item => {
      const dayKey = moment(item.created_at).format('DD-MM-YYYY'); // Chuyển đổi created_at thành định dạng ngày
      if (!dataByDate[dayKey] || moment(item.created_at).isAfter(dataByDate[dayKey].created_at)) {
        // Nếu chưa có dữ liệu cho ngày này hoặc dữ liệu hiện tại được tạo sau, cập nhật/ghi đè dữ liệu
        dataByDate[dayKey] = item;
      }
    });

    // Chuyển đổi object lưu trữ trở lại mảng, sắp xếp theo trường created_at và trả về
    const sortedData = Object.values(dataByDate).sort((a, b) => {
      return moment(a.created_at).diff(moment(b.created_at));
    });

    return sortedData;
  }, [data]);

  return filteredData;
};

export default useFilterLastCreatedDataPerDay;
