import React from "react";
import moment from "moment";

function InfoDetails({ data }) {
  return (
    <div className="row border-bottom">
      <div className="col-md-12">
        <div className="green-clr font-weight-bold mb-1">Thông tin sản phẩm</div>
        <div className="d-flex justify-content-between">
          <div className="info ">
            <div className="mb-1">Tên gọi: &nbsp; {data[0].name}</div>
            <div className="mb-1">Mã số (số lô): &nbsp;{data[0].lot_code}</div>
            <div className="mb-1">Quy cách đóng gói: &nbsp;{data[0].packing_way}</div>
            <div className="mb-1">
              Ngày đóng góp: &nbsp;{moment(data[0].created_at).format("DD/MM/YYYY")}
            </div>
            <div className="mb-1">Số ngày cách ly: &nbsp;{data[0].number_of_quarantine_days}</div>
            <div className="mb-1">Chứng nhận: &nbsp;{data[0].certification}</div>
            <div className="font-weight-bold text-dark mb-1"> Video thu hoạch/đóng góp</div>
            <div className="video">
              {data.map((item) => {
                return (
                  <div className="list-videos mt-1">
                    <video
                      width="400"
                      height="200"
                      src={item.video?.name}
                      controls
                      preload
                      type="video/mp4"
                    ></video>
                  </div>
                );
              })}
            </div>
          </div>
          <div className=" d-block media">
            <div className=" font-weight-bold text-dark mb-1 "> Hình ảnh thu hoạch/đóng góp</div>
            <div className="image d-flex">
              {data.map((item) =>
                item.list_images?.map((image, index) => (
                  <div key={index} className="list-images ">
                    <img className="traceaility-image ml-1" src={image.name} alt="" />
                  </div>
                ))
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
export default InfoDetails;
