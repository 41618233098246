import qs from 'query-string';
import axiosService from 'services/axiosService';
import { DOMAIN, PAGE, PER_PAGE } from 'constants';
import { get_user } from 'lib';

const url = 'api/v2/articles';
const objectHashTagURL = 'api/v2/object_hashtags';
const url_account = 'api/v2/account';
const url_short_video = 'api/v2/short_video';

export const getArticles = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`);
};

export const getArticleId = params => {
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.get(`${DOMAIN}/${url}/${params}`, isAuth);
};

export const getRelationArticles = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  let id = params['id'];
  return axiosService.get(`${DOMAIN}/${url}/${id}/relation_articles${query}`);
};

export const getRelationShortVideo = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  let id = params['id'];
  return axiosService.get(`${DOMAIN}/${url_short_video}/short_videos/${id}/relation_short_videos${query}`);
};

export const getFavoritesArticles = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/favorite_articles${query}`, true);
};

export const createFavoriteArticle = params => {
  let current_user = get_user();
  axiosService.setHeader('Authorization', `Bearer ${current_user.token_user}`);
  return axiosService.post(`${DOMAIN}/api/v2/account/create_favourite`, params);
};

export const getObjectHashTag = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${objectHashTagURL}${query}`);
};

export const deleteFavoriteArticle = id => {
  let current_user = get_user();
  axiosService.setHeader('Authorization', `Bearer ${current_user.token_user}`);
  return axiosService.post(`${DOMAIN}/api/v2/account/favourite/${id}`);
};

export const likeComment = params => {
  return axiosService.post(`${DOMAIN}/${url_account}/like_item`, params);
};

export const UnlikeComment = params => {
  return axiosService.post(`${DOMAIN}/${url_account}/unlike`, params);
};

export const getShortVideos = params => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/api/v2/short_video/short_videos${query}`);
};
