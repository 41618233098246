import React, { useEffect, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as productActions from 'actions/product';
import FadeIn from 'react-fade-in';
import AddMetaTags from "components/add_meta_tags";
import { DOMAIN } from 'constants';
import RightSidebar from 'components/right_sidebar';
import LeftSidebar from 'components/left_sidebar';
import { validURL } from 'lib';
import NumberFormat from 'components/number_format';
import { getProfile } from 'apis/auth';
import * as authActions from 'actions/auth';
import { imageUrl } from 'lib';
import ScrollToTop from 'components/ScrollToTop';

function ShopProfile(props) {
  let { userId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const businessAssociationId = params.get('business_association_id');
  const businessAssociationName = params.get('business_association_name');
  const { productActionsCreator } = props;
  const { fetchProductsByUser, fetchLoadMoreProduct } = productActionsCreator;
  const { data: dataProducts} = props.listProducts;
  const [page, setPage] = useState(1);
  const [profile, setProfile] = useState(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!businessAssociationId) {
      getProfile(userId).then(res => {
        let result = res['data']['data'];
        if (result) {
          setProfile(result);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    if (businessAssociationId) {
      fetchProductsByUser({ business_association_id: businessAssociationId });
    } else {
      fetchProductsByUser({ user_id: profile?.id });
    }
  }, [fetchProductsByUser, businessAssociationId, profile?.id]);

  const listenerProduct = () => {
    setPage(page + 1);
    fetchLoadMoreProduct({ page: page + 1, limit: 10 });
  };

  const handleSetContent = value => {
    setContent(value);
  };

  return (
    <ScrollToTop>
      <div className="posts">
        <div className="row">
          <LeftSidebar />
            <div className="col-md-6 col-sm-12">
              <div className=" fw-bold">
                <h5 className="title-line-lt text-uppercase fw-bold my-3">Cửa  hàng {businessAssociationName ||profile?.name}</h5>
              </div>
              <FadeIn
                  className="row prod-list"
                  childClassName="col-md-3 col-sm-6 col-xs-12"
                >
                  {dataProducts.map(function (product, index) {
                    let product_image = imageUrl(product.images[0]["name"]);
                    return (
                      <div key={index} className="item-lst">
                        <Link to={`/san-pham/${product?.slug}`}>
                          <div
                            className="bg-img-orgnal"
                            style={{ background: `url(${product_image})` }}
                          />
                          <div className="prod-inf">
                            <div className="txt-name text-capitalize">{product.title}</div>
                            {product.province_name ? (
                              <div className="locatn">
                                <span className="icon-location01" />
                                <span className="txt-city">
                                  {product?.shop?.province_name}
                                </span>
                              </div>
                            ) : (
                              <div className="locatn">
                                <span className="icon-location01" />
                                <span className="txt-city"> Không xác định</span>
                              </div>
                            )}
                            <div className="price-item">
                              <div className="price01">
                                <span className="d-block text-muted">
                                  Giá lẻ:
                                </span>
                                {product.retail_price > 0 ? (
                                  <span className="price-clr">
                                    &nbsp;
                                    <NumberFormat>
                                      {product.retail_price}
                                    </NumberFormat>{" "}
                                    đ/{product.unit_name}
                                  </span>
                                ) : (
                                  <span className="price-clr">Liên hệ</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </FadeIn>
            </div>
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}

const mapStateToProps = state => ({
  listProducts: state.product.listUserProducts,
  user_data: state.login.user_data
});

const mapDispatchToProps = dispatch => ({
  productActionsCreator: bindActionCreators(productActions, dispatch),
  authActionsCreator: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopProfile);
