import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { reportComment } from "apis/comment";
import { toastError, toastSuccess } from "helpers/toastHelper";

function ModalReportComment({ commentID, open, setOpen, commentType }) {
  let [reason, setReason] = useState("");
  const closeModalReport = () => setOpen(false);
  const submitReport = () => {
    if (reason.length <= 0) {
      return toastError("Vui lòng nhập lý do");
    }
    reportComment({ reason: reason, classable_id: commentID, classable_type: commentType }).then((res) => {
      setReason("");
      closeModalReport();
      toastSuccess("Báo cáo thành công");
    });
  };
  return (
    <>
      <Modal show={open} onHide={closeModalReport} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Báo cáo bình luận</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            name="message"
            placeholder="Nhập lý do"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => submitReport()}>
            gửi
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ModalReportComment;
