import qs from 'query-string';
import axiosService from 'services/axiosService';
import { DOMAIN, PAGE, PER_PAGE } from 'constants';

const url = 'api/v2/npk_manures';
export const getManures = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`);
};

export const mixNPK = (params) => {
  const url = 'api/v2/npk_manures/npk_calculator';
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`);
}

export const calculatorReverse = (params) => {
  const url_1 = 'api/v2/npk_manures/npk_calculator_reverse';
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_1}${query}`);
}

export const changeNPK = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }

  return axiosService.get(`${DOMAIN}/${url}/npk_change_type${query}`);
}