import React from 'react';
import EnterpriseInformationMobile from './EnterpriseInformationMobile';
import EnterpriseDescriptionMobile from './EnterpriseDescriptionMobile';


const Mobile = ({ enterprise }) => {
  return (
    <>
      <EnterpriseInformationMobile enterprise={enterprise}/>
      <EnterpriseDescriptionMobile enterprise={enterprise}/>
    </>
  );
};

export default Mobile;