import React, { useCallback, useState } from 'react';
import { TabContainer, TabContent, TabPane, Nav } from "react-bootstrap";
import Loader from "components/loader";
import SearchBox from "./searchBox";
import LoadMore from "./loadMore";
import MarketPriceItem from "./marketPriceItem";
import ListFollowingMarketPrice from "./listFollowingMarketPrice";

function TabControl(props) {
  let {
    data,
    setAgriculturalType,
    isLoadMore,
    isLoading,
    handleLoadMore,
    handleCancelSearch,
    listProvinces,
    searchMarketPrices,
    setKeyword,
    setProvinceId,
    setDate,
    date,
    keyword,
    listFollowingMarketPrices,
    fetchAllMarketPrices,
    agriculturalType,
    fetchFollowingMarketPrice
  } = props;
  const [reset, setReset] = useState(false);

  const handleChangeTab = useCallback((type) => {
    if (type === agriculturalType) return;

    setAgriculturalType(type);
    setKeyword("");
    setDate(null);
    setProvinceId(null);
    setReset(true);
    if (type === "follow") return;

    fetchAllMarketPrices({
      page: 1,
      agricultural_type: type === "all" ? "" : type,
    });

  }, [agriculturalType]);

  return (
    <>
      <TabContainer defaultActiveKey="all" onSelect={(type) => handleChangeTab(type)}>
        <Nav as="ul" className="nav nav-mkt-price" id="mkt-price-tab" justify>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="all">
              Tất cả
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="agricultural">
              Nông sản
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="fertilizer">
              Phân bón
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="follow">
              Quan tâm
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <SearchBox
          handleCancelSearch={handleCancelSearch}
          listProvinces={listProvinces}
          searchMarketPrices={searchMarketPrices}
          setKeyword={setKeyword}
          setProvinceId={setProvinceId}
          setDate={setDate}
          date={date}
          keyword={keyword}
          fetchAllMarketPrices={fetchAllMarketPrices}
          agriculturalType={agriculturalType}
          setReset={setReset}
          reset={reset}
          fetchFollowingMarketPrice={fetchFollowingMarketPrice}
        />
        {isLoading && <Loader isLoading={isLoading} />}
        {data.length > 0 ? (
          <TabContent>
            <TabPane eventKey="all" title="Tất cả">
              <MarketPriceItem data={data} />
              {isLoading && <Loader isLoading={isLoading} />}
              {isLoadMore && <LoadMore handleLoadMore={handleLoadMore} />}
            </TabPane>
            <TabPane eventKey="agricultural" title="Nông sản">
              <MarketPriceItem data={data} />
              {isLoading && <Loader isLoading={isLoading} />}
              {isLoadMore && <LoadMore handleLoadMore={handleLoadMore} />}
            </TabPane>
            <TabPane eventKey="fertilizer" title="Phân bón" type="agricultural">
              <MarketPriceItem data={data} />
              {isLoading && <Loader isLoading={isLoading} />}
              {isLoadMore && <LoadMore handleLoadMore={handleLoadMore} />}
            </TabPane>
            <TabPane eventKey="follow" title="Quan tâm">
              <ListFollowingMarketPrice listFollowingMarketPrices={listFollowingMarketPrices} />
            </TabPane>
          </TabContent>
        ) : (
          !isLoading && <div className="font-italic mt-2 text-secondary">Không tìm thấy mặt hàng</div>
        )}
      </TabContainer>
    </>
  );
}

export default TabControl;
