import axiosService from "services/axiosService";
import { DOMAIN } from "constants";
import qs from 'query-string';

const url = 'api/v2/user_albums';

export const getUserAlbums = (params) => {
  let query = '';
  if(Object.keys(params).length > 0){
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`, true);
}

export const getUserAlbumsId = (albumsId) => {
  return axiosService.get(`${DOMAIN}/${url}/${albumsId}`, true);
}

export const createUserAlbums = (params) => {
  return axiosService.post(`${DOMAIN}/${url}`, params);
}

export const updateUserAlbums = (params) => {
  return axiosService.put(`${DOMAIN}/${url}/${params.albumsId}`, {name: params.name});
}

export const destroyUserAlbums = (albumsId) => {
  return axiosService.delete(`${DOMAIN}/${url}/${albumsId}`, true);
}

export const getListImageAlbums = (albumsId) => {
  return axiosService.get(`${DOMAIN}/${url}/${albumsId}/images`, true);
}

export const moveImageToOtherAlbums = (albumsId, imageId, otherAlbumsId) => {
  return axiosService.put(`${DOMAIN}/${url}/${albumsId}/images/${imageId}`, {user_album_id: otherAlbumsId});
}

export const destroyImageAlbums = (albumsId, imageId) => {
  return axiosService.delete(`${DOMAIN}/${url}/${albumsId}/images/${imageId}`, true);
}

export const UploadImageToAlbums = (params, albumsId) => {
  return axiosService.post(`${DOMAIN}/${url}/${albumsId}/create_image`, params);
}
