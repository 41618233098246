import * as managerConst from 'constants/manager';

export const managerUpdateComment = params => {
  return {
    type: managerConst.MANAGER_UPDATE_COMMENT,
    payload: {
      params
    }
  }
}

export const managerUpdateCommentSuccess = data => {
  return {
    type: managerConst.MANAGER_UPDATE_COMMENT_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerUpdateCommentFailed = error => {
  return {
    type: managerConst.MANAGER_UPDATE_COMMENT_FAILED,
    payload: {
      error
    }
  }
}

export const managerUpdateAnswerComment = params => {
  return {
    type: managerConst.MANAGER_UPDATE_ANSWER_COMMENT,
    payload: {
      params
    }
  }
}

export const managerUpdateAnswerCommentSuccess = data => {
  return {
    type: managerConst.MANAGER_UPDATE_ANSWER_COMMENT_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerUpdateAnswerCommentFailed = error => {
  return {
    type: managerConst.MANAGER_UPDATE_ANSWER_COMMENT_FAILED,
    payload: {
      error
    }
  }
}

export const managerDeletePost = params => {
  return {
    type: managerConst.MANAGER_DELETE_POST,
    payload: {
      params
    }
  }
}

export const managerDeletePostSuccess = data => {
  return {
    type: managerConst.MANAGER_DELETE_POST_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerFetchCommentWarnings = params => {
  return {
    type: managerConst.FETCH_WARNING_COMMENT,
    payload: {
      params
    }
  }
}

export const managerFetchCommentWarningsSuccess = data => {
  return {
    type: managerConst.FETCH_WARNING_COMMENT_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerFetchCommentWarningsFailed = error => {
  return {
    type: managerConst.FETCH_WARNING_COMMENT_FAILED,
    payload: {
      error
    }
  }
}

export const managerDeleteWarning = params => {
  return {
    type: managerConst.DELETE_WARNING_COMMENT,
    payload: {
      params
    }
  }
}

export const managerDeleteWarningSuccess = data => {
  return {
    type: managerConst.DELETE_WARNING_COMMENT_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerDeleteWarningFailed = error => {
  return {
    type: managerConst.DELETE_WARNING_COMMENT_FAILED,
    payload: {
      error
    }
  }
}

export const managerDeleteCommentPostDetail = id => {
  return {
    type: managerConst.MANAGER_DELETE_COMMENT_POST_DETAIL,
    payload: {
      id
    }
  }
}

export const managerDeleteCommentPostDetailSuccess = data => {
  return {
    type: managerConst.MANAGER_DELETE_COMMENT_POST_DETAIL_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerDeleteCommentPostDetailFailed = error => {
  return {
    type: managerConst.MANAGER_DELETE_COMMENT_POST_DETAIL_FAILED,
    payload: {
      error
    }
  }
}

export const managerContributeTechnicalProcess = (params) => {
  return {
    type: managerConst.MANAGE_CONTRIBUTE_TECHNICAL_PROCESS,
    payload: {
      params
    }
  }
}

export const managerContributeTechnicalProcessSuccess = (data) => {
  return {
    type: managerConst.MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerContributeTechnicalProcessFailed = (error) => {
  return {
    type: managerConst.MANAGE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED,
    payload: {
      error
    }
  }
}

export const managerDeleteContributeTechnicalProcess = (params) => {
  return {
    type: managerConst.MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS,
    payload: {params}
  }
}

export const managerDeleteContributeTechnicalProcessSuccess = (data) => {
  return {
    type: managerConst.MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_SUCCESS,
    payload: {data}
  }
}

export const managerDeleteContributeTechnicalProcessFailed = (error) => {
  return {
    type: managerConst.MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS_FAILED,
    payload: {error}
  }
}

export const managerDeleteContributePest = (params) => {
  return {
    type: managerConst.MANAGE_DELETE_CONTRIBUTE_PESTS,
    payload: {
      params
    }
  }
}

export const managerDeleteContributePestSuccess = (data) => {
  return {
    type: managerConst.MANAGE_DELETE_CONTRIBUTE_PESTS_SUCCESS,
    payload: {
      data
    }
  }
}

export const managerDeleteContributePestFailed = (error) => {
  return {
    type: managerConst.MANAGE_DELETE_CONTRIBUTE_PESTS_FAILED,
    payload: {
      error
    }
  }
}

export const managerContributePests = (params) => {
  return {
    type: managerConst.MANAGE_CONTRIBUTE_PESTS,
    payload: {params}
  }
}

export const managerContributePestsSuccess = (data) => {
  return {
    type: managerConst.MANAGE_CONTRIBUTE_PESTS_SUCCESS,
    payload: {data}
  }
}

export const managerContributePestsFailed = (error) => {
  return {
    type: managerConst.MANAGE_CONTRIBUTE_PESTS_FAILED,
    payload: {error}
  }
}

export const managerFetchContributeTechnicalProcess = (params) => {
  return {
    type: managerConst.FETCH_TECHNICAL_PROCESS_FOR_APPROVE,
    payload: {params}
  }
}

export const managerFetchContributeTechnicalSuccess = (data) => {
  return {
    type: managerConst.FETCH_TECHNICAL_PROCESS_FOR_APPROVE_SUCCESS,
    payload: {data}
  }
}

export const managerFetchContributeTechnicalFailed = (error) => {
  return {
    type: managerConst.FETCH_TECHNICAL_PROCESS_FOR_APPROVE_FAILED,
    payload: {error}
  }
}

export const managerFetchContributePests = (params) => {
  return {
    type: managerConst.FETCH_CONTRIBUTE_PESTS,
    payload: {params}
  }
}

export const managerFetchContributePestsSuccess = (data) => {
  return {
    type: managerConst.FETCH_CONTRIBUTE_PESTS_SUCCESS,
    payload: {data}
  }
}

export const managerFetchContributePestsFailed = (error) => {
  return {
    type: managerConst.FETCH_CONTRIBUTE_PESTS_FAILED,
    payload: {error}
  }
}

export const managerFetchContributeMarketPrice = (params) => {
  return {
    type: managerConst.FETCH_CONTRIBUTE_MARKET_PRICE,
    payload: {params}
  }
}

export const managerFetchContributeMarketPriceSuccess = (data) => {
  return {
    type: managerConst.FETCH_CONTRIBUTE_MARKET_PRICE_SUCCESS,
    payload: {data}
  }
}

export const managerFetchContributeMarketPriceFailed = (error) => {
  return {
    type: managerConst.FETCH_CONTRIBUTE_MARKET_PRICE_FAILED,
    payload: {error}
  }
}


export const managerApproveContributeMarketPrice = (params) => {
  return {
    type: managerConst.MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE,
    payload: {params}
  }
}

export const managerApproveContributeMarketPriceSuccess = (data) => {
  return {
    type: managerConst.MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_SUCCESS,
    payload: {data}
  }
}

export const managerApproveContributeMarketPriceFailed = (error) => {
  return {
    type: managerConst.MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE_FAILED,
    payload: {error}
  }
}

export const managerDeleteContributeMarketPrice = (params) => {
  return {
    type: managerConst.MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE,
    payload: {params}
  }
}

export const managerDeleteContributeMarketPriceSuccess = (data) => {
  return {
    type: managerConst.MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_SUCCESS,
    payload: {data}
  }
}

export const managerDeleteContributeMarketPriceFailed = (error) => {
  return {
    type: managerConst.MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE_FAILED,
    payload: {error}
  }
}

