import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import qs from "query-string";
// import { get_user } from 'lib';

export const getReportRevenue = params => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/api/v2/invoice_users/report${query}`, true);
};
