import { useContext } from "react";
import AppContext from "components/app_context";

const useCartTotal = () => {
  const { current_user } = useContext(AppContext);
  const updateTotalPrice = (listProductInCart) => {
    const totalPrice = listProductInCart
      .filter((product) => product.user_id === current_user?.id)
      .reduce((sum, product) => {
        sum += product.price * product.quantity;
        return sum;
      }, 0);
    return totalPrice;
  };
  const updateTotalQuantity = (currentProduct, newProduct, quantity) => {
    if (currentProduct.id === newProduct.id) {
      return Object.assign({ ...currentProduct, quantity: parseInt(currentProduct.quantity) + quantity });
    } else {
      return currentProduct;
    }
  };
  const updateTotalNumberProductInCart = (listProductInCart) => {
    if (listProductInCart.filter(item => item.user_id === current_user?.id).length > 0) {
      return listProductInCart.filter(item => item.user_id === current_user?.id).length;
    } else {
      return 0;
    }
  };
  return {
    updateTotalPrice,
    updateTotalQuantity,
    updateTotalNumberProductInCart,
  };
};

export default useCartTotal;
