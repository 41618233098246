import React from 'react';

function ListFertilizer(props) {
  let { data, detectFertilizer, getFertilizerIds } = props;
  var result_n = [];
  var result_p = [];
  var result_k = [];

  data.map((e) => {
    if(e.kind === 'nitrogen'){
      result_n.push(e);
    }else if(e.kind === 'kali'){
      result_p.push(e);
    }else{
      result_k.push(e);
    }
  })

  return (
    <div className="choice-lst">
      <label>Chọn phân để trộn</label>
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <div className='row'>
        <div className="col-md-4">
          {result_n.map(function (fertilizer, index) {
            return (
              <div className="list-fertilizer col" key={index}>
                <input type='checkbox' id="fertilizer_ids" className={`${fertilizer.kind}`} value={fertilizer?.id} onChange={(e) => getFertilizerIds(e.target.value)} />
                &nbsp;&nbsp;{fertilizer?.code} - {fertilizer?.title} ({detectFertilizer(fertilizer?.kind)})
              </div>
            )
          })}
        </div> 

        <div className="col-md-4">
          {result_p.map(function (fertilizer, index) {
            return (
              <div className="list-fertilizer col" key={index}>
                <input type='checkbox' id="fertilizer_ids" className={`${fertilizer.kind}`} value={fertilizer?.id} onChange={(e) => getFertilizerIds(e.target.value)} />
                &nbsp;&nbsp;{fertilizer?.code} - {fertilizer?.title} ({detectFertilizer(fertilizer?.kind)})
              </div>
            )
          })}
        </div>  
        <div className="col-md-4">
          {result_k.map(function (fertilizer, index) {
            return (
              <div className="list-fertilizer col" key={index}>
                <input type='checkbox' id="fertilizer_ids" className={`${fertilizer.kind}`} value={fertilizer?.id} onChange={(e) => getFertilizerIds(e.target.value)} />
                &nbsp;&nbsp;{fertilizer?.code} - {fertilizer?.title} ({detectFertilizer(fertilizer?.kind)})
              </div>
            )
          })}
        </div>  
        </div>
      </div>
    </div>
  </div>
  );
}

export default ListFertilizer;