import { STATUS_CODE } from "constants/index";
import { call, put, all } from "redux-saga/effects";
import { toastError, toastSuccess } from "helpers/toastHelper";
import {
  getTechnicalProcess,
  getTechnicalProcessId,
  getCataloguesTechnicalProcess,
  getCataloguesChildren,
  getCataloguesDetail,
} from "apis/technical_process";
import {
  createComment,
  getComment,
  likeComment,
  disLikeComment,
  reportComment,
  destroyComment,
  replyComment,
  updateComment,
  destroyChildComment,
  getAnswersComment,
  updateCommentAnswer,
} from "apis/comment";
import { managerDeleteComment, managerDeleteSubComment, managerUpdateComment } from "apis/manager";

import {
  fetchTechnicalProcessSuccess,
  fetchTechnicalProcessFailed,
  fetchTechnicalProcessIdSuccess,
  fetchTechnicalProcessIdFailed,
  fetchLoadMoreTechnicalProcessSuccess,
  fetchLoadMoreTechnicalProcessFailed,
  createTechnicalProcessCommentSuccess,
  createTechnicalProcessCommentFailed,
  fetchTechnicalProcessCommentSuccess,
  fetchTechnicalProcessCommentFailed,
  dislikeTechnicalProcessCommentFailed,
  dislikeTechnicalProcessCommentSuccess,
  likeTechnicalProcessCommentFailed,
  likeTechnicalProcessCommentSuccess,
  reportTechnicalProcessCommentFailed,
  reportTechnicalProcessCommentSuccess,
  deleteTechnicalProcessCommentSuccess,
  managerDeleteTechnicalProcessCommentSuccess,
  updateTechnicalProcessCommentSucess,
  replyTechnicalProcessCommentSucess,
  managerDeleteChildTechnicalProcessCommentSuccess,
  deleteChildTechnicalProcessCommentSuccess,
  fetchTechnicalProcessChildCommentSuccess,
  fetchTechnicalProcessChildCommentFailed,
  updateTechnicalProcessChildCommentSuccess,
  replyTechnicalProcessChildCommentSucess,
  managerUpdateTechnicalProcessCommentSucess,
  fetchCatalogueTechnicalProcessSuccess,
  fetchCataloguesChildrenSuccess,
  fetchCataloguesChildren,
  fetchDetailCataloguesSuccess,
  resetDataCataloguesChildrenSuccess,
} from "actions/technical_process";

export function* fetchListTechnicalProcess({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getTechnicalProcess, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchTechnicalProcessSuccess(data));
    } else {
      yield put(fetchTechnicalProcessFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchTechnicalProcessDetail({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getTechnicalProcessId, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchTechnicalProcessIdSuccess(data));
    } else {
      yield put(fetchTechnicalProcessIdFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* fetchLoadMoreTechnicalProcessItem({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getTechnicalProcess, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMoreTechnicalProcessSuccess(data));
    } else {
      yield put(fetchLoadMoreTechnicalProcessFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* createTechnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(createTechnicalProcessCommentSuccess(data));
    } else {
      yield put(createTechnicalProcessCommentFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchTechnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchTechnicalProcessCommentSuccess(data));
    } else {
      yield put(fetchTechnicalProcessCommentFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* likeTechnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likeComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(likeTechnicalProcessCommentSuccess(data));
    } else {
      yield put(likeTechnicalProcessCommentFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* dislikeTechnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(disLikeComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(dislikeTechnicalProcessCommentSuccess(params));
    } else {
      yield put(dislikeTechnicalProcessCommentFailed(params));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* reportTechnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(reportComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(reportTechnicalProcessCommentSuccess(params));
      toastSuccess("Báo cáo thành công");
    } else {
      yield put(reportTechnicalProcessCommentFailed(params));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userDeleteTechnicalProcessComment({ payload }) {
  try {
    const { id } = payload.id;

    const resp = yield call(destroyComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteTechnicalProcessCommentSuccess(id));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* userDeleteChildTechnicalProcessComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(destroyChildComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteChildTechnicalProcessCommentSuccess(id));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* managerDestroyTechnicalProcessComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(managerDeleteComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDeleteTechnicalProcessCommentSuccess(id));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* managerDeleteChildTechnicalProcessComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(managerDeleteSubComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDeleteChildTechnicalProcessCommentSuccess(id));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* userReplyTechchnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(replyComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(replyTechnicalProcessCommentSucess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userUpdateTechnicalProcessComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateTechnicalProcessCommentSucess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* managerUpdateTechnicalComment({ payload }) {
  try {
    const { params } = payload;

    const resp = yield call(managerUpdateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateTechnicalProcessCommentSucess(data));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* fetchTechnicalProcessChildrenComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAnswersComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchTechnicalProcessChildCommentSuccess(data));
    } else {
      yield put(fetchTechnicalProcessChildCommentFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userUpdateTechnicalProcessChildrenComment({ payload }) {
  try {
    const { params } = payload;

    const resp = yield call(updateCommentAnswer, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateTechnicalProcessChildCommentSuccess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userReplyTechchnicalProcessChildComment({ payload }) {
  try {
    const { params } = payload;

    const resp = yield call(replyComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(replyTechnicalProcessChildCommentSucess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userFetchCatalogueTechnicalProcess({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getCataloguesTechnicalProcess, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchCatalogueTechnicalProcessSuccess(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userFetchCataloguesChildren({ payload }) {
  try {
    const { parent_id } = payload;
    const resp = yield call(getCataloguesChildren, parent_id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchCataloguesChildrenSuccess(data));
    }
  } catch (error) {}
}

export function* userFetchCatalogueDetail({ payload }) {
  try {
    const { catalogueId } = payload;
    const [response1, response2] = yield all([
      call(getCataloguesDetail, catalogueId),
      call(getCataloguesChildren, catalogueId),
    ]);
    yield put(fetchDetailCataloguesSuccess({ detail: response1.data, children: response2.data }));
  } catch (error) {}
}

export function* userResetDataCatalogueChildren() {
  yield put(resetDataCataloguesChildrenSuccess());
}
