import React, { useState, useContext } from "react";
import { Trash3Fill } from "react-bootstrap-icons";
import NumberFormat from "components/number_format";
import { useCart } from "../Context";
import _ from "lodash";
import AppContext from "components/app_context";
import Card from "react-bootstrap/Card";

function ListProduct({ listProductInCarts }) {
  const [values, setValues] = useState([]);
  const { updateQuantitySafely, handleRemoveProduct, totalPriceProductInCart } = useCart();
  const { current_user } = useContext(AppContext);

  const handleUpdateQuantityProduct = (event, index, product) => {
    const regex = /^0$|^[1-9]\d*$/;
    const newValues = [...values];
    let numberOnly;
    if (event.target.value.match(regex)) {
      numberOnly = event.target.value;
    } else if (event.target.value == "" || event.target.value == 0) {
      numberOnly = "";
    } else {
      numberOnly = "";
    }

    newValues[index] = numberOnly;
    setValues(newValues);
    updateQuantitySafely(product, newValues[index]);
  };

  const handleRemoveProductFromCart = (product) => {
    handleRemoveProduct(product);
  };

  var listElements = [];
  const listProductInCartsGroupShopId = _.groupBy(listProductInCarts, (pro) => {
    return pro?.shop_id;
  });

  Object.entries(listProductInCartsGroupShopId).forEach(([key, value]) => {
    const productOfUser = value.filter((item) => item.user_id === current_user?.id);
    if (productOfUser.length > 0) {
      listElements.push(
        <Card className="mt-3  table-cart " key={key}>
          <Card.Header>
            <p className="fw-bold">Cửa hàng: {productOfUser[0]?.shop_name}</p>
          </Card.Header>
          <Card.Body>
            <table className="table align-middle mb-0 bg-white ">
              <thead className="bg-light">
                <tr>
                  <th className="line-clamp-01">Tên sản phẩm</th>
                  <th>Giá tiền</th>
                  <th className="line-clamp-01">Số lượng</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {productOfUser?.map((product) => {
                  return (
                    <tr key={product?.id} className={product?.shop_status === 'active' ? '' : 'table-secondary'}>
                      <td>
                        <div className="d-flex align-items-center sm-pt-20">
                          <img src={product?.image?.name ?? "images/photos/no_image.png"} className="rounded-circle mr-2" alt="" style={{ width: "45px", height: "45px" }} />
                          <div>
                            <p className="fw-bold mb-1">{product?.name}</p>
                            {
                              product.shop_status === 'disable' ?
                                <small className="text-danger">Sản phẩm này đã ngừng bán</small>
                              :
                                <></>
                            }
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="fw-bold mb-1" style={{ marginTop: "10px" }}>
                          <NumberFormat>{product?.price}</NumberFormat>₫
                        </p>
                      </td>
                      <td>
                        <div className="cart-quantity d-flex">
                          <input
                            type="text"
                            className=" w-quailty-cart form-control"
                            value={values[product.id] || product?.quantity}
                            onChange={(e) => handleUpdateQuantityProduct(e, product.id, product)}
                            disabled = {product.shop_status === 'active' ? false : true}
                          />
                          <span className="mt-2 ml-2 fw-bold" style={{ marginTop: "10px" }}>
                            {product?.unit}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Trash3Fill
                          size={16}
                          className="pointer"
                          onClick={() => handleRemoveProductFromCart(product)}
                          style={{ marginTop: "10px" }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      );
    }
  });

  return (
    <>
      {listElements.map((ele) => ele)}
      <div className="total-price">
      </div>
    </>
  );
}

export default ListProduct;
