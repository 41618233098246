import * as sitemapConst from 'constants/sitemap'

const initialState = {
  listSitemap: {
    data: [],
    error: null,
    isLoading: false
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case sitemapConst.FETCH_SITEMAP: {
      return {
        ...state,
        listSitemap: {
          data: initialState.listSitemap.data,
          isLoading: true,
          error: null
        }
      };
    }
    case sitemapConst.FETCH_SITEMAP_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listSitemap: {
          data: data.data,
          isLoading: false,
          error: null
        }
      };
    }
    default:
      return state;
  }
}
export default reducer;